import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FileQueueObject, FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'app-file-progress-snack-bar',
  templateUrl: './file-progress-snack-bar.component.html',
  styleUrls: ['./file-progress-snack-bar.component.scss']
})
export class FileProgressSnackBarComponent implements OnInit {
  public files: FileQueueObject[] = [];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<FileProgressSnackBarComponent>,
    private fileUploadService: FileUploadService) {
    this.snackBarRef.afterOpened().subscribe(
      () => {
        this.fileUploadService.queue.subscribe(files => {
          this.files = files;
        })
      },
      (error) => console.error(error)
    );
  }

  ngOnInit(): void {
  }

  get uploadingFilesText() {
    let index = this.files.length;
    return 'Uploading Media Files ' + index + ' of ' + index;
  }

  get allFilesProgress(): number {
    let finalProgress = 0;
    if (this.files.length > 0) {
      let allProgress = this.files.reduce((a, b) => a + b.progress, 0);
      finalProgress = allProgress / this.files.length;
    }
    return finalProgress;
  }

  get currentFileProgress(): number {
    if (this.files.length > 0) {
      let progress = this.files[this.files.length - 1].progress;
      return progress;
    } else {
      return 0;
    }
  }

}
