  <div class="head flex-sap-bw">
    <h1 mat-dialog-title>{{data.title}}</h1>
     <span class="close-icon generic__modal" [mat-dialog-close]="data.closeIconMessage" (click)="modalService.genericPopup = false" *ngIf="!data.hideCloseIcon" >
       <!-- <mat-icon>close</mat-icon> -->
       <mat-icon  class="close-circled" svgIcon="close-circled"
       aria-hidden="false" aria-label="close-circled"></mat-icon>
      </span>
    
    </div>
    <div mat-dialog-content>
      <p *ngIf="!largeContent else fileUpload">{{data.body}}</p>
      <p *ngIf="data?.middleContent">{{data.middleContent}}</p>
    </div>
    <div mat-dialog-actions class="generic__modal--btns">
      <button mat-raised-button class="btnOk grey-btn" (click)="onConfirm()" [ngClass]="{'active': isConfirm}">{{data.ok}}</button>
      <button *ngIf="data?.Cancel" mat-button mat-dialog-close class="btnCancel org-btn" [ngClass]="{'active': !isConfirm}" (click)="onDismiss()">{{data.Cancel}}</button>
    </div>

    <ng-template #fileUpload>
      <ng-container *ngFor="let msg of data.body; let i = index">
        <p class="large--content" matTooltipClass="custTooltip" matTooltip="{{msg.replace('?', '')}}" matTooltipPosition="above"
        [matTooltipDisabled]="i!=1" [ngClass]="{'file-name': i===1}">{{msg}}</p>
      </ng-container>
    </ng-template>

