<div class="change-layout">
  <form [formGroup]="form">

    <div class="flex-sap-bw">
      <span class="title">Change Password</span>
      <span class="close-icon change_pwd" (click)="cancelDialog()">
        <mat-icon class="close-circled" svgIcon="close-circled" aria-hidden="false"
          aria-label="close-circled"></mat-icon></span>
    </div> <br />
    <div class="divided-list">
      <div class="">
        <p>Current Password</p>
        <mat-form-field class="current_pass">
          <input matInput type="password" formControlName="currentPassword" (keyup)="onvalidate()">
          <mat-error *ngIf="currentPassword?.hasError('required')" class="error">Password is required</mat-error>
        </mat-form-field>
        <br />
        <p class="oldpassworderror">{{oldpasswordIncorrect}}</p>
      </div>
      <div class="">
        <p>New Passwrord </p>
        <mat-form-field class="new_pass">
          <input matInput type="password" formControlName="password" (input)="onPasswordInput()" (keyup)="onvalidate()">
          <mat-error *ngIf="password?.hasError('required')" class="error">Password is required</mat-error>
          <mat-error *ngIf="password?.hasError('minlength')" class="error">Password must contain {{minPw}} to {{maxPw}}
            characters</mat-error>
          <mat-error *ngIf="password?.hasError('maxlength')" class="error">Password must contain {{minPw}} to {{maxPw}}
            characters</mat-error>
          <mat-error *ngIf="password?.hasError('hasNumber')" class="error">Password must contain numbers</mat-error>
          <mat-error *ngIf="password?.hasError('hasSpecialCharacters')" class="error">Password must contain at least one special character</mat-error>
          <mat-error *ngIf="password?.hasError('hasCapitalCase')" class="error">Password must include minimum one uppercase letter </mat-error>
          <mat-error *ngIf="password?.hasError('hasSmallCase')" class="error">Password must contain at least one lowercase letter</mat-error>
          <span *ngIf="isCompareField" class="mat-error error mat-form-field-subscript-wrapper mat-form-field">New password should not be the same as previous password</span>
        </mat-form-field>
      </div>
      <div class="">
        <p>Confirm Password</p>
        <mat-form-field class="confirm_pass">
          <input matInput type="password" formControlName="password2" (input)="onPasswordInput()">
          <mat-error *ngIf="password2?.hasError('required')" class="error">Please re-enter the new password</mat-error>
          <mat-error *ngIf="password2?.invalid && !password2?.hasError('required')" class="error">The password you entered does not match</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>


  <div mat-dialog-actions class="project-btns mrg-top">
    <button class="btn--Cancel" mat-raised-button (click)="cancelDialog()">Cancel</button>
    <button class="btn--Proceed" mat-raised-button (click)="confirm()" type="submit" [disabled]="isCompareField">Confirm</button>

  </div>

</div>