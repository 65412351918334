import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Alert, AlertType } from "../models/alert.model";

@Injectable({
    providedIn:'root',
})

export class CustomToasterService{

    private keepAfterRouteChange = false;
    queuedAlerts: BehaviorSubject<Alert[]>;
    alerts:Alert[]=[];


    constructor(private router: Router){
        this.queuedAlerts = new BehaviorSubject(this.alerts);
        router.events.subscribe((event) => {
            if(event instanceof NavigationStart) {
                if(this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    this.alerts = [];
                    this.queuedAlerts.next(this.alerts);
                }
            }
        });
    }

    success(message: string) {
        this.alert(new Alert({message, type:AlertType.Success}));
    }

    info(message: string) {
        this.alert(new Alert({message, type:AlertType.Info}));
    }

    error(message: string) {
        this.alert(new Alert({message, type:AlertType.Error}));
    }

    warn(message: string) {
        this.alert(new Alert({message, type:AlertType.Warning}));
    }


    alert(alert:Alert){
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        if(!alert.message) {
            this.alerts = [];
        } else {
            const index = this.alerts.findIndex((p) => p.message === alert.message && p.type === alert.type);
            if(index > -1) {
                this.removeAlert(this.alerts[index]);
            }
            setTimeout(() => {
                this.removeAlert(alert);
            }, 7 * 1000);
            this.alerts.push(alert);
            }
        this.queuedAlerts.next(this.alerts);
    }
    
    removeAlert(alert:Alert){
        this.alerts = this.alerts.filter((x) => x !== alert);
        this.queuedAlerts.next(this.alerts);
    }

}