import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  finishTime: any;
  timeLeft: any;
  config: any;
  baseUrl = environment.baseUrl;
  subject = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    public router: Router)
  {
    this.config = AppConfig;
  }

  // set the data in localStorage
  setSessionData(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // clear localStorageData
  clearSessionData() {
    localStorage.clear();
  }


  logout() {
    const username = localStorage.getItem('username');
    return this.httpClient.post(`${this.baseUrl}${this.config.LOGIN}?username=${username}&signout=true`, {});
  }
}
