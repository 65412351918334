import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SideBarComponent } from '../../components/side-bar/side-bar.component';
import { AppCommonService } from '../../services/app-common.service';
import { AuthorizationService } from '../../services/authorization.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { passwordMatchValidator } from '../../validator/password-match-validator';
import { CustomValidators } from '../../validator/pattern-validator';
import { SetPasswordModalComponent } from '../set-password-modal/set-password-modal.component';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  minPw = 8;
  maxPw = 24;
  form: FormGroup;

  @ViewChild('email')
  private elTitle: ElementRef;
  email: string;
  defaultPassword: string;

  oldpasswordIncorrect: any = "";
  userName: any;
  isCompareField:boolean=false;
  
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    public appCommonService: AppCommonService,
    public sessionStorageService: SessionStorageService,
    public router: Router,
  ) {
  }



  ngOnInit() {
    this.userName = this.appCommonService.getUserName();

    this.form = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(this.minPw),
        Validators.maxLength(this.maxPw),
        // patternValidator(/\d/, { hasNumber: true })
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
      ])
      ],
      password2: ['', [Validators.required]],
      currentPassword: ['', Validators.required]
    }, { validator: passwordMatchValidator });
  }

  get currentPassword() { return this.form.get('currentPassword'); }
  get password() { return this.form.get('password'); }
  get password2() { return this.form.get('password2'); }

  async confirm() {
    if (this.form.valid) {
      let payload = {
        "scope": "CHANGE_PASSWORD",
        "username": this.userName,
        "old_password": this.currentPassword?.value,
        "new_password": this.password?.value,
      }

      try {
        
        const res = await this.authorizationService.initialPasswordChange(payload)
          if(res?.statusCode === 400) {
            this.appCommonService.showError(res.message)
            return
          }
          if (res?.status === 'error') {
            this.oldpasswordIncorrect = "Incorrect Password";//res.message;
          } else {
            const data = {
              message: "Password Updated",
            }
            this.appCommonService.showCustomMessage(data, 5000)
            this.dialogRef.close();
            this.sessionStorageService.clearSessionData();
            this.router.navigate(['/login']);
          }
      } catch (error:any) {
        this.appCommonService.showError(error?.message ? error?.message:'Some Error Occured!')
      }


    } else {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control?.markAsTouched({ onlySelf: true })
      })
    }
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  onvalidate(){
    this.isCompareField=false;
    if (this.form.controls['currentPassword'].value === this.form.controls['password'].value) {
      this.password2?.setErrors([{ 'current password and new password should be differ': true }]);
      this.isCompareField=true;
    }
  }
  /* Called on each input in either password field */
  onPasswordInput() {
    if (this.form.hasError('passwordMismatch')) {
      this.password2?.setErrors([{ 'passwordMismatch': true }])
    }
    else {
      this.password2?.setErrors(null);
    }
  }



}
