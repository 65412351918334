import { ApiService } from './api.service';
import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from 'src/app/app.config';
import { GenericSnackbarComponent } from '../modals/generic-snackbar/generic-snackbar.component';
import { APP_CONFIG } from 'src/app/app.config';
import { distinctUntilChanged } from 'rxjs/operators';
@Injectable()

export class EnterpriseTranscriptionService {

  private subject = new Subject<any>();
  public resData = this.subject.asObservable();

  emitdata(x: any){
    this.subject.next(x);
  }

  config: any;
  baseUrl = environment.baseUrl;
  private videoTimeUpdate = new BehaviorSubject<any>('');
  public isLoading = new BehaviorSubject(false);
  data = this.videoTimeUpdate.asObservable();
  private autoScrollValue = new BehaviorSubject<boolean>(false);
  autoScrollData = this.autoScrollValue.asObservable();
  private autoSeekValue = new BehaviorSubject<boolean>(false);
  autoSeekData = this.autoSeekValue.asObservable();
  private captionElement = new BehaviorSubject<any>('');
  captionData = this.captionElement.asObservable();
  private headerEvent = new BehaviorSubject<any>('');
  headerData = this.headerEvent.asObservable();
  // private headerKeyData$ = new BehaviorSubject<any>('');
  // headerKeyData = this.headerKeyData$.asObservable();
  private emitData$ = new BehaviorSubject<any>('');
  emittedHeaderData = this.emitData$.asObservable();
  private holdDirective$ = new BehaviorSubject<any>('');
  holdDirectiveObs = this.holdDirective$.asObservable();
  transferPresetData: any;
  showData: any;
  public autoScroll: boolean = false;
  public autoSeek: boolean = false;
  // public transcriptionCaretPos: boolean = false;

   // send capptions data to videojs to display current active caption
   sendVideoJSEvent = new Subject<any>()
   sendVideoJSEvent$ = this.sendVideoJSEvent.asObservable().pipe(distinctUntilChanged((x, y) => {
     return x === y
   }))

  constructor(public httpClient: HttpClient,
    public snackBar: MatSnackBar,
    private api: ApiService) {
    this.config = AppConfig
  }

  /**
   * To update video duration and send data to another component
   */
  updatedVideoTime(data: Number) {
    this.videoTimeUpdate.next(data);
  }

  /**
   * To update auto scroll checkbox value and pass to another component
   */
  updateAutoScrollValue(checkboxValue: boolean) {
    this.autoScrollValue.next(checkboxValue);
  }
  /**
   * To update auto seek checkbox value and pass to another component
   */
  updateSeekBarDuration(duration: boolean) {
    this.autoSeekValue.next(duration);
  }
  /**
   * Call API to save job status
   */
  saveJobData(data: any) {
    return this.httpClient.post(this.baseUrl + this.config.ENTERPRISE_TRANSCRIPTION.SAVE_TRANSCRIPTION_DATA, data);
  }

  /**
   * Call API to update speaker data
   */
  async updateSpeakerData(data: any){
    try {
      const res = await this.api.put(this.config.ENTERPRISE_TRANSCRIPTION.GET_UPDATE_TRANSCRIPTION_DATA, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async addNewSpeakerData(data: any) {
    try {
      const res = await this.api.post(this.config.ENTERPRISE_TRANSCRIPTION.DELETE_UPDATE_SPEAKER_DATA, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  /**
   * Call API to delete speaker data
   */
  async deleteSpeakerData(data: any) {
    try {
      const res = await this.api.delete(this.config.ENTERPRISE_TRANSCRIPTION.DELETE_UPDATE_SPEAKER_DATA+ "?job_id=" + data.jobId + "&language_id=" + data.language_id+ "&section_id=" + data.sectionId+ "&level_id=" + data.level_id, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  /**
   * Call API to update color data
   */
  async updateColorAndNameData(data: any) {
    try {
      const res = await this.api.put(this.config.ENTERPRISE_TRANSCRIPTION.DELETE_UPDATE_SPEAKER_DATA, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }

  }

  /**
   * Call API to get captioning data
   */
  getCaptioningData(jobId: any) {
    return this.httpClient.get(this.baseUrl + this.config.KEYBOARD_SHORTCUTS.GET_ALL_KEYBOARD_SHORTCUTS + '?job_id=' + jobId, {});
  }
  /**
   * Call API to get transcription data
   */
  async getTranscriptionData(jobId: any) {
    try {
      const res = await this.api.get(this.config.ENTERPRISE_TRANSCRIPTION.GET_TRANSCRIPTION_DATA + '?job_id=' + jobId, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  /**
   * Call API to get transcription json data link
   */
  async getTranscriptionDataJSON(data: any) {
    try {
      const res = await this.api.get(this.config.ENTERPRISE_TRANSCRIPTION.GET_UPDATE_TRANSCRIPTION_DATA + '?job_id=' + data.jobId + '&language_id=' + data.language_id  + '&level_id=' + data.level_id +'&part_no=' + data.part_no, true).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }
  /**
   * Call API to get svg images
   */
  svgCreation() {
    return this.httpClient.get('assets/svgpaths.json');
  }

  getAllTeams(all: any) {
    return this.httpClient.get(this.config.ADMIN_MANAGE.TEAMS_ENDPOINT + '?account=' + all + '&team_name=' + all);
  }

  getAllUsers() {
    return this.httpClient.get(this.config.ADMIN_MANAGE.GET_ALL_USERS + '?username=pradeepkotha&list_users=true&sub_username=')
  }

  asrSetup(data: any) {
    return this.httpClient.post(this.config.PROJECT_CREATION_STEPS.POST_ASRDATA, data);
  }




  getGlobalPresetData(data: any) {
    return this.httpClient.get(this.config.PROJECT_CREATION_PROCESS.GET_GLOBAL_PRESET + "?account_id=0&preset_name=" + data);
  }

  duplicateGlobalPresetData(acctId: any, data: any) {
    return this.httpClient.get(this.config.PROJECT_CREATION_PROCESS.POST_GLOBAL_PRESET + "?account_id=" + acctId + "&preset_name=" + data);
  }

  sendGlobalPreData(data: any) {
    return this.httpClient.post(this.config.PROJECT_CREATION_PROCESS.POST_GLOBAL_PRESET, data);
  }

  editPreData(data: any) {
    return this.httpClient.put(this.config.PROJECT_CREATION_PROCESS.POST_GLOBAL_PRESET, data);
  }

  deleteGlobalPresetData(acct: any, name: any) {
    return this.httpClient.delete(this.config.PROJECT_CREATION_PROCESS.DELETE_PRESET + "?account_id=" + acct + "&preset_name=" + name);
  }

  getEventData(data: any) {
    this.captionElement.next(data);
  }

  sendHeaderEventData(data: any) {
    this.headerEvent.next(data);
  }

  // sendHeaderData(data: any) {
  //   this.headerKeyData$.next(data);
  // }

  emitHeaderData(data: any) {
    this.emitData$.next(data);
  }

  emitToComp(hold: boolean) {
    this.holdDirective$.next(hold);
  }

  /**
   * Call API to get bookmark
   */
  async getBookMark(jobId: any, part_no: any) {

    try {
      const res = await this.api.get(this.config.ENTERPRISE_TRANSCRIPTION.BOOK_MARKS + "?job_id=" + jobId +"?part_no=" + part_no, true).toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error)
    }
  }

  /**
   * Call API to add bookmark
   */
  async addBookMark(data: any) {
    try {
      const res = await this.api.post(this.config.ENTERPRISE_TRANSCRIPTION.BOOK_MARKS, data, true).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }
  /**
   * Call API to remove one bookmark
   */
  async deleteOneBookMark(data: any) {
    try {
      const res = await this.api.delete(this.config.ENTERPRISE_TRANSCRIPTION.BOOK_MARKS + '?job_id=' + data.job_id + '&level_id=' + data.level_id + '&language_id=' + data.language_id + '&bookmark_id=' + data.bookmark_id + '&part_no=' + data.part_no, true).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }

  /**
   * Call API to remove all bookmark
   */
  async deleteAllBookMark(data: any) {
    try {
      const res = await this.api.delete(this.config.ENTERPRISE_TRANSCRIPTION.BOOK_MARKS + '?job_id=' + data.job_id + '&level_id=' + data.level_id + '&language_id=' + data.language_id + '&part_no=' + data.part_no, true).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }

  /**
   * Call API to update bookmark
   */
  async updateBookMark(data: any) {
    try {
      const res = await this.api.put(this.config.ENTERPRISE_TRANSCRIPTION.BOOK_MARKS, data, true).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }


  //Global Presets




//Customized Presets
getCustomPresetData(id:any, data:any){
  return this.httpClient.get(this.config.PROJECT_CREATION_PROCESS.CUSTOMIZED_PRESET+"?account_id="+id+"&preset_name="+data);
}




vocSetupUploadDoc(data:any, file:any){
  const formData = new FormData();
  formData.append(data,file);
  return this.httpClient.post(this.config.VOCABULARY_SETUP.UPLOAD_DOC+"?account_project_id=1&file_name="+data+"&type=vocabulary",formData);
}

vocSetupUploadWords(data:any){
  return this.httpClient.post(this.config.VOCABULARY_SETUP.UPLOAD_WORDS,data);
}

getVocSetupWords(){
  return this.httpClient.get(this.config.VOCABULARY_SETUP.GET_WORDS+"?account_project_id=1&type=vocabulary");
}

getVocSetupFiles(){
  return this.httpClient.get(this.config.VOCABULARY_SETUP.GET_FILES+"?account_project_id=1&type=vocabulary");
}

getInstructionDoc(data:any, file:any){
  const formData = new FormData();
  formData.append(data,file);
  return this.httpClient.post(this.config.INSTRUCTIONS_UPLOAD.UPLOAD_DOC+"?account_id=1&file_name="+data,formData);
}

async getShortcutKeys(userId: any, type:string, keyboardType: string) {
  try {
    // const res = await this.api.get(`${this.config.CAPTIONING.SETTINGS_KEYBOARD}?user_id=${userId}&type=${type}&keyboard_type=${keyboardType}`, true).toPromise();
    const res = await this.api.get(`${this.config.CAPTIONING.SETTINGS_KEYBOARD}?type=${type}&keyboard_type=${keyboardType}`, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error)
  }
}

async getSounCues(projectId: number) {
  try {
    const res = await this.api.get(`${this.config.SOUND_CUES.SOUND_CUES}?project_id=${projectId}`, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error)
  }
}

async addNewSoundCue(payload: any) {
  try {
    const res = await this.api.post(`${this.config.SOUND_CUES.SOUND_CUES}` , payload, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error)
  }
}

async deleteSoundCue(id: number) {
  try {
    const res = await this.api.delete(this.config.SOUND_CUES.SOUND_CUES+ "?id=" + id , true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error)
  }
}

async generateNewTranscript(payload: any) {
    try {
      const res = await this.api.post(`${this.config.ENTERPRISE_TRANSCRIPTION.LOAD_TRANSCRIPTION}`, payload, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
}

async getProjectDetails(data: {level_id:number,job_id:number}) {
  try {
    const res: any = await this.api.get(`${this.config.CAPTIONING.USER_FILES}`, true,data).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}


async getProjectDetailsByAssignmentId(data: {assignment_id:number,job_id:number}) {
  try {
    const res: any = await this.api.get(`${this.config.CAPTIONING.USER_FILES}`, true,data).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

async goToCaption(payLoad: any) {
  try {
    const res: any = await this.api.post(`${this.config.CAPTIONING.CAPTION_PRESETS}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}
async manageWorkFlowLevel(payLoad:ManageWorkFlowLevel.Payload){
  try {
    const res: any = await this.api.post(`${this.config.CAPTIONING.MANAGE_WORKFLOW_LEVEL}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

async afcConverter(payLoad:AFC_CONVERTER){
  try {
    const res: any = await this.api.post(`${this.config.CAPTIONING.AFC_CONVERTER}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

async deliverTranscriptions(payLoad:DeliverTranscriptionsPayload){
  try {
    const res: any = await this.api.post(`${this.config.CAPTIONING.DELIVER_TRANSCRIPTIONS}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

/**
* Call `deliver_captions` `API`.
 * @param  {DeliverCaptioningPayload} payLoad
 */
async deliverCaptioning(payLoad:DeliverCaptioningPayload){
  try {
    const res: any = await this.api.post(`${this.config.CAPTIONING.DELIVER_CAPTIONS}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

/**
 * Send video JS event
 */
videoJSEvent(data: any) {
  this.sendVideoJSEvent.next(data);
}

getSystemStatus() {
  let isMac = navigator.platform;
  if (isMac == "Win32") {
    return "NON_MAC";
  } else {
    return "MAC";
  }
}

getKey(keysArray: string[]) {
  if (!keysArray.length)
    return [];
  return keysArray.map((key: string) => {
    if (key == 'control')
      return 'ctrl';
    return key;
  });
}

async UpdateFontsAndBookmarks(payLoad: any){
  try {
    const res: any = await this.api.put(`${this.config.ENTERPRISE_TRANSCRIPTION.UPDATE_TRANSCRIPTION}`, {...payLoad}, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

async getSpeakerTags(jobId: number, part_no: number){
  try {
    const res: any = await this.api.get(`${this.config.ENTERPRISE_TRANSCRIPTION.DELETE_UPDATE_SPEAKER_DATA}?job_id=${jobId}&part_no=${part_no}`, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}
async downloadTranscription(data: any){
  try {
    const res: any = await this.api.post(`${this.config.ENTERPRISE_TRANSCRIPTION.DOWNLOAD_TRANSCRIPTION}`, data, true).toPromise();
    return res;
  } catch (e: any) {
    return Promise.reject(e?.error);
  }
}

setCurrentCursorPosition(elementId: string, chars: number = -1, speakerIndex?: number) {
  // this.transcriptionCaretPos = false;
    setTimeout(() => {
      let focusElement: any;
      if (elementId) {
        focusElement = document.getElementById(elementId);
        if (chars == -1 && focusElement) {
          chars = focusElement.innerText.trim().length+1;
          if (focusElement.innerText[chars-1] == ' ')
             chars -= 1;
        }
      } else if (speakerIndex != undefined && speakerIndex >= 0) {
        focusElement = document.getElementById(`speaker-texts-${speakerIndex}`);
      }
      if (focusElement) {
        var selection = window.getSelection();
        let range = this.createRange(focusElement, { count: chars });
        if (range && selection) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
       }
      }
    }, 0);
};

createRange(node: any, chars: any, range?: any) {
  if (!range) {
      range = document.createRange()
      range.selectNode(node);
      range.setStart(node, 0);
  }
  if (chars.count === 0) {
      range.setEnd(node, chars.count);
  } else if (node && chars.count >0) {
      if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent.length < chars.count) {
              chars.count -= node.textContent.length;
          } else {
               range.setEnd(node, chars.count);
               chars.count = 0;
          }
      } else {
          for (var lp = 0; lp < node.childNodes.length; lp++) {
              range = this.createRange(node.childNodes[lp], chars, range);

              if (chars.count === 0) {
                 break;
              }
          }
      }
 }
 return range;
}

/**
   * Bulk Update API For Undo and Redo
   */
async bulkUpdation(data: any) {
  try {
    const res = await this.api.put(this.config.ENTERPRISE_TRANSCRIPTION.UPDATE_TRANSCRIPTION, data, true).toPromise();
    return res;
  } catch (e: any) {
    return e;
  }
}

}

export namespace ManageWorkFlowLevel{
  export interface Payload {
    // user_id: number;
    job_id: number;
    level_id: number;
    sub_level_no: number;
    level_status: Status;
    project_id:number;
    part_no?: number;
  }
  export type Status  = 'COMPLETED' | 'CLOSE' | 'GOTO_CAPTIONING' | 'BACK_TO_TRANSCRIPTION'
}

interface AFC_CONVERTER {
  job_id: string;
  level_id: number;
  frame_rate:string;
  language_ids: number[];
  // part_no?: number;
}

interface DeliverTranscriptionsPayload extends Omit<AFC_CONVERTER,'language_ids'| 'frame_rate'>{
  language_id:number;
  preset_id: number;
  part_no?: number;
}

interface DeliverCaptioningPayload extends Omit<DeliverTranscriptionsPayload,'preset_id'>{}
