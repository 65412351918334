import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { MenuItemModel } from 'src/app/pages/help/menu-item-model';
import { GenericSnackbarComponent } from '../modals/generic-snackbar/generic-snackbar.component';
import { AccountDetailsStateModel } from '../models/account-details-state.model';
import { AuthService } from './auth.service';
import { CustomToasterService } from './custom-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class AppCommonService {
  public appSubject = new ReplaySubject<any>();
  public appObservable = this.appSubject.asObservable();

  public themeToggle = new BehaviorSubject('dark');
  public getThemeValue$ = this.themeToggle.asObservable();
  public readonly calenderIcon = '../../../assets/images/calendar.svg';
  public readonly backIcon = '../../../assets/images/back.svg';
  public readonly settingsIcon = '../../../assets/images/settings.svg';
  public readonly passwordIcon = '../../../assets/images/password.svg';
  public readonly exitIcon = '../../../assets/images/exit.svg';
  public readonly homeIcon = '../../../assets/images/home.svg';
  public readonly manageIcon = '../../../assets/images/manage.svg';
  public readonly pricingIcon = '../../../assets/images/pricing.svg';
  public readonly bellIcon = '../../../assets/images/bell.svg';
  public readonly hamburgerIcon = '../../../assets/images/hamburger.svg';
  public readonly themeSwitchIcon = '../../../assets/images/theme-switch.svg';
  public readonly userWhiteIcon = '../../../assets/images/user-white.svg';
  public readonly searchIconLightIcon = '../../../assets/images/search-icon-light.svg';
  public readonly closeCircledIcon = '../../../assets/images/close-cirlced.svg';
  public readonly sideProjectIcon = '../../../assets/images/side-project.svg';
  public readonly videoFileIcon = '../../../assets/images/video-file.svg';
  public readonly sideHelpIcon = '../../../assets/images/side-help.svg';
  public readonly smlSpeakerTagIcon = '../../../assets/images/sml-speaker-tag.svg';
  public readonly smlSoundCuesIcon = '../../../assets/images/sml-sound-cues.svg';
  public readonly undoIcon = '../../../assets/images/undo.svg';
  public readonly redoIcon = '../../../assets/images/redo.svg';
  public readonly closeIcon = '../../../assets/images/close.svg';
  public readonly boldIcon = '../../../assets/images/bold.svg';
  public readonly italicsIcon = '../../../assets/images/italics.svg';
  public readonly bookmarkIconIcon = '../../../assets/images/bookmark-icon.svg';
  public readonly soundCuesIcon = '../../../assets/images/sound-cues.svg';
  public readonly speakerTagIcon = '../../../assets/images/speaker-tag.svg';
  public readonly iconInfoIcon = '../../../assets/images/icon-info.svg';
  public readonly encloseLyricsIcon = '../../../assets/images/music.svg';
  public readonly singleLeftArrowIcon = '../../../assets/images/single-left-arrow.svg';
  public readonly singlerightArrowIcon = '../../../assets/images/single-right-arrow.svg';
  public readonly captionAppendDownIcon = '../../../assets/images/caption_appendDown.svg';

  isLoading = new Subject<boolean>();
  public ignoreLoaders = ['jobs-status', 'user-activity', 'create-job', 'refresh-token', 'bookmark', 'caption_bookmark','spectrogram'];
  public ignoreGetLoaders = ['sound-cues','project-languages'];
  public ignoreUpdateLoaders = ['captions', 'transcriptions', 'bookmark', 'caption_bookmark', 'sound-cues', 'section', 'transcription_updates'];

  /* Start common permissions for menu items */
  public readonly filesMenuPermissions = [
    'CAN_VIEW_FILES', 
    'CAN_EXPORT_FILES', 
    'CAN_VIEW_FILES_PROGRESS', 
    'CAN_EXPORT_FILES_PROGRESS', 
    'CAN_VIEW_ASSIGNMENTS', 
    'CAN_MANAGE_ASSIGNMENTS', 
    'CAN_ADD_NEW_JOB',
    'CAN_DOWNLOAD_FILES',
    'CAN_CHANGE_PRESET',
    'CAN_CHANGE_TAT',
    'CAN_ASSIGN_JOB',
    'CAN_CHANGE_PRIORITY'
    
  ];
  
  public readonly billingPermissions = [
    'CAN_VIEW_ACCOUNT_BALANCE',
    'CAN_VIEW_TRANSACTIONS',
    'CAN_VIEW_CONSUMPTIONS',
    'CAN_VIEW_PRICING'
  ];
  public readonly servicePricingPermissions = [
    'VIEW_GENERAL_PRICING',
    'MANAGE_GENERAL_PRICING',
    'VIEW_ACCOUNT_PRICING',
    'MANAGE_WORKFLOW_PRICING'];
  public readonly dashboardPermissions = [
    'CAN_VIEW_CONSUMPTIONS',
    'CAN_VIEW_FILES_PROGRESS',
    'CAN_VIEW_RESOURCE_STATUS',
    'CAN_VIEW_ACCOUNT_BALANCE',
    'CAN_VIEW_SERVICE_UPTIME',
    'CAN_MANAGE_SERVICE_ALERTS',
    'CAN_VIEW_TIME_SPENT_REPORT',
    'MANAGE_ACCOUNT_WISE_TIME_SPENT',
    'CAN_VIEW_WORK_WISE_TIME_SPENT',
    'CAN_VIEW_USER_STATUS'
  ];
  public readonly settingsPermissions = [
    'CAN_VIEW_PROJECT_EVENTS',
    'CAN_MANAGE_PROJECT_EVENTS',
    'CAN_VIEW_PROJECT_NOTIFICATIONS',
    'CAN_MANAGE_PROJECT_NOTIFICATIONS'
  ];
  public readonly accountPermissions = [
    'MANAGE_ACCOUNTS',
    'MANAGE_ACCOUNTS'
  ];
  public readonly adminManagePermissions = [
    'CAN_MANAGE_ROLES',
    'CAN_VIEW_ROLES',
    'CAN_VIEW_USERS',
    'CAN_MANAGE_USERS',
    'CAN_VIEW_TEAMS',
    'CAN_MANAGE_TEAMS',
    'CAN_VIEW_GLOBAL_PRESETS',
    'CAN_MANAGE_GLOBAL_PRESETS',
    'CAN_VIEW_ASR_DEFINITION',
    'CAN_MANAGE_ASR_DEFINITION'
  ];
  public readonly pmaProjects = [
    'CAN_VIEW_PROJECTS',
    'CAN_MANAGE_PROJECTS',
    'CAN_VIEW_PRESETS',
    'CAN_MANAGE_PRESETS'
  ];
  public readonly notifications = [
    'CAN_VIEW_NOTIFICATIONS'
  ];

  constructor(
    public snackBar: MatSnackBar,
    private authService: AuthService,
    public customToasterService: CustomToasterService,
    private router: Router,
    private _httpClient: HttpClient
  ) { }

  /**
   * Method for show loader.
   *
   */
  show() {
    this.isLoading.next(true);
  }

  /**
   * Method for hide loader.
   *
   */
  hide() {
    this.isLoading.next(false);
  }


  /* get username */
  getUserName() {
    return localStorage.getItem('username') ? localStorage.getItem('username') : '';
  }

  /* get logged in userId  */
  getUserId() {
    return localStorage.getItem('userId') ? localStorage.getItem('userId') : '';
  }


  /**
   * Check if user is admin or normal user
   */
  isSuperAdmin() {
    let permissions: any = localStorage.getItem('permissions');
    permissions = permissions ? JSON.parse(permissions) : null;
    return permissions ? permissions.includes('MANAGE_ACCOUNTS') : false;
  }


  /**
   * Checks if a user exists in local storage.
   *
   * @param checkExpiry - By default, this function will also check the "expiry" of the token against device time. If set to false, this function will instead
   * only check for presence of the token.
   *
   * @returns True if user is considered logged in, false otherwise.
   */
  isUserLoggedIn(checkExpiry = false): boolean {
    const userToken = localStorage.getItem('token');
    let expiryTime: any = localStorage.getItem('expiryTime');
    let isloggedIn = false;

    // if we aren't checking expiry, as long as the token is present, we will consider them logged in
    // note: when checking if the user is logged in, we will typically not check the expiry of the token. Token expiry will
    // only be taken into account for api interaction. At that point, if the token is expired, we will refresh the token.
    // this saves us from needing token refresh logic in both the auth guard AND the api interface
    if (userToken && !checkExpiry) {
      isloggedIn = true;
    }

    // our expiry timestamp is in seconds, javascript provides unix timestamps in ms
    // if it we have a token and it hasn't expired: return true
    if ((parseInt(expiryTime) > Date.now())) {
      isloggedIn = true;
    }

    return isloggedIn;
  }
  /**
   * Display single message in snackbar
   * @param message Text
   */
  showSuccess(message: string, duration=3000) {
    this.snackBar.openFromComponent(GenericSnackbarComponent, {
      panelClass: "success-snackbar",
      horizontalPosition: "right",
      verticalPosition: "bottom",
      duration: duration,
      data: {
        message,
        icon: 'checkmark_skyblue',
      }
    });
  }

  /**
   * Display Error message in snackbar
   * @param message Text
   */
  showError(message: string) {
    this.snackBar.openFromComponent(GenericSnackbarComponent, {
      panelClass: "snackbar",
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "bottom",
      data: {
        message,
        icon: 'error_outline',
      }
    });
  }

  /**
   * To display custom snackbar, pass templateref in data JSON
   * @param data
   * @param duration To display custom snackbar for given milisecond, provide `null` to show always.
   * @default duration = 5000
   */
  showCustomMessage(data: any, duration: number | null = 5000) {
    let opts: MatSnackBarConfig<any> = {
      panelClass: "custom_snackbar",
      horizontalPosition: "center",
      verticalPosition: "top",
      data
    }
    if (duration != null) {
      opts['duration'] = duration
    }
    this.snackBar.openFromComponent(GenericSnackbarComponent, opts);
  }

  /**
   * Display custom component in snackbar
   * @param component Text
   */
  showCustomSnackBar(customComponent: any, data: any, duration: number = 0) {
    this.snackBar.openFromComponent(customComponent, {
      duration: duration,
      data: data,
      horizontalPosition: "right",
      verticalPosition: "bottom",
      panelClass: "success-snackbar",
    });
  }

  /**
   * Dismiss active snackbar
   */
  dismissActiveSnackBar() {
    this.snackBar.dismiss();
  }

  /**
   * AccountDetails local storage save common properties
   * @param accountID
   * @param isEdit
   * @param isDetail
   */

  public setAccountDetailData(account_id: number, isEdit: boolean, isDetail?: boolean, accountName?: string, accountStatus?: number) {
    this.removeAccountDetailsState()
    let stateData: AccountDetailsStateModel = {
      account_id: account_id,
      isEdit: isEdit,
      isDetail: isDetail,
      account_name: accountName,
      account_status: accountStatus
    }
    localStorage.setItem('accountDetailsState', JSON.stringify(stateData));
  }

  /**
   * Remove accountDetailsState from local storage
   */

  public removeAccountDetailsState() {
    localStorage.removeItem('accountDetailsState');
  }

  /**
  * Remove accountDetailsState from local storage
  */

  public getAccountDetailsState(): AccountDetailsStateModel | null {
    try {
      let strData = localStorage.getItem('accountDetailsState') as string;
      let stateData = JSON.parse(strData) as AccountDetailsStateModel;
      return stateData;
    } catch (error) {
      return null;
    }

  }

  public async captureUserActivity(activity: string, pageName?: string) {
    try {
      const res: any = await this.authService.captureUserActivity(activity, this.router.url);
    } catch (e) {
      this.customToasterService.error(
        'Something went wrong , please try again'
      );
    }
  }

  /* Permissions exists check */
  public isPermissionsExists(checkPermissions: string[]): boolean {
    let permissionsStr: any = localStorage.getItem('permissions');
    if (permissionsStr) {
      let permissions = JSON.parse(permissionsStr);
      if (checkPermissions.some(v => permissions.includes(v))) {
        return true;
      }
    }
    return false;
  }

  /* Redirect to default menu item based on permissions */
  public redirectToAccessibleMenuItem() {
    if (this.isPermissionsExists(this.dashboardPermissions)) {
      this.router.navigate(['/pages/dashboard']);
    } else if (this.isSuperAdmin() && this.isPermissionsExists(this.accountPermissions)) {
      this.router.navigate(['/pages/admin-accounts']);
    } else if (!this.isSuperAdmin() && this.isPermissionsExists(this.pmaProjects)) {
      this.router.navigate(['/pages/pma-projects']);
    } else if (this.isPermissionsExists(this.adminManagePermissions)) {
      this.router.navigate(['/pages/admin-manage']);
    } else if (this.isSuperAdmin() && this.isPermissionsExists(this.servicePricingPermissions)) {
      this.router.navigate(['/pages/service-pricing']);
    } else if (!this.isSuperAdmin() && this.isPermissionsExists(this.billingPermissions)) {
      this.router.navigate(['/pages/billing']);
    } else if (this.isPermissionsExists(this.filesMenuPermissions)) {
      this.router.navigate(['/pages/files/files']);
    } else if (this.isPermissionsExists(this.settingsPermissions)) {
      this.router.navigate(['/pages/settings/admin-settings']);
    } else {
      this.router.navigate(['/permissions-not-found']);
    }
  }
  onGetLanguage(key:string) {
    return this._httpClient.get('assets/language/language-' + key + '.json');
  }

  ongetMenuList(langulage:string):Observable<any>{
    return this._httpClient.get('assets/i18n/'+langulage+'-menu.json');
  }
  ongetFaqsList(langulage:string):Observable<any>{
    return this._httpClient.get('assets/i18n/'+langulage+'-faqs.json');
  }

  onGetMenuObject(){
    return this._httpClient.get('assets/json/menu-keys.json');
  }
}
