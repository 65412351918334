import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExtraHeaderType } from '../models/ExtraHeaderType.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  previousPostRequestData: any; // This is used to cache the request data going out in post API calls

  previousPostRequestResult: any; // stores the result of the cached request

  previousPutRequestData: any;

  previousPutRequestResult: any;

  constructor(private http: HttpClient) {
  }

  /**
   * Sends a HTTP POST to the API at the given end point path.
   *
   * @param path - The end point path to send the request to.
   * @param body - The body of the post request.
   * @param [requireAuth=false] - If the call should have the auth headers attached.
   * @param [params] - Optional query params for the request.
   * @param [responseType=false] -Set response type flag for file type.
   * @param fullResponse - To get response object and not the body content.
   */
  post(path: string, body: any, requireAuth = false, params?: any, responseType = false, fullResponse = false, extraHeaders: ExtraHeaderType[] = []) {
    let allHeaders = this.generateHeaders(requireAuth) as HttpHeaders;
    if (extraHeaders.length > 0) {
      extraHeaders.forEach(header => {
        allHeaders = allHeaders.set(header.name, header.value);
      })
    }
     // check if responseType = false then set response type arraybuffer
    const options: {
      headers?: HttpHeaders,
      observe?: any,
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: any,
      withCredentials?: boolean
    } = {
      params,
      headers: allHeaders,
      responseType: responseType ? 'arraybuffer' as const : 'json',
      observe: (fullResponse ? 'response' as const : 'body')
    };

    const currentPostRequestData = { path, body };
    // Compare previous and current post request data
    if (JSON.stringify(this.previousPostRequestData) === JSON.stringify(currentPostRequestData)) {
      // The previous and current post data is same so we block the request going to servers if it was previously successful
      console.log('duplicate post detected');

      // we'll also return the result of the previous request if it was a success, if it errored, we'll let it pass
      // Since whatever is consuming this function is expecting to be returned a
      // call from the http client, we'll have to wrap it in of() to return an observable
      if (this.previousPostRequestResult?.ok) {
        console.error('duplicate post blocked');
        return of(this.previousPostRequestResult);
      }
    }

    // The previous and current data is different so lets proceed with the request and take the data in global variable
    this.previousPostRequestData = currentPostRequestData;

    return this.http.post(environment.baseUrl + path, body, options).pipe(
      tap((res) => { // Tap into the response, so we can set the previousRequestPostResult to give to any subsequent duplicate API calls
        this.previousPostRequestResult = res;
      }, (err) => { // if the result is an error, then we don't want to adhere to the 10 second rule, let them retry again if they choose to
        this.previousPostRequestData = null;
        this.previousPostRequestResult = null;
      }), finalize(() => { // finalize is called whenever the observable is closed
        setTimeout(() => { // To substantially block duplicate requests, we need timeout
          this.previousPostRequestData = null;
          this.previousPostRequestResult = null;
        }, 10000); // This means two post requests with same data wont be entertained within 10 seconds.
      })
    );
  }

  /**
   * Sends a HTTP PUT to the API at the given end point path.
   *
   * @param path - The end point path to send the request to.
   * @param body - The body of the put request.
   * @param [requireAuth=false] - If the call should have the auth headers attached.
   * @param [params] - Optional query params for the request.
   * @param [responseType=false] -Set response type flag for file type.
   *
   */
  put(path: string, body: any, requireAuth = false, params?: any, responseType = false) {
    const currentPostRequestData = { path, body };
    // Compare previous and current post request data
    if (JSON.stringify(this.previousPutRequestData) === JSON.stringify(currentPostRequestData)) {
      // The previous and current post data is same so we block the request going to servers if it was previously successful
      console.log('duplicate post detected');

      // we'll also return the result of the previous request if it was a success, if it errored, we'll let it pass
      // Since whatever is consuming this function is expecting to be returned a
      // call from the http client, we'll have to wrap it in of() to return an observable
      if (this.previousPutRequestResult?.statusCode === 200) {
        console.error('duplicate post blocked');
        return of(this.previousPutRequestResult);
      }
    }

    // The previous and current data is different so lets proceed with the request and take the data in global variable
    this.previousPutRequestData = currentPostRequestData;
    // check if responseType = false then set response type arraybuffer
    if (responseType) {
      return this.http.put(environment.baseUrl + path, body, { params, headers: this.generateHeaders(requireAuth), responseType: 'arraybuffer' });
    }
    return this.http.put(environment.baseUrl + path, body, { params, headers: this.generateHeaders(requireAuth) }).pipe(
      tap((res) => { // Tap into the response, so we can set the previousRequestPostResult to give to any subsequent duplicate API calls
        this.previousPutRequestResult = res;
      }, (err) => { // if the result is an error, then we don't want to adhere to the 10 second rule, let them retry again if they choose to
        this.previousPutRequestData = null;
        this.previousPutRequestResult = null;
      }), finalize(() => { // finalize is called whenever the observable is closed
          this.previousPutRequestData = null;
          this.previousPutRequestResult = null;
        // setTimeout(() => { // To substantially block duplicate requests, we need timeout
        //   this.previousPutRequestData = null;
        //   this.previousPutRequestResult = null;
        // }, 10000); // This means two post requests with same data wont be entertained within 10 seconds.
      })
    );
  }

  /**
   * Sends a HTTP PATCH to the API at the given end point path.
   *
   * @param path - The end point path to send the request to.
   * @param body - The body of the patch request.
   * @param [requireAuth=false] - If the call should have the auth headers attached.
   * @param [params] - Optional query params for the request.
   *
   */
  patch(path: string, body: any, requireAuth = false, params?: any) {
    return this.http.patch(environment.baseUrl + path, body, { params, headers: this.generateHeaders(requireAuth) });
  }

  /**
   * Sends a HTTP GET to the API at the given end point path.
   *
   * @param path - The end point path to send the request to.
   * @param [requireAuth=false] - If the call should have the auth headers attached.
   * @param [params] - Optional query params for the request.
   * @param withCredentials - Optional if the request should attach http cookie for auth.
   * @param [responseType=false] -Set response type flag for file type.
   *
   */
  get(path: string, requireAuth = false, params?: any, withCredentials?: boolean, responseType = false, applyBaseAPIUrl?:boolean) {
    // check if responseType = true then set response type arraybuffer
    const options: {
      headers?: HttpHeaders,
      params?: HttpParams,
      responseType: any,
      withCredentials?: boolean
    } = {
      params,
      headers: this.generateHeaders(requireAuth) as HttpHeaders,
      responseType: responseType ? 'arraybuffer' as const : 'json',
      withCredentials
    };

    return this.http.get(applyBaseAPIUrl=== false ? path : environment.baseUrl + path, options);
  }

  /**
   * Sends a HTTP DELETE to the API at the given end point path.
   *
   * @param path - The end point path to send the request to.
   * @param {boolean=false} requireAuth - If the call should have the auth headers attached.
   * @param params - Optional query params for the request.
   * @param withCredentials - Optional if the request should attach http cookie for auth.
   */
  delete(path: string, requireAuth = false, params?: any, withCredentials?: boolean) {
    return this.http.delete(environment.baseUrl + path, { params, headers: this.generateHeaders(requireAuth), withCredentials });
  }

  /**
   * Add Authorization Token and API key to the request.
   *
   * @param authHeaderRequired - If the call should have the auth
   * headers attached.
   */
  generateHeaders(authHeaderRequired: boolean): HttpHeaders {
    // append our api key
    let header = new HttpHeaders();

    // add our auth header if required
    if (authHeaderRequired) {
      const unparsedToken = window.localStorage.getItem('token');
      if (unparsedToken) {
        const token = unparsedToken;

        if (token) {
          header = header.set('Authorization', `Bearer ${token}`);
        }
      }
    }

    return header;
  }
}
