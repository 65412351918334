import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericModalService } from '../../services/generic-modal.service';

@Component({
  selector: 'app-permissions-not-found',
  templateUrl: './permissions-not-found.component.html',
  styleUrls: ['./permissions-not-found.component.scss']
})
export class PermissionsNotFoundComponent implements OnInit {

  constructor(private modal:GenericModalService, private router:Router) { }

  ngOnInit(): void {
    const isUserId = localStorage.getItem('userId')
    if(!isUserId || isUserId === null || isUserId === undefined || isUserId === ''){
      this.modal.openDialog('Notification','Session expired','OK').subscribe((res) => {
          localStorage.clear()
          this.router.navigate(['login'])
      })
    }
  }

}
