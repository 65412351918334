import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  //regex =/^-\d*((\d+(\.\d{0,2})?)|((\d*(\.\d{0,2}))))$|^\d*((\d+(\.\d{0,2})?)|((\d*(\.\d{0,2}))))$/;
   regex = /^(-)?\d{0,5}(\.\d{0,2})?$/;

  public payment: FormControl = new FormControl('',[Validators.required,Validators.pattern(this.regex)]);
  public today = new Date();
  public receivedDate: Date = this.today;

  constructor(
    public dialogRef: MatDialogRef<AddPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
  }
  onConfirm(): void {
    this.dialogRef.close({
      amount: this.payment.value,
      recieved_date: this.receivedDate
    });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close('');
  }

  private specialKeys = [
		'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
	];

  validateNumberField(e: Event) {
    if (e instanceof KeyboardEvent) {
      if (this.specialKeys.indexOf(e.key) !== -1) {
        return;
      }
      let inputElement = e.target as HTMLInputElement;
      let current: string = inputElement.value;
      let cursorPosition: number = inputElement.selectionStart || 0;
      let next: string = current.slice(0, cursorPosition) + e.key + current.slice(cursorPosition);
      if (next && !next.match(this.regex)) {
        e.preventDefault();
      }
    } else if (e instanceof InputEvent) {
      if (!e.data) return
      if (e.data === '.') return
      if (e.data && !!!e.data.match(this.regex)) {
        let inputElement = e.target as HTMLInputElement;
        inputElement.value = inputElement.value.replace(e.data, '');
        return
      };
    }
  }
  
}
