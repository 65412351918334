export const locale = {
  lang: 'en',
  data: {
    dashboard: {
      pma_dashboard: {
        title: 'Home',
        consumptions: {
          title: 'Consumptions',
        },
        job_status: {},
        resources_status: {},
        account_balance: {
          total: '',
        },
      },
    },
    help: {
      title: 'Help',
      //home-dashboard
      helphome: {
        title: 'Table of Content',
        content:
          'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.Click on the <b>New Role</b> button in the top-right corner of the <b>Roles</b> page. The application redirects to the <b>Add New Role</b> window.Give a name to the role in the <b>Role Name</b> field.Select the permissions of the role from the list of all permissions in the <b>Show All</b> section of the <b>Available Permissions</b> field.',
      },
      // FAQ's
      faqs: {
        question1: 'How to create a new project in TranceIQ?',
        answer1:
          'A project is a unit of segregation within the client account to group jobs based on services required, workflow, turn around time (TAT), preset requirements, etc. Usually, multiple media files will have the same captioning requirements. So, it is very time-saving to configure the requirements in a project and add all jobs (media files) under that project. This simplifies repeatedly setting up requirements individually for all jobs that belong to the same category.</br>Consider an example if generating captions for multiple episodes of the same production. A project is first created with all the requirements of that particular production. Then all the episodes (media files) of that production are uploaded as individual jobs under that project, without having to set up the same requirements for every episode (job).</br>Please check out the ',
        answer1link: 'Create Projects to Customize Job Requirements',
        answer1b:
          ' section in <b>Workflow Guide</b> to get a step-by-step guide on project creation in TranceIQ.',
        question2: 'How to create a new job in TranceIQ?',
        answer2:
          'TranceIQ is an advanced workflow application that provides Transcription, Captioning, Translation, and Caption Conformance services for media files. To utilize the TranceIQ services on a particular file, users have to first upload that media file into the application. Every media file uploaded in the application for processing through the TranceIQ services is known as a <b>job</b>. Creating a job includes both uploading the job and also configuring its requirements.</br>Please check out the ',
        answer2link: 'Upload Jobs and Check Job Statuses',
        answer2b:
          ' section in <b>Workflow Guide</b> to get a step-by-step guide on job creation in TranceIQ.',
        question3: 'How to create a new employee in TranceIQ?',
        answer3:
          'When a particular organization purchases access to TranceIQ, all employees of that organization, who want to utilize the services and manage the organization’s client account in the application, need to have separate user accounts in TranceIQ. Creating new employees in TranceIQ, hence, means creating new user accounts for the employees. This ensures that all the employees have separate user credentials to individually work on separate jobs.',
        answer3Content1:
          'Please check out the ',
        answer3link: 'Create Users',
        answer3b:
          ' section in <b>Workflow Guide</b> to get a step-by-step guide on users’ creation in TranceIQ.',
        question4: 'How to create a new caption template in TranceIQ?',
        answer4:
          'Embedding captions in media files requires specifying details about how the captions have to load on the media file. These details are provided in the form of caption templates, which are technically known as <b>presets</b>. A preset is a well-defined set of rules which define how captions have to load on the respective media file. The rules include every minute configuration regarding subtitling, like caption position, arrangement, spacing, caption words’ splitting, etc.</br>TranceIQ has a provision for users to create presets with complete customization depending on the job and requirements. Please check out the ',
        answer4link: ' Manage Presets',
        answer4b:
          ' section in <b>Workflow Guide</b> to get a step-by-step guide on preset creation in TranceIQ.',
        question5: 'How to translate captions in TranceIQ?',
        answer5:
          'Translation service in TranceIQ converts the finalized and synced captions in the media files into other languages, in order to provide regional captioning for international media content or vice versa. Apart from the subtitle language translation, the translation service also provides a well-equipped space to manually verify and edit the syncing of the captions with the media file, along with their format and arrangement.</br>Please check out the ',
        answer5link: ' Translate to Secondary Language ',
        answer5b:
          ' section in <b>Workflow Guide</b> to get a step-by-step guide on translating captions in TranceIQ.',
        question6:'What are the different services available in TranceIQ?',
        answer6:'TranceIQ provides 5 different services: ',
        answer6heading1:'<b>Automatic Speech Recognition (ASR):</b>',
        answer6content1:' The ASR service is a speech-to-text service that converts the audio input from media files into texts, known as transcripts.',
        answer6heading2:'<b>Transcription:</b>',
        answer6content2:' The transcription service provides a well-equipped space to manually verify, edit, and format the ASR output i.e., the transcripts.',
        answer6heading3:'<b>Captioning:</b>',
        answer6content3:' Captioning service embeds the audio transcripts into the respective media files. And then provides a well-equipped space to manually verify and edit the captions sync with the media file, along with their format and arrangement.',
        answer6heading4:'<b>Translation:</b>',
        answer6content4:' Translation service converts the finalized and synced captions in the media files into other languages, in order to provide regional captioning for international media content or vice versa. Translation service also comes with all the features available in Captioning service, to verify and edit the format and arrangement of the translated subtitles.',
        answer6heading5:'<b>DRM Service:</b>',
        answer6content5:' Digital Rights Management (DRM) Service is another unique service provided in TranceIQ to protect the users’ digital content that they upload to the application. While using the above-mentioned TranceIQ services on copyrighted media files that require extra protection in making sure that the content is not shareable, the DRM service can be utilized. When subscribed to the DRM service, the application strictly ensures that no user can share, download, or screencast the uploaded media files by any means.'
      },
      //Get Started with Trance
      GetStartedwithTrance: {
        title: 'Get Started with TranceIQ',
      },
      launchTheTranceApplication: {
        title: 'Launch the TranceIQ Application',
        content1:
          'Connect with Digital Nirvana Support Team to get access to the TranceIQ application',
        content2:
          'The URL(s) of the TranceIQ web interface will then be sent to the user’s registered email. Open the URL in any supported web browser and the application will be launched.',
      },
      logintoTheTranceApplication: {
        title: 'Login into the TranceIQ application',
        content1:
          'View the login credentials sent by the Digital Nirvana Support Team to the user’s registered email.',
        content2: 'Launch the TranceIQ application ',
        content3:
          'and enter the credentials in the respective <b>Username/Email</b> and <b>Password</b> fields.',
        content4:
          'Finally, click on the <b>Sign In</b> button to successfully log in to the application.',
      },
      resetAccountPassword: {
        title: 'Reset Account Password',
        content1:
          'To reset missing/forgotten passwords, click on the <b>Forgot Password?</b> link available on the screen during ',
        contentlink: 'application login.',
        content2:
          'The application opens the <b>Forgot Password</b> window. Enter the user’s registered email in the respective field and click on the <b>Get OTP</b> button.',
        content3:
          'Check the respective email account for an email from Digital Nirvana Support Team with the OTP for Password Reset.',
        content4:
          'Enter the received 4-digit OTP in the respective field in the <b>Forgot Password</b> window.',
        content5:
          'Click on the <b>Submit</b> button and the window redirects to the <b>Create New Password</b> window.',
        content6:
          'Enter a new password of choice and click on the <b>Submit</b> button to successfully reset the password of the respective account.',
        note: 'TranceIQ Password Policy — <p>(i) The password expires after 90 days.</p><p> (ii) The password should contain a minimum of 8 to a maximum of 24 characters.</p><p> (iii) The password should contain at least 1 upper case letter and at least 1 number.</p>',
      },
      changeTheApplicationTheme: {
        title: 'Change the Application’s Theme',
        content1: 'After ',
        contentlink: ' logging into the TranceIQ application',
        content1b:
          ", click on the <b>menu</b> icon (<img class='help-img' src={{value}}>) at the top left corner of the page to open the elaborated Global Sliding Menu.",
        content2:
          'Click on the <b>View in Light Theme</b> option at the bottom of the vertical menu bar to successfully change the theme of the application.',
      },
      changeAccountPassword: {
        title: 'Change Account Password',
        content1: 'After ',
        contentlink: ' logging into the TranceIQ application',
        content1b:
          ", click on the <b>menu</b> icon (<img class='help-img' src={{value}}>) at the top left corner of the page to open the elaborated Global Sliding Menu.",
        content2:
          'Click on the <b>User Profile Name</b> option at the bottom of the vertical menu bar to open its dropdown menu.',
        content3:
          'Click on the <b>Change Password</b> option and the <b>Change Password</b> window will be opened.',
        content4:
          'Enter the Current Password and the New Password in the respective fields and click on the <b>Confirm</b> button to successfully change the account password, or <b>Cancel</b> to terminate the action.',
      },
      signOutOfTrance: {
        title: 'Sign out of TranceIQ',
        content1:
          "Click on the <b>menu</b> icon (<img class='help-img' src={{value}}>) at the top left corner of the page to open the elaborated Global Sliding Menu.",
        content2:
          'Click on the <b>User Profile Name</b> option at the bottom of the vertical menu bar to open its dropdown menu.',
        content3:
          'Click on the <b>Log Out</b>  option to successfully log out of the respective account in the application.',
      },
      //Get Account Overview from Home Dashboard Page
      GetAccountOverviewfromHomeDashboardPage: {
        title: 'Get Account Overview from Home Dashboard Page',
      },
      getOverviewOfProjectWiseConsumptionTime: {
        title: 'Get an Overview of ProjectWise Consumption Time',
        content1:
          'Select the <b>Home</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Home-Dashboard</b> page.',
        content2:
          'Focus on the <b>Consumption</b> section at the top-left for data about <b>ProjectWise Consumption Time</b> for the account.',
        content3:
          'View the list of projects in the account and the number of hours consumed in utilizing TranceIQ application services by each of them respectively.',
        content4:
          'To the left, view the graphical representation of the number of hours consumed in utilizing TranceIQ application services by every project in the account. Hover the mouse on any colored sector of the circular graph to get the name of the project whose data it represents.',
        content5:
          "Click on the calendar icons (<img class='help-img' src={{value}}>) against the <b>From</b> and <b>To</b> fields at the top to select the “from date” and “to date” and get the data from a filtered time frame.",
      },
      getTheStatusOfTheNumberOfCompletedOrInProgressJobs: {
        title: 'Get the Status of the Number of Completed/In Progress Jobs',
        content1:
          'Select the <b>Home</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Home-Dashboard</b> page.',
        content2:
          'Focus on the <b>Job Status</b> section at the top right to know the overall status of all the jobs uploaded to the account.',
        content3:
          'In the <b>Completed</b> field, view the no. of jobs that have completed processing in the TranceIQ application and the combined duration of processing time taken by those jobs to date.',
        content4:
          'In the <b>In Progress</b> field, view the no. of jobs that are under the processing stages of the TranceIQ application and the combined duration of processing time taken so far by those jobs.',
        content5:
          'In the <b>Total</b> field, view the total no. of both <b>Completed</b> and <b>In Progress</b> jobs in the account and their combined duration of processing time taken.',
        content6:
          'To the left, view the graphical representation of the number of <b>Completed</b> and <b>In Progress</b> jobs in the account. Hover the mouse on any colored sector of the circular graph to get details about that sector.',
        content7:
          "Click on the calendar icons (<img class='help-img' src={{value}}>) against the <b>From</b> and <b>To</b> fields at the top to select the “from date” and “to date” to get the data from a filtered time frame.",
      },
      getAnOverviewOfTheNoOfEmployeesAndTheirWorkingStatus: {
        title:
          'Get an Overview of the No. of Employees and their Working Status',
        content1:
          'Select the <b>Home</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Home-Dashboard</b> page.',
        content2:
          'Focus on the <b>Resource Status</b> section at the bottom-left to get an overview of the number of employees using the TranceIQ application under the respective account and their working status.',
        content3:
          'View data about the total no. of employees (resources) that are currently working under different categories of the TranceIQ application (i.e., online), against the <b>Editors Online, Captioners Online, Auditors Online, Supervisors Online</b> category names respectively.',
        content4:
          'Under each category, view data about the no. of users under that category who are currently working on any task (<b>Occupied</b>) and the no. of users who are available to take up new work (<b>Available</b>).',
        content5:
          'To the left, view the no. of resources (employees) online (all categories combined) out of the total no. of resources registered under the account.',
      },
      getAccountBalanceOverview: {
        title: 'Get Account Balance Overview',
        content1:
          'Select the <b>Home</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Home-Dashboard</b> page',
        content2:
          'Focus on the <b>Account Balance</b> section at the bottom right to get an overview of the payment and balance-related information.',
        content3:
          'Under the <b>Payment</b> field, view the total amount of money paid under the account for utilizing the different services in the application.',
        content4:
          'Under the <b>Consumed</b> field, view the total amount of money that is consumed till date from the payment amount through utilizing the different services in the application.',
        content5:
          'Under the <b>Balance</b> field, view the balance amount left from the payment amount.',
      },
      viewNotifications: {
        title: 'View Notifications',
        content1:
          'Select the <b>Notifications</b> option/icon on the <b>Global Sliding Menu</b>. The application opens the <b>Notifications</b> page.',
        content2:
          'View warnings at the top, followed by all notifications related to jobs, projects, payment and balance, users, roles, teams, etc.',
        content3:
          "Click on the <b>Settings</b> icon (<img class='help-img' src={{value}}>) at the top-right corner to manage notification-related settings as per business requirements.",
      },
      //setup roles,users and teams for employees
      setuprolesusersandteamsforemployees: {
        title: 'Setup Roles,users and Teams for Employees',
      },
      createroles: {
        title: 'Create Roles',
        content1:
          'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
        content2:
          'Click on the <b>New Role</b> button in the top-right corner of the <b>Roles</b> page. The application redirects to the <b>Add New Role</b> window.',
        content3: 'Give a name to the role in the <b>Role Name</b> field.',
        content4:
          'Select the permissions of the role from the list of all permissions in the <b>Show All</b> section of the <b>Available Permissions</b> field.',
        content4A:
          'Select the required permissions for that role from the <b>domain-vice permissions</b> sections in the <b>Available Permissions</b> field.',
        content4B:
          'Select the required permissions for that role by searching for them in the <b>Search Bar</b> of the <b>Available Permissions</b> field.',
        content5:
          'View the selected permissions in the <b>Permissions Selected</b> field for confirmation.',
        content6:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new role is successfully created, or click <b>Cancel</b> to terminate this action.',
        contentOR: 'OR',
      },
      managerole: {
        title: 'Manage Roles',
        viewRolesData: {
          title: 'View Roles Data',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>.',
          content2:
            'The application redirects to the <b>Roles</b> page in the <b>Manage</b> section. View all roles-related data on this page. There is the <b>Role</b> name, the <b>Account</b> to which the role belongs, and the <b>Permissions</b> allotted to the role.',
        },
        editExistingRole: {
          title: 'Edit an Existing Role ',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            "The page displays the list of all roles available. Click on the edit icon (<img class='help-img' src={{value}}>) against the role to be edited.",
          content3:
            'Application redirects to the <b>Edit Role</b> window. Edit the name of the role in the <b>Role Name</b> field.',
          content4:
            'Edit the required permissions for that role by selecting/deselecting permissions from the list of all permissions in the <b>Show All</b> section of the <b>Available Permissions</b> field.',
          content4A:
            'Edit the required permissions for that role by selecting/deselecting permissions from the domain-vice permissions sections of the Available Permissions field.',
          content4B:
            'Edit the required permissions for that role by selecting/deselecting permissions by searching for them in the <b>Search Bar</b> of the <b>Available Permissions</b> field.',
          content5:
            'View the selected permissions in the <b>Permissions Selected</b> field for confirmation.',
          content6:
            'Once done, click on the <b>Save</b> button at the top-right corner to save the new changes made to the role, or click <b>Cancel</b> to terminate this action.',
        },
        deleteRole: {
          title: 'Delete Role',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            "The page displays the list of all roles available. Click on the delete icon (<img class='help-img' src={{value}}>) against the role to be deleted.",
          content3:
            'Application displays the <b>Delete Role</b> window to confirm the delete action. Click on the <b>Delete</b> button to permanently delete the role from the application, or click <b>Cancel</b> to terminate this action.',
        },
        searchRoles: {
          title: 'Search for Roles',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter a <b>Role Name</b> or <b>Permission</b> of the role.',
          content3:
            'Press <b>Enter</b> and the related roles are displayed based on the search results.',
        },
      },
      CreateUsers: {
        title: 'Create Users',
        content1:
          'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
        content2:
          'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
        content3:
          'Click on the <b>New User</b> button in the top-right corner of the <b>Users</b> page. The application redirects to the <b>Add New User</b> window.',
        content4:
          'Fill in the fields under the <b>General Details</b> section:',
        content4a: 'Give a name to the user in the <b>Username</b> field.',
        content4b:
          'Enter the user’s email address in the <b>Email Id</b> field.',
        content4c:
          'Enter the user’s first name in the <b>First Name</b> field.',
        content4d: 'Enter the user’s last name in the <b>Last Name</b> field.',
        content4e:
          'Provide the user with access to use the application by enabling the <b>Active</b> toggle button. <b>Disable</b> if access is not required.',
        content5:
          'Select one/multiple roles to be assigned to the user in the <b>Roles</b> field.',
        content6:
          'Select one/multiple teams that the user has to be added to in the <b>Teams</b> field.',
        content7:
          'Select one/multiple projects to which the user must be assigned in the <b>Projects</b> field.',
        content8:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new user is successfully created, or click <b>Cancel</b> to terminate this action.',
      },
      createUsers: {
        title: 'Create Users',
        content1:
          'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section',
        content2:
          'Click on the <b>Users option</b> at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
        content3:
          'Click on the <b>New User</b> button in the top-right corner of the <b>Users</b> page. The application redirects to the </b>Add New User</b> window',
        content4:
          'Fill in the fields under the <b>General Details</b> section:',
        content4A: 'Give a name to the user in the <b>Username</b> field.',
        content4B:
          'Enter the user’s email address in the <b>Email Id</b> field',
        content4C: 'Enter the user’s first name in the <b>First Name</b> field',
        content4D: 'Enter the user’s last name in the <b>Last Name</b> field.',
        content4E:
          'Provide the user with access to use the application by enabling the <b>Active</b> toggle button. Disable if access is not required.',
        content5:
          'Select one/multiple roles to be assigned to the user in the <b>Roles</b> field.',
        content6:
          'Select one/multiple teams that the user has to be added to in the <b>Teams</b> field.',
        content7:
          'Select one/multiple projects to which the user must be assigned in the <b>Projects</b> field',
        content8:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new role is successfully created, or click <b>Cancel</b> to terminate this action',
      },
      manageUsers: {
        title: 'Manage Users',
        viewUsersData: {
          title: 'View Users Data',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            'View all users related data on this page. There is the <b>Username</b> of the user account, the <b>First Name</b> and <b>Email</b> of the user, the <b>Account</b> to which the user belongs, the <b>Roles</b>, <b>Teams</b>, and <b>Projects</b> assigned to the user, and the <b>Availability</b> of the user.',
        },
        filterUsersData: {
          title: 'Filter Users Data',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage section.</b>',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            'Choose one/multiple/all of the <b>filter criteria</b> available at the top of the page.',
          content3a:
            'Click on the <b>Role</b> filter dropdown and select a role to filter the users’ data based on the selected <b>Roles</b> criteria.',
          content3b:
            'Click on the <b>Team</b> filter dropdown and select a team to filter the users’ data based on the selected <b>Teams</b> criteria.',
          content3c:
            'Click on the <b>Status</b> filter dropdown and select the <b>Online</b> option or <b>Offline</b> option to filter the users’ data based on the selected <b>User’s Status</b> criteria.',
          content3d:
            'Click on the <b>Availability</b> filter dropdown and select the <b>Available</b> or <b>Occupied</b> option to filter the users’ data based on the selected <b>User’s Availability</b> criteria.',
          content4:
            'Click on the <b>Apply</b> button  after choosing the required filter criteria. The application displays the resulting users’ data based on the combination of the selected criteria.',
        },
        editExistingUser: {
          title: 'Edit an Existing User',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            "The page displays the list of all users available. Click on the edit icon (<img class='help-img' src={{value}}>) against the user to be edited.",
          content4:
            'Application redirects to the <b>Update User</b> window. Edit user-related information in the fields under the <b>General Details</b> section:',
          content4a:
            'Edit the user’s email address in the <b>Email Id</b> field.',
          content4b:
            'Edit the user’s first name in the <b>First Name</b> field.',
          content4c: 'Edit the user’s last name in the <b>Last Name</b> field.',
          content4d:
            'Edit the user’s <b>Active</b> access to use the application by enabling/disabling the toggle button.',
          content5:
            'Edit the roles assigned to the user in the <b>Roles</b> field.',
          content6:
            'Edit the teams the user is added to in the <b>Teams</b> field.',
          content7:
            'Edit the projects assigned to the user in the <b>Projects</b> field.',
          content8:
            'Once done, click on the <b>Save</b> button at the top-right corner to save the new changes made to the user, or click <b>Cancel</b> to terminate this action.',
        },
        deleteUser: {
          title: 'Delete User',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the Users option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            "The page displays the list of all users available. Click on the <b>delete</b> icon (<img class='help-img' src={{value}}>) against the user to be deleted.",
          content4:
            'Application displays the <b>Delete User</b> window to confirm the delete action. Click on the <b>Delete</b> button to permanently delete the user from the application, or click <b>Cancel</b> to terminate this action.',
        },
        enableDisableUser: {
          title: 'Enable/Disable a User',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            'The page displays all users related data in different columns. Go to the <b>Active</b> column at the right end of the user’s data.',
          content4:
            'Enable a user with access to use the application by enabling the <b>Active</b> toggle button against that user. Else, turn off the <b>Active</b> toggle button to disable access to the user.',
        },
        SearchForUsers: {
          title: 'Search for Users',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter the <b>Username</b> or <b>Email Id</b> of the user.',
          content4:
            'Press <b>Enter</b> and the related users are displayed based on the search results.',
        },
        addNewColumnRemoveExistingColumn: {
          title:
            'Add New Columns of User Data or Remove Existing Columns of User Data',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Users</b> option at the top. The application redirects to the <b>Users</b> page in the <b>Manage</b> section.',
          content3:
            "The page displays all users related data in different columns. Click on the horizontal ellipsis button (<img class='help-img' src={{value}}>) at the right end of the column headings row.",
          content4:
            'A small window appears with the list of all column headings available. Uncheck any of the existing columns that are not required for display on the <b>Users</b> page.',
          content5:
            'Also, check any new columns of data that has to be displayed on the <b>Users</b> page.',
          content6:
            'Once done, click anywhere on the screen and the window disappears.',
        },
      },
      createTeams: {
        title: 'Create Teams',
        content1:
          'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
        content2:
          'Click on the <b>Teams</b> option at the top. The application redirects to the <b>Teams</b> page in the <b>Manage</b> section.',
        content3:
          'Click on the <b>New Team</b> button in the top-right corner of the <b>Teams</b> page. The application redirects to the <b>Add New Team</b> window.',
        content4: 'Give a name to the team in the <b>Team Name</b> field.',
        content5a:
          'Select the users to be added to the team from the alphabetical order of the list of users in the <b>Available Resources</b> section.',
        content5b:
          'Select the users to be added to the team by searching for them in the <b>Search Bar</b> of the <b>Available Resources</b> section.',
        content6:
          'View the list of members added to the team in the <b>Selected Team Members</b> field for confirmation.',
        content7:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new team is successfully created, or click <b>Cancel</b> to terminate this action',
      },
      manageTeams: {
        title: 'Manage Teams',
        viewTeamsData: {
          title: 'View Teams Data',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2: 'Click on the <b>Teams</b> option at the top.',
          content3:
            'Application redirects to the <b>Teams</b> page in the <b>Manage</b> section. View all the teams-related data on this page. There is the <b>Team</b> name, the <b>Strength</b> of the team, the <b>Account</b> to which the team belongs, and the <b>Team Members</b>.',
        },
        editExistingTeam: {
          title: 'Edit an Existing Team',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Teams</b> option at the top. The application redirects to the <b>Teams</b> page in the <b>Manage</b> section.',
          content3:
            "The page displays the list of all teams available. Click on the edit icon (<img class='help-img' src={{value}}>) against the team to be edited.",
          content4:
            'Application redirects to the <b>Edit Team</b> window. Edit the name of the team in the <b>Team Name</b> field.',
          content5a:
            'Edit the team members by selecting/deselecting members from the alphabetical order of the list of users in the <b>Available Resources</b> section.',
          content5b:
            'Edit the team members by selecting/deselecting members by searching for them in the <b>Search Bar</b> of the <b>Available Resources</b> section.',
          content6:
            'View the list of members added to the team in the <b>Selected Team Members</b> field for confirmation.',
          content7:
            'Once done, click on the <b>Save</b> button at the top-right corner to save the new changes made to the team, or click <b>Cancel</b> to terminate this action.',
        },
        deleteTeam: {
          title: 'Delete Team',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Teams</b> option at the top. The application redirects to the <b>Teams</b> page in the <b>Manage</b> section.',
          content3:
            "The page displays the list of all teams available. Click on the <b>delete</b> icon (<img class='help-img' src={{value}}>) against the team to be deleted.",
          content4:
            'Application displays the <b>Delete Team</b> window to confirm the delete action. Click on the <b>Delete</b> button to permanently delete the team from the application, or click <b>Cancel</b> to terminate this action.',
        },
        enableDisableTeam: {
          title: 'Enable/Disable a Team',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Teams</b> option at the top. The application redirects to the <b>Teams</b> page in the <b>Manage</b> section.',
          content3:
            'The page displays all teams-related data in different columns. Go to the <b>Active</b> column at the right end of the teams data.',
          content4:
            'Enable a team with access to use the application by enabling the <b>Active</b> toggle button against that team. Else, turn off the <b>Active</b> toggle button to disable access.',
        },
        searchForTeams: {
          title: 'Search for Teams',
          content1:
            'Select the <b>Manage</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Roles</b> page in the <b>Manage</b> section.',
          content2:
            'Click on the <b>Teams</b> option at the top. The application redirects to the <b>Teams</b> page in the <b>Manage</b> section.',
          content3:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter the <b>Team Name</b> or a <b>Team Member</b> name.',
          content4:
            'Press <b>Enter</b> and the related teams data is displayed based on the search results.',
        },
      },
      //Manage Presets
      ManagePresets: {
        title: 'Manage Presets',
      },
      makeACopyOfAnAvailableGlobalPreset: {
        title: 'Make a copy of an available Global Preset',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2: 'Click on the <b>Presets</b> option at the top.',
        content3:
          'Application redirects to the <b>Presets</b> page in the <b>Projects</b> section. The first part of the page displays <b>Global Preset Templates</b>.',
        content4:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the <b>Global Preset Template</b> that has to be duplicated.",
        content5:
          'Select the <b>Duplicate Preset</b> option and a copy of the template is created under <b>Customized Presets</b>, which are displayed in the second part of the page.',
      },
      createACustomizedPreset: {
        title: 'Create a Customized Preset',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2: 'Click on the <b>Presets</b> option at the top.',
        content3:
          'Application redirects to the <b>Presets</b> page in the <b>Projects</b> section. The second part of the page displays <b>Customized Presets</b>.',
        content4:
          'Click on the <b>Create New Preset</b> button at the beginning. The application redirects to the <b>Create New Custom Preset</b> window.',
        content5: 'Fill in the fields under the <b>General Rules</b> section:',
        content5a: 'Give a name to the preset in the <b>Preset Name</b> field.',
        content5b:
          'In the <b>Minimum Duration</b> field, enter the minimum number of seconds each caption frame should last in the jobs (media files) to which this preset is applied.',
        content5c:
          'In the <b>Maximum Duration</b> field, enter the maximum number of seconds each caption frame should last in the jobs to which this preset is applied.',
        content5d:
          'In the <b>Maximum Words/Min</b> field, set the reading speed of the captions by entering the maximum number of words per minute that can be displayed through the caption frames.',
        content5e:
          'In the <b>Single Line Maximum Length</b> field, enter the maximum number of characters that can be displayed in each line of the captions.',
        content5f:
          'In the <b>Minimum Gap Between Subtitles</b> field, set a time gap for captions (for better readability) by entering the minimum milliseconds of gap to be present between 2 caption frames.',
        content5g:
          'In the <b>Maximum Number of Lines</b> field, enter the maximum number of lines of captions that are allowed per caption frame.',
        content5h:
          'In the <b>Character Per Second</b> field, enter the maximum number of characters to be displayed per second in caption frames.',
        content6:
          'In the <b>Languages</b> field, select one/multiple languages w.r.t the audio input of all the jobs that require this preset template.',
        content7:
          'Fill in the fields under <b>Caption Split Rules</b> section:',
        content7a:
          'Enable the <b>Proper Noun</b> toggle button to enable caption split rules on proper nouns in the captions. On enabling, the application ensures that captions never split (due to the <b>maximum character per caption line</b> limit) between a proper noun.',
        content7b:
          'Enable the <b>Prepositions / Conjunctions / Punctuations</b> toggle button to enable caption split rules on those respective parts of speech. On enabling, the application ensures that captions are not split (due to the <b>maximum character per caption line limit</b>) between prepositions/conjunctions/ punctuations.',
        content7c:
          'Enable the <b>Retain Speakers Tags from Transcription</b> toggle button to allow users to retain all speaker tags from transcription level to captioning level.',
        content8:
          'Fill in the fields under <b>Caption Split Rules Based on Music / Silence</b> section:',
        content8a:
          'Enable the toggle button to apply caption split rules w.r.t music/silence for the jobs (media files) where this preset is used. On enabling, the application ensures that captions are not split between music or silence indication in the captions.',
        content8b:
          'In the <b>Minimum Duration of Music/Silence</b> field, enter the minimum amount of time a music indication or silence indication should be displayed in the captions.',
        content8c:
          'In the <b>Maximum Duration of Music/Silence</b> field, enter the maximum amount of time a music indication or silence indication should be displayed in the captions.',
        content9:
          'Fill in the fields under <b>Caption Split Rules Based</b> on Shot Change section:',
        content9a:
          'Enable the toggle button to apply caption split rules w.r.t shot change for the jobs (media files) where this preset is used. On enabling, the application ensures that captions are always split into the next caption frame when there is a shot change.',
        content9b:
          'In the <b>Adjustable Duration Before Splitting</b> field, enter the number of frames that can be adjusted to avoid captions to cross a shot change in a scene.',
        content10:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new customized preset is successfully created, or click <b>Cancel</b> to terminate this action.',
      },
      makeACopyOfAnExistingCustomizedPreset: {
        title: 'Make a copy of an Existing Customized Preset',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2: 'Click on the <b>Presets</b> option at the top.',
        content3:
          'Application redirects to the <b>Presets</b> page in the <b>Projects</b> section. The second part of the page displays <b>Customized Presets</b>.',
        content4:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the <b>Customized Preset</b> that has to be duplicated.",
        content5:
          'Select the <b>Duplicate Preset</b> option and a copy of the template is created under the same <b>Customized Presets</b> section.',
      },
      editAnExistingCustomizedPreset: {
        title: 'Edit an Existing Customized Preset',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2: 'Click on the <b>Presets</b> option at the top.',
        content3:
          'Application redirects to the <b>Presets</b> page in the <b>Projects</b> section. The second part of the page displays <b>Customized Presets</b>.',
        content4:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the preset to be edited and select <b>Edit Preset</b> option. The application redirects to the <b>Update Custom Preset</b> window.",
        content5: 'Edit the fields under the <b>General Rules</b> section:',
        content5a:
          'Edit the name of the preset in the <b>Preset Name</b> field.',
        content5b:
          'In the <b>Minimum Duration</b> field, edit the minimum number of seconds each caption frame should last in the jobs (media files) to which this preset is applied.',
        content5c:
          'In the <b>Maximum Duration</b> field, edit the maximum number of seconds each caption frame should last in the jobs to which this preset is applied.',
        content5d:
          'In the <b>Maximum Words/Min</b> field, set the reading speed of the captions by editing the maximum number of words per minute that can be displayed through the caption frames.',
        content5e:
          'In the <b>Single Line Maximum Length</b> field, edit the maximum number of characters that can be displayed in each line of the captions.',
        content5f:
          'In the <b>Minimum Gap Between Subtitles</b> field, set a time gap for captions (for better readability) by editing the minimum milliseconds of gap to be present between 2 caption frames.',
        content5g:
          'In the <b>Maximum Number of Lines</b> field, edit the maximum number of lines of captions that are allowed per caption frame.',
        content5h:
          'In the <b>Character Per Second</b> field, edit the maximum number of characters to be displayed per second in caption frames.',
        content6:
          'In the <b>Languages</b> field, select/deselect one/multiple languages w.r.t the audio input of all the jobs that require this preset template.',
        content7:
          'Fill in the fields under <b>Caption Split Rules</b> section:',
        content7a:
          'Enable/disable the toggle button to apply/not apply caption split rules for the jobs (media files) where this preset is used.',
        content7b:
          'Enable/disable the <b>Prepositions / Conjunctions / Punctuations</b> toggle button to enable/disable caption split rules on those respective parts of speech. On enabling, the application ensures that captions are not split (due to the <b>maximum character per caption line</b> limit) between prepositions/conjunctions/ punctuations.',
        content7c:
          'Enable/disable the <b>Retain Speakers Tags from Transcription</b> toggle button to allow/disallow users to retain all speaker tags from transcription level to captioning level.',
        content8:
          'Fill in the fields under <b>Caption Split Rules Based on Music / Silence</b> section:',
        content8a:
          'Enable/disable the toggle button to apply/not apply caption split rules w.r.t music/silence for the jobs (media files) where this preset is used. On enabling, the application ensures that captions are not split between music or silence indication in the captions.',
        content8b:
          'In the <b>Minimum Duration of Music/Silence</b> field, edit the minimum amount of time a music indication or silence indication should be displayed in the captions.',
        content8c:
          'In the <b>Maximum Duration of Music/Silence</b> field, edit the maximum amount of time a music indication or silence indication should be displayed in the captions.',
        content9:
          'Fill in the fields under <b>Caption Split Rules Based on Shot Change</b> section:',
        content9a:
          'Enable/disable the toggle button to apply/not apply caption split rules w.r.t shot change for the jobs (media files) where this preset is used. On enabling, the application ensures that captions are always split into the next caption frame when there is a shot change.',
        content9b:
          'In the <b>Adjustable Duration Before Splitting</b> field, edit the number of frames that can be adjusted to avoid captions to cross a shot change in a scene.',
        content10:
          'Once done, click on the <b>Save</b> button at the top-right corner and the new customized preset is successfully created, or click <b>Cancel</b> to terminate this action.',
      },
      deleteAnExistingCustomizedPreset: {
        title: 'Delete an Existing Customized Preset',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2: 'Click on the <b>Presets</b> option at the top.',
        content3:
          'Application redirects to the <b>Presets</b> page in the <b>Projects</b> section. The second part of the page displays <b>Customized Presets</b>.',
        content4:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the <b>Customized Preset</b> that has to be deleted.",
        content5: 'Select the <b>Delete Preset</b> option.',
        content6:
          'Application displays the <b>Delete Preset</b> window to confirm the delete action. Click on the <b>Delete</b> button to permanently delete the preset template from the application.',
      },
      //Create Projects to Customize Job Requirements
      CreateProjectstoCustomizeJobRequirements: {
        title: 'Create Projects to Customize Job Requirements',
      },
      createANewProject: {
        title: 'Create a New Project',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2:
          'Click on the <b>Create New Project</b> option in the top-left corner of the <b>Projects</b> page. The application redirects to page 1 of the <b>Create Project</b> window.',
        contentPage1:
          '<b>Setup Project Basic Details and Required Services</b>',
        content3:
          'Fill in the details in all the fields on this <b>Project Details</b> and <b>Select Services</b> page:',
        content3a:
          'Give a name to the project in the <b>Project Name</b> field.',
        content3b:
          'In the <b>Domain</b> field, select the domain of the jobs that will be uploaded under this project.',
        content4:'In the <b>Change Time Zone</b>, select the time zone to be followed while processing the various jobs that will be added under this project. This is an optional field.',
        content5:
          "In the <b>Select Services</b> field, select the TranceIQ application services required for the jobs uploaded under this project. Please refer to <a class='help-trance'>TranceIQ FAQs</a> to know about the different services available in TranceIQ.",
        content6:
          'In the <b>Total Cost</b> field, view the total service charges that will be applied for jobs under this project for confirmation.',
        content7:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Setup Workflow</b> page.',
        contentPage2: '<b>Configure Project Workflow Levels</b>',
        content8:
          "Review and, if necessary, edit the services selected on the previous page. Click on the <b>add</b> icon (<img class='help-img' src={{value}}>) against any service to add it to the list of services required for the jobs that will be uploaded under this project. Or click on the <b>remove</b> icon",
        content8img2:
          "(<img class='help-img' src={{value}}>) to remove the service.",
        content9:
          "In the Transcription and Captioning fields, click on the <b>add</b> icon (<img class='help-img' src={{value}}>) against the additional process levels (<b>L2</b> and <b>L3</b>), if more accuracy and perfection in those respective services are required.",
        content10:
          "Enter custom titles to the services (and the <b>L2/L3</b> process levels) by clicking on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against the respective field.",
        content11:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>ASR Setup</b> page.',
        contentPage3: '<b>ASR Setup</b>',
        content12:
          'In the <b>Mode</b> field, select the mode of the media files (jobs) that will be uploaded under this project.',
        content13:
          'In the <b>Content Type</b> field, select the genre to which the content of the jobs uploaded under this project will belong.',
        content14:
          'In the <b>Languages</b> field, select the audio language of the media files (jobs) that will be uploaded under this project.',
        content15:
          'In the <b>Country</b> field, select the country (or accent) to which the selected language belongs to.',
        content16:
          'Click on the <b>Add Language</b> button if jobs of multiple languages will be uploaded under this project. Upon adding, repeat steps 13 and 14.',
        content17:
          'Repeat step 15 to add as many languages as per business requirements.',
        content18:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Translation Languages</b> page.',
        contentPage4: '<b>Translation Languages</b>',
        content19:
          'Select the different languages required while utilizing the application’s <b>translation</b> service for media files (jobs) that will be uploaded under this project.',
        content20:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Set Default Preset</b> page.',
        contentpage5: '<b>Set Default Preset</b>',
        content21:
          'Choose a preset from the list of Global Preset Templates and Customized Presets to set as the default preset for media files (jobs) that will be uploaded under this project.',
        content22:
          'Click on the vertical ellipsis button against any preset for options to manage the respective preset if required. <b>Duplicate Preset</b> option is available for Global Presets and <b>Edit Preset/Delete Preset/Duplicate Preset</b> are the different options available for Customized Presets. Refer to ',
        content22link: ' Make a copy of an Existing Customized Preset',
        content22b: ' to know further about these options.',
        content23:
          'Create a new customized preset and then set it as the default preset if required. Click on the <b>Create New Preset</b> button under the <b>Customized Presets</b> section for preset creation. Refer to ',
        content23link: ' Manage Presets',
        content23b: ' to know further about this preset creation.',
        content24:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Select Output Requirements</b> page.',
        contentPage6: '<b>Select Output Requirements</b>',
        content25:
          'In the <b>Final Output</b> field, choose the TranceIQ application services whose file output is required for the media files (jobs) that will be uploaded under this project. Only services selected in the <b>Project Details</b> and <b>Setup Workflow</b> pages are available to choose from.',
        content26:
          'In the <b>Output Type</b> field, choose the video type(s) in which the output has to be downloaded.',
        content27:
          'In the <b>Output Video Formats</b> field, choose the format(s) in which the output video files have to be downloaded.',
        content28:
          'In the <b>Caption Output Formats</b> field, choose the format(s) in which the caption files have to be downloaded. This field is available for selection only if the <b>Caption</b> option is chosen for download in the above <b>Final Output</b> field.',
        content29:
          'In the <b>Transcription Output Formats</b> field, choose the format(s) in which the transcript output file of the <b>Transcription</b> service has to be downloaded. This field is available for selection only if the <b>Transcription</b> option is chosen for download in the above <b>Final Output</b> field.',
        content30:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Turn Around Time (TAT)</b>  page.',
        contentPage7: '<b>Turn Around Time (TAT)</b>',
        content31:
          "Choose one or multiple <b>TAT</b> values between minutes or hours according to business requirements. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more about <b>TAT</b> if required.",
        content32:
          'Turn off the toggle button at the top-right corner to entirely disable this feature and skip the <b>TAT</b> selection if required.',
        content33:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Hierarchy</b> page.',
        contentPage8: '<b>Hierarchy</b>',
        content34:
          "Click on the <b>Add New Node</b> icon (<img class='help-img' src={{value}}>) under the PMA multiple times to add all the different roles for this project that are to be designated with Level-2 authority (The PMA is the Level-1 authority).",
        content35:
          "Click on the <b>Add New Node</b> icon(s) (<img  class='help-img' src={{value}}>) present under the Level-2 authority roles to add all the different roles for this project that are to be designated with Level-3 authority. Add the new nodes in accordance with which roles of level-2 require a third level of authority(or authorities) under them for doing the respective tasks in the application.",
        content36:
          "Click on the <b>Add New Node</b> icon(s) (<img class='help-img' src={{value}}>) present under the Level-3 authority roles to add all the different roles for this project that are to be designated with Level-4 authority. Add the new nodes in accordance with which roles of level-3 require a fourth level of authority(ies) under them for doing the respective tasks in the application. Refer to <a class='help-trance' >TranceIQ User Manual</a> to know more about hierarchies if required.",
        content37:
          "Click on the <b>List Hierarchy</b> icon (<img class='help-img' src={{value}}>) at the top-right corner to add and review nodes in a list format (instead of the tree format) if required.",
        content38:
          'Once done, click on the <b>Next</b> button at the bottom-right corner:',
        content38a:
          'If the role(s) added in the hierarchy are new role names that are not part of the list of roles available in the application for the particular account, then the page redirects to the <b>Select Permissions for the Hierarchy Roles</b> page. Select the permissions for the one/multiple new roles added in the hierarchy. Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Operating Method</b> page.',
        content38b:
          'If all the role(s) added in the hierarchy are existing roles, then the page directly goes to the next page in the <b>Create Project</b> window. The page redirects to the <b>Operating Method</b> page.',
        contentPage9: '<b>Operating Method</b>',
        content39:
          "Read the descriptions and select either of the One Job <b>One Employee</b> and <b>One Job Many Employees</b> operating methods available. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more about operating methods if required.",
        content40:
          "At the bottom of the page, read the description and enable the <b>Split Job into Parts</b> checkbox if required. Refer to <a class='help-trance' >TranceIQ User Manual</a> to know more about the use of splitting jobs into parts.",
        content41:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Job Access to Employees</b>  page.',
        contentPage10: '<b>Job Access to Employees</b>',
        content42:
          "Read the descriptions and select either of the <b>Auto Assign Jobs</b> and <b>Employees Can Choose Jobs</b> methods available, to choose who should be given access to process jobs that will be uploaded under this project. Refer to <a class='help-trance' >TranceIQ User Manual</a> to know more about job access to employees if required.",
        content43:
          'Once done, click on the Next button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Performance Levels</b>  page.',
        contentPage11: '<b>Performance Levels</b>',
        content44:
          "Click on the <b>Edit</b> icon (<img class='help-img' src={{value}}>) against the <b>Performance Level Tags</b> to enter customized names to the performance levels, which later can be used to rank users. The order of ranking from highest to lowest is P1, P2, P3, P4, and P5, and hence enter the customized names accordingly. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more about performance levels if required.",
        content45:
          'Turn on/off the toggle button(s) against the performance levels that have to be enabled or disabled respectively.',
        content46:
          'Turn off the toggle button at the top-right corner to entirely disable this feature and skip adding performance levels if required.',
        content47:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Other Settings</b> page.',
        contentPage12: '<b>Other Settings</b>',
        content48:
          'In the <b>Auto Delete Video Files After</b> field, choose the number of days the uploaded video files for this project whose output is already processed should be stored in the application’s database.',
        content49:
          'In the <b>Auto Delete Output Files After</b> field, choose the number of days the generated output files of this project should be stored in the application’s database.',
        content50:
          'Enable the <b>Never Delete</b> toggle button against the <b>Auto Delete Output Files After</b> field to permanently retain the output files of this project in the application database.',
        content51:
          'Check the <b>Zip File Folders</b> checkbox to download the zip files of the output while downloading the output media files. The downloaded zip files will have the same name as the output files.',
        content52:
          'Once done, click on the <b>Next</b> button at the bottom right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Vocabulary Files</b> page.',
        contentPage13: '<b>Vocabulary Files</b>',
        content53:
          'Click on the <b>Upload</b> button to upload documents from the local machine with a compilation of project-specific words/terminology that are probably not a part of the general English dictionary but are still valid words for this particular project domain.',
        content54:
          "Manually enter the project-specific vocabulary in the text box at the center, if required. Refer to <a class='help-trance' >TranceIQ User Manual</a> to know more about vocabulary files.",
        content55:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Style Guide</b> page.',
        contentPage14: '<b>Style Guide</b>',
        content56:
          'Click on the <b>Upload</b> button to upload documents from the local machine with instructions/guidelines related to styling, formatting, and arrangement of captions to refer to while processing jobs that will be uploaded under this project.',
        content57:
          "Manually enter the project-specific instructions/guidelines for caption styling in the text box at the center, if required. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more about style guides.",
        content58:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Guidelines</b> page.',
        contentPage15: '<b>Guidelines</b>',
        content59:
          'Click on the <b>Upload</b> button to upload documents from the local machine with instructions/guidelines to refer to while processing jobs that will be uploaded under this project.',
        content60:
          "Manually enter the project-specific instructions/guidelines in the text box at the center, if required. Refer to <a class='help-trance' >TranceIQ User Manual</a> to know more about guidelines.",
        content61:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Email Notification Setup</b> page.',
        contentPage16: '<b>Email Notification Setup</b>',
        content62:
          'Choose and enable the toggle button(s) against the available job-related actions to send notifications to Digital Nirvana Support, PMA, and the user account who uploaded the respective job, as per the business requirements of the project.',
        content63:
          'Enter additional email ids if required, to add other users to whom job-related actions` notifications related to this project have to be sent.',
        content64:
          'Once done, click on the <b>Next</b> button at the bottom-right corner to go to the next page in the <b>Create Project</b> window. The page redirects to the <b>Project Access</b> page.',
        contentPage17: '<b>Project Access</b>',
        content65:
          'From the <b>Available Users and Teams</b> section, select the users and teams in the account to whom access to this project should be given.',
        content66:
          'View the selected members in the <b>Selected Users and Teams</b> section for confirmation.',
        content67:
          'If required, enable the toggle button at the top to give access to this project to all the users and teams in the account.',
        content68:
          'Once done, click on the <b>Save</b> button at the top to complete the project creation procedure and successfully create the project.',
      },
      //Manage Projects
      ManageProjects: {
        title: 'Manage Projects',
      },
      editAnExistingProject: {
        title: 'Edit an Existing Project',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the project to be edited and select the <b>Edit Project</b> option..",
          content3:'A flash window appears where the user can choose which page of the <b>Edit Project</b> window (between pages 1-17) to navigate to for making changes. Choose the required page number from the list.',
          content4:'The application redirects to the respective page of the <b>Edit Project</b> window. Make the necessary changes as required. Please refer to ',
          content4link:'Create a New Project',
          content4b:' to understand the different fields present on all 17 pages of the <b>Edit Project</b> window.',
          content5:'Once done, navigate to the final 17th page of the <b>Edit Project</b> window and click on the <b>Save</b> button at the top right corner to successfully save the changes made in the respective project.'
      },
      deleteAnExistingProject: {
        title: 'Delete an Existing Project',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the project to be deleted and select the <b>Delete Project</b> option.",
        content3:
          'Application displays the <b>Delete Project</b> window to confirm the delete action. Click on the <b>Delete</b> button to permanently delete the project from the application.',
      },
      duplicateAnExistingProject: {
        title: 'Duplicate an Existing Project',
        content1:
          'Select the <b>Projects</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Projects</b> page in the <b>Projects</b> section.',
        content2:
          "Click on the vertical ellipsis button (<img class='help-img' src={{value}}>) against the project to be duplicated and select the <b>Duplicate Project</b> option.",
        content3:
          'View the duplicated project in the list of projects displayed on the <b>Projects</b> page.',
      },
      //Upload Jobs and Check Job Statuses
      UploadJobsandCheckJobStatus: {
        title: 'Upload Jobs and Check Job Status',
      },
      addnewjob: {
        title: 'Add New Job(Upload New Media File)',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'Click on the <b>New Job</b> button at the top right corner of the page. The application redirects to the <b>Choose Project & Language</b> window.',
        content3:
          'Under <b>Projects</b>, select the project under which the job (media file) is to be uploaded.',
        content4:
          'Under <b>Languages</b>, choose the audio language of the media file to be uploaded.',
        content5:
          'Once done, click on the <b>Proceed</b> button. The page redirects to the <b>Upload Files</b> window.',
        content6:
          'The <b>Language</b> dropdown menu at the top by default shows the audio language of the media file that is chosen in the previous window. Click on the dropdown menu and choose a different language if required.',
        content7:
          'The <b>Preset</b> dropdown menu at the top by default shows the default preset of the project under which this job is uploaded. Click on the dropdown menu and choose a different preset template if required.',
        content8:
          'Under the <b>TAT</b> field at the top, click on the dropdown menu and choose the required TAT value for the uploading job. Select the option <b>None</b> to disable the TAT feature for the uploading job.',
        content9:
          'Under <b>Auto Assign</b>, choose the user to whom the job has to be assigned. Please note that this dropdown menu is available only if <b>Employee Can Choose</b> method is selected in <b>Job Access Methods</b> page duing Project Creation.',
        content10:
          'Under the <b>Priority</b> field at the top, click on the dropdown menu and select the level of priority to be given for this job, which is helpful during job assignments for users. Select <b>Nil</b> if the job doesn’t require any distinct priority.',
        content11:
          'Click on the <b>Drag and Drop Files to Upload</b> box present at the center of the window to upload the media file(s).',
        content12:
          'Select the file from the local machine and click on <b>Open</b> to start the uploading procedure.',
        content13:
          'Repeat steps 10 and 11 any number of times to upload multiple media files as per business requirement, provided all those files come under the same project, have the same audio language, and require the same preset template, TAT value, and priority level.',
        content14:
          'After selecting all the required media files from the local machine, view the status of the uploading files on the <b>Files Uploaded</b> section to the right.',
        content14a:
          'Wait for the <b>Status</b> to change from <b>Uploading</b> to <b>Uploaded</b>, and view the <b>Job Id</b> of the file(s)',
        content14b:
          'Wait for the <b>Status</b> to change from <b>Uploading</b> to <b>ASR Completed</b>, and click on the highlighted <b>Play</b> icon to open the job in Transcription/Captioning editor windows',
        content14c:
          'Or simply navigate back in the application irrespective of the status. The newly uploaded file(s) and their job status can be viewed on the <b>Files</b> page',
      },
      viewJobInfoAndCheckJobStatus: {
        title: 'View Job Info and Check Job Status',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'On this page, view all jobs-related data of every uploaded media file. There is the <b>File Name</b>, the <b>Job Id</b>, the date and time at which it is <b>Uploaded</b>, the date and time at which it is <b>Last Modified</b> by any user, the <b>Duration</b> of the media file, the <b>Project</b> under which the job is uploaded, the <b>Status</b> of the job, and the name of the <b>Uploaded By</b> user.',
        content3:
          "Regularly check the <b>Status</b> column for constant updates about the present stage of processing that the job is in. There are 7 different stages — Upload, ASR in Progress, Not Started, Transcription, Captioning, Translation, and Completed. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more about job statuses.",
      },
      ManageJobsData: {
        title: 'Manage Jobs Data',
        FilterJobs: {
          title: 'Filter Jobs',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Choose one/multiple/all of the <b>filter criteria</b> available at the top of the page.',
          content2a:
            'Click on the <b>calendar</b> icon against the <b>Start Date</b> filter and choose the date of upload of files starting from which the jobs information has to be displayed.',
          content2b:
            'Click on the <b>calendar</b> icon against the <b>End Date</b> filter and choose the date   until which the jobs information has to be displayed.',
          content2c:
            'Click on the <b>Project</b> filter dropdown and select the project whose related jobs have to be displayed.',
          content2d:
            'Click on the <b>Job Status</b> filter dropdown and select the status of the jobs to be displayed.',
          content3:
            'Once done, click on the <b>Apply</b> button. The application then displays the resulting jobs’ data based on the combination of the filter criteria selected.',
        },
        searchJobs: {
          title: 'Search Jobs',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter the <b>File Name</b> or <b>Job Id</b> of the required job.',
          content3:
            'Press <b>Enter</b> and the related jobs’ data is displayed based on the search results.',
        },
        DownloadJobOutput: {
          title: 'Download Job Output',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Check whether or not the job to download has the <b>download</b> icon highlighted. The application highlights the <b>download</b> option for jobs whose status is updated to <b>Completed</b> or to any TranceIQ service that is given permission for output download during the creation of the project under which the job is uploaded.',
          content3:
            'If yes, click on the <b>download</b> icon, and the output of the job is downloaded into the respective local machine.',
        },
        ExportJobsData: {
          title: 'Export Jobs Data',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Export</b> icon at the top of the page and this starts the download procedure of the excel sheet into the local machine.',
          content3:
            'Open the downloaded file to view all jobs and their details in an offline spreadsheet.',
          content4:
            'To get data of any selected job(s) in particular, enter the respective <b>File Name/Job Id</b> on the <b>Search Bar</b> at the top and click on the <b>Apply</b> button. And then follow steps 2 and 3 to export details about selected jobs into the local machine.',
        },
        addnewcolumnsjobsdataremoveexistingcolumnsjobsdata: {
          title:
            'Add New Columns of Jobs Data or Remove Existing Columns of Jobs Data',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            "The page displays all jobs’ related data in different columns. Click on the horizontal ellipsis button (<img class='help-img' src={{value}}>) at the right end of the column headings row.",
          content3:
            'A small window appears with the list of all column headings available. Uncheck any of the existing columns that are not required for display on the <b>Jobs</b> page.',
          content4:
            'Also, check any new columns of data that has to be displayed on the <b>Jobs</b> page.',
          content5:
            'Once done, click anywhere on the screen and the window disappears, and the required columns of data are successfully displayed on the page.',
        },
      },
      OpenJob: {
        title: 'Open Job',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>.',
        content2:
          'The application redirects to the <b>Files</b> page in the <b>Files</b> section. The page displays the list of all jobs in the account.',
        content3:
          'Click on the <b>play</b> icon against the job to open and the page redirects to the Transcription/Captioning editor windows depending on the respective job status.',
        note: 'Please note that the <b>play</b> icon is not highlighted if :',
        note1: ' (i) The job is not accessible to the user',
        note2:
          '(ii) The job status is <b>Uploaded/Created/Not Started/ASR In Progress/Completed/Failed</b> ',
        note3:
          "Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more.",
      },
      //Track Job Progress and View Job Assignments
      TrackJobProgressandViewJobAssignments: {
        title: 'Track Job Progress and View Job Assignments',
      },
      TrackJobsStagewiseProgress: {
        title: 'Track Jobs’ Stage-wise Progress',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2: 'Click on the <b>Progress</b> option at the top.',
        content3:
          'The application redirects to the <b>Progress</b> page in the <b>Files</b> section. On this page, view all jobs and their progress-related data of every uploaded media file. There is the <b>File Name</b>, the <b>Job Id</b>, the <b>Duration</b> of the media file, the date and time at which it is <b>Uploaded</b>, the <b>Project</b> under which the job is uploaded, and followed by the <b>Service and Process Level Statuses</b>.',
        content4:
          'Track the job progress with the help of the color of the horizontal bar against the <b>Service and Process Level Statuses</b>.',
        content4a:
          "<b class='help-green'>Green</b> color indicates that the particular stage is completed",
        content4b:
          "<b class='help-yellow'>Yellow</b> color indicates that the particular stage is in progress",
        content4c:
          "<b class='help-grey'>Grey</b> color indicates that the particular stage is not yet started",
        content4d:
          '<b>NA</b> indicates that the particular stage is not available for that job ',
      },
      ViewJobAssignmentsData: {
        title: 'View Job Assignments Data',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2: 'Click on the <b>Assignments</b> option at the top.',
        content3:
          'The application redirects to the <b>Assignments</b> page in the <b>Files section</b>. On this page, view all job assignments-related data of every uploaded media file. There is the <b>File Name</b>, the <b>Job Id</b>, the <b>Duration</b> of the media file, the date and time at which it is <b>Uploaded</b>, the <b>TAT</b> value of the job, the date and time the job’s output is <b>Expected Out At</b>, the <b>Project</b> under which the job is uploaded, the <b>Status</b> of the jobs, and the name of the <b>Uploaded By</b> user.',
      },
      ManageJobAssignmentsData: {
        title: 'Manage Job Assignments Data',
        StopJob: {
          title: 'Stop Job',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
          content3:
            'Click on the <b>stop</b> icon against the job present at the end.',
          content4:
            'Application displays the <b>Stop this Job?</b> confirmation window. Click on the <b>Yes</b> button and the processing of the job in its current processing stage will be stopped. ',
        },
        FilterJobs: {
          title: 'Filter Jobs',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
          content3:
            'Choose one/multiple/all of the <b>filter criteria</b> available at the top of the page.',
          content3a:
            'Click on the <b>calendar</b> icon against the <b>Start Date</b> filter and choose the date of upload of files starting from which the jobs information has to be displayed.',
          content3b:
            'Click on the <b>calendar</b> icon against the <b>End Date</b> filter and choose the date  until which the jobs information has to be displayed.',
          content3c:
            'Click on the <b>Project</b> filter dropdown and select the project whose related jobs have to be displayed.',
          content3d:
            'Click on the <b>Job Status</b> filter dropdown and select the status of the jobs to be displayed.',
          content4:
            'Once done, click on the <b>Apply</b> button. The application then displays the resulting job assignments’ data based on the combination of the filter criteria selected.',
        },
        SearchJobs: {
          title: 'Search Jobs',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
          content3:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter the <b>File Name</b> or <b>Job Id</b> of the required job.',
          content4:
            'Press <b>Enter</b> and the related jobs’ assignments-related  data is displayed based on the search results.',
        },
        addnewcolumnsofjobassignmentsdata: {
          title:
            'Add New Columns of Assignments Data or Remove Existing Columns of Assignments Data',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
          content3:
            "The page displays all job assignments’ related data in different columns. Click on the horizontal ellipsis button (<img class='help-img' src={{value}}>) at the right end of the column headings row.",
          content4:
            'A small window appears with the list of all column headings available. Uncheck any of the existing columns that are not required for display on the <b>Assignments</b> page.',
          content5:
            'Also, check any new columns of data that has to be displayed on the <b>Assignments</b> page.',
          content6:
            'Once done, click anywhere on the screen and the window disappears, and the required columns of data are successfully displayed on the page.',
        },
      },
      //Change Job Assignments and Priorities
      ChangeJobAssignmentsandPriorities: {
        title: 'Change Job Assignments and Priorities',
      },
      AddEditUserAssignments: {
        title: 'Add/Edit User Assignments',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
        content3:
          "Click on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against the job whose user assignments have to be added/edited.",
        content4:
          'The application redirects to the editing page. View the available details about every individual processing stage of the job.',
        content5:
          'Under the <b>Assigned To</b> column, click on the <b>Select AssignTo</b> dropdown menu(s) available against one/all of the processing stages and select the same/different users who has to process that stage.',
        note: "Adding/editing user assignment(s) in this procedure can be done only by users whose assigned roles have the respective permissions. Also, processing stages with status <b>In Progress</b> or <b>Completed</b> will not have the option to add/edit user assignments. Refer to <a class='help-trance'> TranceIQ User Manual</a> to know more.",
      },
      addeditjobpriorityateverystage: {
        title: 'Add/Edit Job Priority at Every Stage of the Job ',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
        content3:
          "Click on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against the job whose job priority levels have to be added/edited.",
        content4:
          'The application redirects to the editing page. View the available details about every individual processing stage of the job.',
        content5:
          'Under the <b>Priority</b> column, click on the dropdown menu(s) available against any one(or all) of the processing stage(s) and select the same or different priority level(s) required for the respective stage(s). There are priority levels 1-10 available, with 10 being the highest level of priority.',
        content6:
          'Select the option <b>Nil</b> if no distinct priority level is required for the job.',
        note: "Adding/editing job priority in this procedure can be done only by users whose assigned roles have the respective permissions. Also, processing stages with status <b>In Progress</b> or <b>Completed</b> will not have the option to add/edit job priority. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more.",
      },
      RestartCompletedStagesofJob: {
        title: 'Restart “Completed” Stages of a Job',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
        content3:
          "Click on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against the job to restart.",
        content4:
          'The application redirects to the editing page. View the available details about every individual processing stage of the job.',
        content5:
          'The restart option is only available for processing stages that have the <b>Status</b> column updated to <b>Completed</b>. Hence, check out the <b>Status</b> column for stages that have completed processing.',
        content6:
          'From the process stages which have status as "Completed", click on the <b>restart job</b> icon against the required stage. This restarts the processing of the job, starting from that processing stage to all the stages under it.',
        note: "Restarting “Completed” Stages can be done only by users whose assigned roles have the respective permissions. Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more.",
      },
      changeuserassignmentofaninprogressstage: {
        title: 'Change User Assignment of an “In Progress” Stage',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          'Click on the <b>Assignments</b> option at the top. The application redirects to the <b>Assignments</b> page in the <b>Files</b> section.',
        content3:
          "Click on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against the job whose user assignment(s) have to be stopped or changed.",
        content4:
          'The application redirects to the editing page. View the available details about every individual processing stage of the job.',
        content5:
          'To edit the user assignment of a processing level with <b>Status</b> as <b>In Progress</b>, first click on the <b>stop job</b> icon against the respective level.',
        content6:
          'Application displays the <b>Stop This Job?</b> confirmation window. Click on <b>Yes</b> to stop the job. This changes the status of the job from <b>In Progress</b> to <b>NOT_STARTED</b>.',
        content7:
          'Now, under the <b>Assigned To</b> column, click on the <b>Select AssignTo</b> dropdown menu against the respective processing stage and select the user who has to process that level.',
      },
      //View Billing and Consumption Data
      ViewBillingandConsumptionData: {
        title: 'View Billing and Consumption Data',
      },
      ViewAccountBalanceOverview: {
        title: 'View Account Balance Overview',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'Extract the overall status of the account balance from the 3 fields of information at the top:',
        content2a:
          'The total amount of money that is deposited by the client for using the application is displayed in the <b>Payment</b> field.',
        content2b:
          'The total amount that is consumed to date by using the various services in the application is displayed in the <b>Consumed</b> field.',
        content2c:
          'The balance amount left from the deposited amount is displayed in the <b>Balance</b> field.',
      },
      ViewTransactionHistory: {
        title: 'View Transaction History',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'On the main section of the page, view the day-by-day history of credits/debits in the account, i.e., Transaction History. There is the <b>Date</b> of the transaction, the names of the <b>Projects</b> under which the account balance is consumed on that date by utilizing TranceIQ services, the number of <b>Consumed Hours</b>, the amount credited on that date to add <b>Payment</b> to the account balance, the amount <b>Consumed</b> (debited) on that day from the account balance, and the <b>Balance</b> left in the account after the debited amount for the day.',
      },
      ManageTransactionHistory: {
        title: 'Manage Transaction History',
        FilterTransactionHistory: {
          title: 'Filter Transaction History',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Choose one/multiple/all of the <b>filter criteria</b> available at the left side of the page.',
          content2a:
            'Click on the <b>calendar</b> icon against the <b>Start Date</b> filter and choose the date starting from which the transaction history has to be displayed.',
          content2b:
            'Click on the <b>calendar</b> icon against the <b>End Date</b> filter and choose the date up to when the transaction history has to be displayed',
          content2c:
            'Click on the <b>Project</b> filter dropdown and select the project whose transaction history has to be displayed.',
          content2d:
            'Click on the <b>Payment/Consumed</b> filter dropdown and select whether transaction history about account payments or account consumptions has to be displayed.',
          content3:
            'Once done, click on the <b>Apply</b> button. The application then displays the resulting transaction history based on the combination of the filter criteria selected.',
        },
        ExportTransactionHistoryData: {
          title: 'Export Transaction History Data',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Click on the <b>Export</b> icon at the top-right corner of the page and this starts the download procedure of the excel sheet into the local machine.',
          content3:
            'Open the downloaded file to view the transaction history in an offline spreadsheet.',
        },
        ViewAllPaymentsHistory: {
          title: 'View All Payments History',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Click on the <b>View All Payments</b> option at the right and the page redirects to the <b>All Payments</b> page.',
          content3:
            'On this page, view the day-by-day history of credits in the account, i.e., <b>Payments History</b>. There is the <b>Received Date</b> of the payment made by the respective client team, the <b>Amount</b> of payment made, the <b>Date of Entry</b> of crediting the payment amount into the account by the <b>TranceIQ Support Team</b>, and the name of the user from the <b>TranceIQ Support Team</b> by whom the payment amount is <b>Entered By</b>.',
        },
      },
      ViewJobWiseConsumptionCosts: {
        title: 'View Job-Wise Consumption Data',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
        content3:
          'On the left and main part of the page, view all details related to per-job consumption information. There is the <b>Date & Time</b> when an amount is consumed from the account, the <b>File Name</b> (job name) that utilized TranceIQ service(s) due to which an amount is consumed, the <b>Job Id</b> of the respective file, the name of the <b>Project</b> under which the respective job belongs, the <b>Consumed Hours</b> in utilizing the TranceIQ services for the job for which the amount is debited, and the <b>Cost</b> charged for the respective consumed hours.',
      },
      ManageJobWiseConsumptionData: {
        title: 'Manage Job Wise Consumption Data',
        FilterJobWiseConsumptionData: {
          title: 'Filter Job-Wise Consumption Data',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
          content3:
            'Choose one/multiple/all of the <b>filter criteria</b> available at the top.',
          content3a:
            'Click on the <b>calendar</b> icon against the <b>Start Date</b> filter and choose the date starting from which the job-wise consumption data has to be displayed.',
          content3b:
            'Click on the <b>calendar</b> icon against the <b>End Date</b> filter and choose the date up to when the job-wise consumption data has to be displayed.',
          content3c:
            'Click on the <b>Project</b> filter dropdown and select the project related to which the job-wise consumption data has to be displayed.',
          content4:
            'Once done, click on the <b>Apply</b> button. The application then displays the resulting job-wise consumption data based on the combination of the filter criteria selected.',
        },
        SearchJobWiseConsumptionData: {
          title: 'Search Job Wise Consumption Data',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
          content3:
            'Click on the <b>Search Bar</b> provided at the top of the page and enter the <b>File Name</b> or <b>Job Id</b> of the job related to which consumption data is required.',
          content4:
            'Press <b>Enter</b> and the related job’s consumption data is displayed based on the search results.',
        },
        ExportJobWiseConsumptionData: {
          title: 'Export Job Wise Consumption Data',
          content1:
            'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
          content2:
            'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
          content3:
            'Click on the <b>Export</b> icon at the top-right corner of the page and this starts the download procedure of the excel sheet into the local machine.',
          content4:
            'Open the downloaded file to view the job-wise consumption data in an offline spreadsheet.',
        },
      },
      ViewProjectWiseConsumptionCosts: {
        title: 'View Project-Wise Consumption Data',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
        content3:
          'On the right section of the page, view the list of projects and the respective number of hours consumed in processing jobs under those projects.',
        content4:
          'On the bottom of the right section, check out the total number of consumed hours for all the projects combined.',
      },
      FilterProjectWiseConsumptionData: {
        title: 'Filter Project-Wise Consumption Data',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'Click on the <b>Consumption</b> option at the top. The application redirects to the <b>Consumption</b> page in the <b>Billing</b> section.',
        content3:
          'On the right section of the page, project-wise consumption data is displayed. Use the filter criteria available at the top of that project-wise consumption data.',
        content4:
          'Click on the <b>calendar</b> icon against the <b>From</b> field and choose the date starting from which the project-wise consumed hours have to be displayed.',
        content5:
          'Click on the <b>calendar</b> icon against the <b>To</b> field and choose the date up to when the project-wise consumed hours have to be displayed.',
        content6:
          'Once done, click on the <b>Apply</b> button. The application then displays the resulting project-wise consumption data based on the combination of the filter criteria selected.',
      },
      ViewIndividualPricingofTranceServicesUsedintheAccount: {
        title: 'View Individual Pricing of TranceIQ Services Used in the Account',
        content1:
          'Select the <b>Billing</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Billing</b> page in the <b>Billing</b> section.',
        content2:
          'Click on the <b>Pricing</b> option at the top. The application redirects to the <b>Pricing</b> page in the <b>Billing</b> section. ',
        content3:
          'On this page, check out the charges consumed, i.e. to be paid, while utilizing the client-selected TranceIQ services for processing jobs. View the list of services opted for this account and their respective pricing.',
      },
      //Process Jobs in Transcription Level(s)
      ProcessJobsinTranscriptionLevel: {
        title: 'Process Jobs in Transcription Level(s)',
      },
      GototheTranscriptionPage: {
        title: 'Go to the Transcription Page',
        content1:
          'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
        content2:
          "Make sure the job to process in <b>Transcription</b> Level(s) has its <b>Status</b> updated to <b>Not Started/Transcription L1</b> (or Transcription L2/Transcription L3, if multiple process levels are chosen). Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more.",
        content3:
          "Click on the <b>play</b> icon (<img class='help-img' src={{value}}>) against the job and the page redirects to the Transcritpion Page/Transcription Editor Window.",
      },
      ManagetheVideoPlayinginMediaPlayerSection: {
        title: 'Manage the Video Playing in Media Player Section',
        MovetoAnyPositionintheVideo: {
          title: 'Move to Any Position in the Video',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Seek Bar</b> located below the video.',
          content3:
            "The seek bar helps to drag the video from the current playing position to another. Hold the circle (<img class='help-img' src={{value}}>) on the Seek bar and move it to any desired minute/second behind or forward from the current position.",
        },
        ControlAudioVolume: {
          title: 'Control Audio Volume',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Volume Controller</b> present at the extreme left of the media player controls  that are located below the video and its seek bar.',
          content3:
            "The volume controller helps to adjust the audio volume of the video playing on the media player. Hold the circle (<img class='help-img' class='help-img' src={{value}}>) on the volume controller and move it up or down to increase or decrease the volume respectively to comfort.",
          content4:
            'Check out the exact percentage of current volume level at the top of the controller.',
          content5:
            "Click on the speaker icon (<img class='help-img' class='help-img' src={{value}}>) at the bottom of the controller to mute/unmute the audio if required.",
        },
        ControlVideoPlayingSpeed: {
          title: 'Control Video Playing Speed',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Speed Controller</b> present at the extreme right of the media player controls that are located below the video and its seek bar.',
          content3:
            "The speed controller helps adjust the playing speed of the video on the media player. Hold the circle (<img class='help-img' src={{value}}>)  on the speed controller and move it up or down to respectively increase or decrease the speed w.r.t to the normal speed.",
          content4:
            'Check out the exact percentage of speed at the top of the controller. “100%” is the default speed (normal speed) of the video.',
        },
        ForwardReversetheVideo: {
          title: 'Forward/Reverse the Video',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Forward/Reverse</b> options present at the center of the media player controls that are located below the video and its seek bar.',
          content3:
            'The forward and reverse controllers help move the video forward or backward from the current position. There are 3 sets of forward/reverse controllers, click on any one of them as per your requirements:',
          content3a:
            'Click on <b>-1F</b> or <b>+1F</b> buttons to move the video 1 frame forward or backward from current position',
          content3b:
            'Click on <b>-10F</b> or <b>+10F</b>  buttons to move the video 10 frames forward or backward from current position',
          content3c:
            'Click on <b>-1S</b> or <b>+1S</b>  buttons to move the video 1 second forward or backward from current position',
        },
        AutoSeekVideo: {
          title: 'Auto Seek Video',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Auto Seek Video</b> check box present below the media player controls under the video.',
          content3:
            'The auto seek editor option helps move the video from the current position to any position in the transcript. Click on the <b>Auto Seek Video</b> check box to enable the option.',
          content4:
            'Followed by that, place the cursor near the part of the transcript to where the current playing position of the video should automatically shift. The transcript (of the media file), generated from backend, is displayed on the right section of the page known as the transcription section.',
        },
        AutoScrolltheTranscript: {
          title: 'Auto Scroll Editor',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Auto Scroll Editor</b> check box present below the media player controls under the video.',
          content3:
            'The auto scroll editor option helps automatically scroll down the transcript along with the video. Click on the <b>Auto Scroll Editor</b> check box to enable the option.',
          content4:
            'The transcript (of the media file), generated from backend, is displayed on the right section of the page known as the transcription section. Upon selecting the checkbox, the scroll bar on the transcription section will keep moving w.r.t to the video time playing.',
        },
      },
      ProcessTranscriptsinTranscriptionSection: {
        title: 'Process Transcripts in Transcription Section',
        AddRemoveSpeakerTags: {
          title: 'Add Speaker Tags of New/Existing Speakers',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Add Speaker</b> option from the several transcript-processing features present above the transcript.',
          content3:
            'Speaker tags in the transcript helps to differentiate the conversation between the characters speaking in the video. Take the mouse pointer and set the insertion point in the transcript at the position where the dialogue of one character ends and another is starting.',
          content4:
            'Click on the <b>Add Speaker</b> option (or click on the <b>Insert menu</b> at the top of the page and select <b>Speaker Tag</b>) and a new speaker tag is added.',
          content5:
            'Type a name to the new speaker tag and click enter to successfully add the new speaker tag, at the position where the cursor is placed. The rest of the transcript is displayed under this new speaker tag.',
        },
        EditDefaultSpeakerTagNames: {
          title: 'Edit Default Speaker Tag Names',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'Default speaker tags are inserted from backend into the transcript, wherever the character speaking the dialogue changes. Spot these speaker tags all through the transcript, displayed on the right section of the page i.e., transcription section.',
          content3:
            'To edit these default speaker tag names (like <b>MS1</b>, <b>MS2</b>, etc.), click on the default title and replace it with the name of choice.',
        },
        SetEditColoroftheSpeakerTag: {
          title: 'Set/Edit Color of the Speaker Tag',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'Default speaker tags are inserted from backend into the transcript, wherever the character speaking the dialogue changes. Spot these speaker tags all through the transcript, displayed on the right section of the page i.e., transcription section.',
          content3:
            "To set a customized color for the speaker, click on the color picker icon (<img class='help-img' src={{value}}>) against the speaker tag.",
          content4:
            'Check out the available color options and choose the color of choice to apply for that respective speaker tag.',
        },
        Deletespeakertag: {
          title: 'Delete Speaker Tag',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'Default speaker tags are inserted from backend into the transcript, wherever the character speaking the dialogue changes. Spot these speaker tags all through the transcript, displayed on the right section of the page i.e., transcription section.',
          content3:
            "To delete a speaker tag, click on the <b>delete</b> icon (<img class='help-img' src={{value}} >) against the speaker tag.",
          content4:
            'Application displays the <b>Delete Speaker</b> confirmation window, click on the <b>Delete</b> button to successfully remove the speaker.',
        },
        AddSoundCues: {
          title: 'Add Sound Cues',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Add Sound Cues</b> option from the several transcript-processing features present above the transcript.',
          content3:
            'Sound Cues help differentiate the sound effects representation in the transcript from the characters’ dialogues. Take the mouse pointer and set the insertion point in the transcript at the position respective to where the sound effect is playing in the media file.',
          content4:
            'Click on the <b>Add Sound Cues</b> icon (or click on the <b>Insert</b> menu at the top of the page and click on the <b>Sound Cues</b> option).',
          content5:
            'The page displays a flash window near the insertion point with the list of all possible sound cues. Use the search bar or scroll down in the window to select the suitable option that represents the sound effect playing in the media file. The application successfully adds the sound cue in the transcript with enclosed brackets, at the position where the cursor is placed.',
          content6:
            'If none of the available options represent the sound effect in the media file, click on the <b>Add New Sound Cue</b> button on the flash window. Enter the sound cue in the <b>Add New Sound Cue</b> window that appears and click <b>Save</b> to add this customized sound cue to the list of Sound Cues in the particular account. Customized sound cues can also be deleted when required using the <b>delete</b> icon against it.',
        },
        UndoRedoChanges: {
          title: 'Undo/Redo Changes',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Undo</b> and <b>Redo</b> options from the several transcript-processing features present above the transcript.',
          content3:
            'Undo option helps change the last action on the transcript. Click on the <b>Undo</b> icon and the last edit on the transcript will be removed.',
          content4:
            'Redo option helps reverse the last undone action on the transcript. Click on the <b>Redo</b> icon and the last edit on the transcript that was removed using <b>“Undo”</b> will revert back.',
        },
        AddMusicSymbol: {
          title: 'Add Music Symbol (Enclose Lyrics)',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Enclose Lyrics</b> option from the several transcript-processing features present above the transcript.',
          content3:
            'Enclose lyrics option helps differentiate song lyrics in the media file from the characters’ dialogues. Highlight the song lyrics in the transcript and click on the <b>Enlcose Lyrics</b> option and the application successfully encloses those lyrical lines with music tags at both ends for differentiation.',
        },
        ApplyBoldItalicStyletoTranscriptText: {
          title: 'Apply Bold/Italic Style to Transcript Text',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Bold</b> and <b>Italic</b> options from the several transcript-processing features present above the transcript.',
          content3:
            'Highlight the required word(s)/sentence(s) in the transcript and click on the <b>Bold</b> icon. The application successfully changes the word style of the highlighted text into bold.',
          content4:
            'Highlight the required word(s)/sentence(s) in the transcript and click on the <b>Italic</b> icon. The application successfully changes the word style of the highlighted text into italics.',
        },
        PerformSpellCheck: {
          title: 'Perform Spell Check',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Spell Check</b> option at the extreme left of the several transcript-processing features present above the transcript.',
          content3:
            'Spell Check feature helps in detecting misspelled words and provides suggestions of the right words that can be used in their place. Click on the <b>Spell Check</b> icon (or click on the <b>Edit</b> menu at the top of the page and click on the <b>Spell Check</b> option).',
          content4:
            'The application opens the <b>Spell Checking</b> flash window which has the list of all misspelled words in the transcript.',
          content5:
            'One misspelled word is displayed per page. So, use the <b>left</b> and <b>right</b> arrow buttons at the bottom-right corner to visit all the pages.',
          content6:
            'On every page, check out the misspelled word displayed in the <b>Suggestion For</b> field. Upon checking, implement any one of the 4 possible actions mentioned below:',
          content6a:
            'Choose an alternative word from the list of suggestions given and click on the <b>Change</b> button to replace the old word with the suggested word.',
          content6b:
            'Choose an alternative word from the list of suggestions given and click on the <b>Change All</b> button to replace the old word with the suggested word in all the instances of that word in the transcript.',
          content6c:
            'If none of the suggested words seem a right fit, click on the <b>Ignore</b> button to leave the word as it is or to manually edit the word in the transcript.',
          content6d:
            'If none of the suggested words seem a right fit for all the instances of that word in the transcript, click on the <b>Ignore All</b> button to leave the word as it is or to manually edit the word in the transcript.',
          content7:
            'Repeat step 6 for all the detected misspelled words shown on the flash window.',
          content8:
            'Parallelly, check out the position of the misspelled words as they are highlighted in white in the transcript as long the <b>Spell Check</b> window is open. The misspelled word displayed on the current page of the <b>Spell Check</b> window is highlighted in red.',
        },
        FindandReplaceWords: {
          title: 'Find and Replace Words',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Find & Replace</b> option from the several transcript-processing features present above the transcript.',
          content3:
            'Click on the <b>Find & Replace</b> textbox (or click on the <b>Edit</b> menu at the top of the page and click on the <b>Find & Replace</b> option) and type the word from the transcript that requires replacement with another.',
          content4:
            'The application opens a flash window with all the instances of the word identified in the transcript. The instances are also highlighted in the transcript for reference.',
          content5:
            'Use the <b>left</b> and <b>right</b> arrow buttons on the window to go to the particular instance where the word is to be replaced in the transcript.',
          content6:
            'Type the new word in the <b>Replace with</b> field and click on the <b>Replace</b> button to successfully replace the old word with the now entered one.',
          content7:
            'To replace all the instances of the word, type the new word and select <b>Replace All</b> button.',
        },
        AddBookmarks: {
          title: 'Add Bookmarks',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'In the header section of the page, spot the <b>Add Bookmark</b> option from the several transcript-processing features present above the transcript.',
          content3:
            'Bookmarks help flag doubts/refer-to-later items in the transcript by adding a hotkey above the related words/sentences. Highlight the word(s)/sentence(s) in the transcript to be flagged and click on the the <b>Add Bookmark</b> icon (or click on the <b>View</b> menu at the top of the page and click on the <b>Add Bookmarks</b> option).',
          content4:
            'The application displays the <b>Bookmark</b> window. Enter a comment explaining why the word/sentence is bookmarked and click on the <b>Save</b> button to successfully add a bookmark on the required word/sentence.',
        },
      },
      UtilizeOtherInternalFeatures: {
        title: 'Utilize Other Internal Features',
        ImportTranscript: {
          title: 'Import Transcript',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To import a transcript, click on the <b>File</b> menu at the top of the page and click on the <b>Import Transcript</b> option.',
          content3:
            'The application opens the <b>Import Transcript</b> window. Choose one of the 2 available options:',
          content3a:
            '<b>Append to Current Transcript</b> — The transcript obtained from the backend is first displayed as usual, and the imported transcript is appended to the last sentence of the backend transcript.',
          content3b:
            '<b>Replace Current Transcript </b>— The imported transcript is displayed in the Transcription Section, replacing the transcript obtained from the backend (ASR Engine).',
          content4:
            'Upon choosing, click on the <b>Proceed</b> button and choose the transcript file from the local machine, and the respective transcript will be successfully imported.',
        },
        ChangePreset:{
          title:'Change Preset',
          content1:'Check out the ',
          content1link:'Go to the Transcription Page',
          content1b:' section to know how to open a job in the Transcription page.',
          content2:'To change the default preset applied for the current job (selected during that job upload), click on the File menu at the top of the page and click on the Change Preset option.',
          content3:'The application displays the list of preset templates available. Choose one and the default preset to be applied for this media file immediately changes to the selected one. The preset rules of this new preset template can be seen reflected on the media file after moving it to the Captioning/Translation Page.'
        },
        ChangeFrameRate: {
          title: 'Change Frame Rate',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To change frame rate, click on the <b>File</b> menu at the top of the page and click on the <b>Change Frame Rate</b> option.',
          content3:
            'The application displays the list of frame rates available. Choose one and the frame rate of the video in the media player immediately changes to the selected value.',
          content4: 'For validation of the updated frame rate, refer to ',
          content4link: 'Show Time in Frames',
          content4b:
            ' section to know how to display the frame numbers of the video.',
        },
        ViewJobInfo: {
          title: 'View Job Info',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To view the job-related information, click on the <b>File</b> menu at the top of the page and click on the <b>Job Info</b> option. The application opens a window with complete information about the job like file name, job id, duration, TAT, etc.',
        },
        InsertSpecialSymbols: {
          title: 'Insert Special Symbols',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            ' To insert special symbols, take the mouse pointer and set the insertion point in the transcript at the position where a special symbol needs to be inserted.',
          content3:
            'Click on the <b>Insert</b> menu at the top of the page and click on the <b>Special Symbols</b> option.',
          content4:
            'The application displays a list of the special symbols available. Select the required symbol to add it to the transcript at the position where the cursor is placed',
        },

        HighlightEdits: {
          title: 'Highlight Edits',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To review all changes made on the transcript while processing it, click on the <b>View</b> menu and select the <b>Highlight Edits</b> option.',
          content3:
            'The application highlights all the newly added/edited words in green colou, the deleted words in orange colour, and low confidence words in red colour.',
        },
        ViewBookmarks: {
          title: 'View Bookmarks',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To view the list of all bookmarks added to the transcript, click on the <b>Bookmarks</b> button from the several transcript-processing features present above the transcript (or click on the <b>View</b> menu at the top of the page and click on the <b>Bookmarks</b> option).',
          content3:
            'The application opens a small window with the list of all bookmarks in the transcript. One bookmark is displayed per page in the window. So, use the <b>left</b> and <b>right</b> arrow buttons at the top of the window to view all the bookmarks in order.',
        },
        DeleteBookmarks: {
          title: 'Delete Bookmarks',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'Click on the <b>Bookmarks</b> button from the several transcript-processing features present above the transcript (or click on the <b>View</b> menu at the top of the page and click on the <b>Bookmarks</b> option).',
          content3:
            'The application opens a small window with the list of all bookmarks in the transcript. To delete a bookmark, click on the <b>Remove</b> button present at the bottom of the window.',
          content4:
            'One bookmark is displayed per page in the window. So, use the <b>left</b> and <b>right</b> arrow buttons at the bottom-right corner to go to the required bookmark that needs to be deleted.',
          content5:
            'To delete all the bookmarks in the transcript, click on the <b>Remove All</b> button present at the bottom of the window.',
        },
        ChooseUIFontFamily: {
          title: 'Choose UI Font Family',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To change the font type of the transcript, click on the <b>View</b> menu and select the <b>UI Font Family</b> option.',
          content3:
            'The application displays a list of all the available fonts. Select the required font type from the list to apply it to the entire transcript text in the <b>Transcription</b> Section.',
        },
        ChooseUIFontSize: {
          title: 'Choose UI Font Size',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To change the font size of the transcript, click on the <b>View</b> menu and select the <b>UI Font Size</b> option.',
          content3:
            'The application displays a list of all the available sizes. Select the required number from the list to apply it to the entire transcript text in the <b>Transcription</b> Section.',
        },
        ChooseUIFontColor: {
          title: 'Choose UI Font Color',
          content1: 'Check out the',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'To change the font color of the transcript, click on the <b>View</b> menu and select the <b>UI Font Color</b> option.',
          content3:
            'The application displays a list of all the available text colors. Select the required color from the list to apply it to the entire transcript text in the <b>Transcription</b> Section.',
        },
        ShowTimeinFrames: {
          title: 'Show Time in Frames',
          content1: 'Check out the ',
          contentlink: 'Go to the Transcription Page',
          content1b:
            '  section to know how to open a job in the Transcription page.',
          content2:
            'The timestamps of the current running time and the end time of the video playing in media player section are displayed under the progress bar.  To replace these timestamps with frame numbers of the current running frame and the end frame respectively, click on the <b>View</b> menu at the top of the page and click on the <b>Show Time in Frames</b> option.',
          content3:
            'The application immediately replaces the timestamp values under the video with frame numbers for reference.',
        },
      },
      DeliverOutput: {
        title: 'Deliver Output',
        content1: 'Check out the',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'After finishing the processing of the job, to confirm the same and close the window, click on the <b>Deliver Output</b> button at the top-right corner (or click on the <b>File</b> menu at top of the page and then click on the <b>Deliver Output</b> option).',
        content3:
          'The application displays the <b>Deliver Output?</b> confirmation window. Click on <b>Ok</b> to confirm the action. The job status for the respective transcription process level is updated as <b>Complete</b>, the transcription window is closed, and the application redirects to the Files page.',
      },
      DownloadTranscript: {
        title: 'Download Transcript',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'After finishing the processing of the job, to download the final output transcript of this particular process level, click on the <b>File</b> menu at top of the page and click on the <b>Download Transcript</b> option.',
        content3:
          'Select the file format in which the transcript has to be downloaded and the respective file(s) starts downloading into the local machine.',
      },
      GotoCaptioning: {
        title: 'Goto Captioning',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'After finishing the processing of the job in the current <b>Transcription</b> stage, to directly go to the <b>Captioning</b> stage from this editor window itself, click on the <b>File</b> menu at the top of the page and click on the <b>Goto Captioning</b> option.',
        content3:
          'The application displays the <b>Goto Captioning</b> confirmation window. Click <b>Yes</b> to successfully redirect the application from transcription page to captioning page.',
      },
      CloseJob: {
        title: 'Close Job',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'To save the transcription work done till the moment and close the page, click on the <b>File</b> menu at the top of the page and click on the <b>Close</b> option.',
        content3:
          'The application displays the <b>Close</b> confirmation window. Click <b>Ok</b> to save the changes and redirect the application from transcription page to the files page.',
      },
      ViewHelpGuidelinesRelatedtothisJob: {
        title: 'View Help Guidelines Related to this Job',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'To view the guidelines related to this job (inputted during creation of the project under which the job is added), click on the <b>Help</b> menu at the top of the page and click on the <b>Guidelines</b> option.',
        content3:
          'The application opens the <b>Guidelines</b> window where the text or the documents with tips or instructions related to processing of the respective job are displayed.',
      },
      ViewKeyboardShortcuts: {
        title: 'View Keyboard Shortcuts',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'To view the keyboard shortcuts (shortcuts available by default or user added shortcuts for the transcription page) available for the respective editor window, click on the <b>Help</b> menu at the top of the page and click on the <b>Shortcuts</b> option.',
        content3:
          'The application opens the Keyboard Shortcuts window where the list of the features/actions/functions that have shortcuts and their respective shortcut key combinations are displayed.',
      },
      ViewVocabularyRelatedtothisJob: {
        title: 'View Vocabulary Related to this Job',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'To refer to unique words or terminology that are specific to this job (inputted during the creation of the project under which the job is added), click on the <b>Help</b> menu at the top of the page and click on the <b>Vocabulary</b> option.',
        content3:
          'The application opens the <b>Vocabulary</b> window where the text or the documents related to job-specific vocabulary are displayed.',
      },
      ViewStyleGuideforCaptions: {
        title: 'View Style Guide for Captions',
        content1: 'Check out the ',
        contentlink: 'Go to the Transcription Page',
        content1b:
          '  section to know how to open a job in the Transcription page.',
        content2:
          'To refer to the instructions/guidelines related to captions, that are specific to this job (inputted during the creation of the project under which the job is added), click on the <b>Help</b> menu at the top of the page and click on the <b>Style Guide</b> option',
        content3:
          'The application opens the <b>Style Guide</b> window where the text or the documents related to styling, formatting, and arrangement of the captions of this job are displayed.',
      },
      //Process Job in Captioning Level(s)
      ProcessJobinCaptioningLevel: {
        title: 'Process Job in Captioning Level(s)',
        GototheCaptioningPage: {
          title: 'Go to the Captioning Page',
          content1:
            'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
          content2:
            "Make sure the job to process in Captioning Level(s) has its <b>Status</b> updated to <b>Captioning L1/Translation</b> (or Captioning L2/Captioning L3, if multiple process levels are chosen). Refer to <a class='help-trance'>TranceIQ User Manual</a> to know more.",
          content3:
            "Click on the <b>play</b> icon (<img class='help-img' src={{value}}>) against the job and the page redirects to the Captioning Page/Captioning Editor Window.",
        },
        ManagetheVideoPlayinginMediaPlayerSection: {
          title: 'Manage the Video Playing in Media Player Section',
          MovetoAnyPositionintheVideo: {
            title: 'Move to Any Position in the Video',
            content1: ' Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Move to Any Position in the Video ',
            content2b: 'segment in the ',
            content2link2: 'Process Job in Transcription Level(s) ',
            content2c:
              'section for a step-by-step guide on how to utilize this feature.',
          },
          ControlAudioVolume: {
            title: 'Control Audio Volume',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page  ',
            content1b:
              'section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: ' Control Audio Volume ',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s) ',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ControlVideoPlayingSpeed: {
            title: 'Control Video Playing Speed',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page section ',
            content1b: ' to know how to open a job on the Captioning page. ',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Control Video Playing Speed  ',
            content2b: ' segment in the ',
            content2link2: ' Process Job in Transcription Level(s) ',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ForwardReversetheVideo: {
            title: 'Forward/Reverse the Video',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page ',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Forward/Reverse the Video',
            content2b: ' segment in the ',
            content2link2: ' Process Job in Transcription Level(s) ',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          AutoSeekVideo: {
            title: 'Auto Seek Video',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page  ',
            content1b:
              ' section to know how to open a job on the Captioning page. ',
            content2:
              'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Auto Seek Video</b> check box present below the media player controls under the video.',
            content3:
              'The auto seek editor option helps move the video from the current position to any caption box position. Click on the <b>Auto Seek Video</b> check box to enable the option.',
            content4:
              'Followed by that, place the cursor near the caption box that contains the captions’ text of the required caption frame to where the current playing position of the video should automatically shift. The caption boxes (of the media file), generated using the transcript from backend/transcription page, are displayed on the right section of the page known as the captioning section.',
          },
          AutoScrolltheCaptions: {
            title: 'Auto Scroll Editor',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page. ',
            content2:
              'The left section of the page is the media player section where the video of the uploaded media file will be playing. Spot the <b>Auto Scroll Editor</b> check box present below the media player controls under the video.',
            content3:
              'The auto scroll editor option helps automatically scroll down the caption boxes along the video. Click on the <b>Auto Scroll Editor</b> check box to enable the option.',
            content4:
              'The caption boxes (of the media file), generated using the transcript from backend/transcription page, are displayed on the right section of the page known as the captioning section. Upon selecting the checkbox, the scroll bar on the captioning section will keep moving from caption box to caption box w.r.t to the caption frame playing. The caption box that contains the captions’ text of the current playing caption frame is continuously highlighted during the auto scrolling.',
          },
        },
        ManageCaptionBoxesintheCaptioningSection: {
          title: 'Manage Caption Boxes in Captioning Section',
          AddaNewCaptionBox: {
            title: 'Add a New Caption Box (Append Up & Append Down)',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page. ',
            content2:
              'In the right section i.e., captioning section, select the caption box above/below which a new caption box needs to be added.',
            content3:
              'Click on the <b>Caption Merging</b> icon from the several captions-processing features present above the caption boxes.',
            content4:
              'A small flash box opens with icons, each of which represents the different features available for managing caption boxes. Select the <b>Append Up</b> icon to add a new empty caption box above the selected caption box.',
            content5:
              'To add a new empty caption box below the selected caption box, select the <b>Append Down</b> icon. ',
            content6:
              'Finally, enter the relevant captions’ text (w.r.t the video playing) on this new caption box.',
          },
          SplitaCaptionBox: {
            title: 'Split a Caption Box',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'In the right section i.e., captioning section, place the cursor at the required position on the required caption box from where the captions need to be split into a new caption box.',
            content3:
              'Click on the <b>Caption Merging</b> icon from the several captions-processing features present above the caption boxes.',
            content4:
              'A small flash box opens with icons, each of which represents the different features available for managing caption boxes. Select the <b>Split Caption</b> icon to create a new caption box below with the captions text from the old box, right from the position where the cursor is placed.',
          },
          MergeCaptionBoxes: {
            title: 'Merge Caption Boxes',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page. ',
            content2:
              'In the right section i.e., captioning section, select the caption box(es) to be merged with the caption box above/below it.',
            content3:
              'Click on the <b>Caption Merging</b> icon from the several captions-processing features present above the caption boxes.',
            content4:
              'A small flash box opens with icons, each of which represents the different features available for managing caption boxes. Select the <b>Move One Cell Up </b>icon to merge the selected caption box with the consecutive caption box above it.',
            content5:
              'To merge the selected caption box with the consecutive caption box below it, select the <b>Move One Cell Down</b> icon. ',
          },
          DeleteCaptionBoxes: {
            title: 'Delete Caption Boxes',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'In the right section i.e., captioning section, select the caption box(es) to be deleted.',
            content3:
              'Click on the <b>Delete</b> icon from the several captions-processing features present above the caption boxes, and the selected caption box will be successfully deleted.',
          },
          AdjustCaptionsAlignmentInsidetheBox: {
            title: 'Adjust Captions Alignment Inside the Box',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'In the right section i.e., captioning section, select the caption box(es) whose alignment needs to be edited. Click on the <b>Select All</b> option above to select all the caption boxes if required.',
            content3:
              'Click on the <b>Alignment Menu</b> icon from the several captions-processing features present above the caption boxes.',
            content4:
              'A small flash box opens with left, center, and right alignment options. Select the <b>Left Align</b> icon to align the text inside the selected caption box to the left of the box.',
            content5:
              'Select the <b>Center Align</b> icon to align the text inside the selected caption box to the center of the box.',
            content6:
              'Select the <b>Right Align</b> icon to align the text inside the selected caption box to the right of the box.',
          },
        },
        ManageCaptionsPositionontheVideo: {
          title: 'Manage Captions Position on the Video',
          AdjustCaptionsHorizontalAlignment: {
            title: 'Adjust Captions Horizontal Alignment ',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Click on the <b>Horizontal Captioning Position Controls</b> icon from the several captions-processing features present above the caption boxes.',
            content3:
              'A small flash box opens with options to position the captions on the video to the left, center, or right. Select the <b>Move Captions to Left</b> icon to display the captions on the left of the video.',
            content4:
              'Select the <b>Move Captions to Center</b> icon to display the captions in the center of the video.',
            content5:
              'Select the <b>Move Captions to Right</b> icon to display the captions on the right of the video.',
          },
          CellwiseControltheHorizontalPositioningofCaptions: {
            title: 'Cell-wise Control the Horizontal Positioning of Captions',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Beside the <b>Horizontal Captioning Position Controls</b> icon, spot the <b>left</b> and <b>right</b> arrows for cell-wise editing of the horizontal position of captions.',
            content3:
              'Click on the <b>left</b> arrow to move the captions position to one cell left. Continue using the <b>left</b> arrow to move the captions position to as many cells left as required.',
            content4:
              'To move the captions position to one cell right, click on the <b>right</b> arrow. Continue using the <b>right</b> arrow to move the captions position to as many cells right as required.',
            content5:
              'Finally, the exact horizontal cell number that the captions are currently positioned at is displayed between the <b>left</b> and <b>right</b> arrows. Refer to that value for confirmation of whether or not the captions position reached the desired horizontal cell position.',
          },
          AdjustCaptionsVerticalAlignment: {
            title: 'Adjust Captions Vertical Alignment',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Click on the <b>Vertical Captioning Position Controls</b> icon from the several captions-processing features present above the caption boxes.',
            content3:
              'A small flash box opens with options to position the captions on the bottom, middle, or top of the video. Select the <b>Move Captions to Bottom</b> icon to display the captions on the bottom end of the video.',
            content4:
              'Select the <b>Move Captions to Middle</b> icon to display the captions on the vertical center of the video.',
            content5:
              'Select the <b>Move Caption to Top</b> icon to display the captions on the top end of the video.',
          },
          CellwiseControltheVerticalPositioningofCaptions: {
            title: 'Cell-wise Control the Vertical Positioning of Captions',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Beside the <b>Vertical Captioning Position Controls</b> icon, spot the <b>up</b> and <b>down</b> arrows for cell-wise editing of the vertical position of captions.',
            content3:
              'Click on the <b>up</b> arrow to move the captions position to one cell above. Continue using the <b>up</b> arrow to move the captions position to as many cells above as required.',
            content4:
              'To move the captions position to one cell below, click on the <b>down</b> arrow. Continue using the <b>down</b> arrow to move the captions position to as many cells below as required.',
            content5:
              'Finally, the exact vertical cell number that the captions are currently positioned at is displayed between the <b>up</b> and <b>down</b> arrows. Refer to that value for confirmation of whether or not the captions position reached the desired vertical cell position.',
          },
        },
        ProcessCaptionsinCaptioningSection: {
          title: 'Process Captions in Captioning Section',
          AddRemoveSpeakerTags: {
            title: 'Add Speaker Tags',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'In the header section of the page, spot the <b>Add Speaker</b> option from the several captions-processing features present above the caption boxes.',
            content3:
              'Speaker tags in the caption boxes help differentiate the conversation between the characters speaking in the video. Take the mouse pointer and set the insertion point at the required word in the required caption box from where the dialogue of one character ends and another is starting.',
            content4:
              'Click on the <b>Add Speaker</b> option (or click on the <b>Insert</b> menu at the top of the page and select <b>Speaker Tag</b>).',
            content5:
              'A small flash window with the list of added speakers for this video is displayed below the icon. There are 3 ways to add speaker tags using this window:',
            content5a:
              'Select the required speaker from the list and the new speaker tag is successfully added at the position where the cursor is placed.',
            content5b:
              'Search for the speaker tag using the search box at the top, select the required speaker tag from the search results, and the new speaker tag is successfully added at the position where the cursor is placed.',
            content5c:
              'For new speaker tags that are not present in the list, type the new speaker tag name in the search box at the top and press <b>enter</b>, and the new speaker tag is successfully added at the position where the cursor is placed.',
            content6:
              'Simply use the backspace key on the keyboard to remove the added speaker tag if required.',
          },
          EditDefaultSpeakerTagNames: {
            title: 'Edit Default Speaker Tag Names',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Edit Default Speaker Tag Names',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          AddSoundCues: {
            title: 'Add Sound Cues',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Add Sound Cues ',
            content2b: 'segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          UndoRedoChanges: {
            title: 'Undo/Redo Changes',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Undo/Redo Changes',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          AddMusicSymbol: {
            title: 'Add Music Symbol (Enclose Lyrics)',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Add Music Symbol(Enclose Lyrics)',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ApplyBoldItalictoCaptionsText: {
            title: 'Apply Bold/Italic to Captions Text',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Apply Bold/Italic to Transcript Text ',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          PerformSpellCheck: {
            title: 'Perform Spell Check',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Perform Spell Check',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          FindandReplaceWords: {
            title: 'Find and Replace Words',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Find and Replace Words',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          AddBookmarks: {
            title: 'Add Bookmarks',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Add Bookmarks ',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
        },
        CheckforCaptionProperties: {
          title: 'Check for Caption Properties',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'Click on the <b>Properties</b> sliding window option present at the right end of the page.',
          content3:
            'In the window, view the captioning-related properties of the current caption box w.r.t the video or the cursor position. The properties include the <b>No. of Lines</b> of captions in the caption box, the <b>Line Length</b> i.e., the number of characters in the caption line, the <b>Duration</b> it is taking to play the part in the video where the lines in the caption box are playing, and the <b>Char/Sec</b> ratio at the part of the video where the lines in the caption box are playing.',
          content4:
            'In the brackets against every property, the respective property’s standard value configured during the project under which the job is uploaded is displayed. Compare the caption properties of every box with these standard values if required.',
          content5:
            'If a property exceeds the standard value, then the application recognizes that there is an error in the properties of that respective caption box. Spot the <b>Check for Errors</b> button at the bottom of the window to check out the errors in captions w.r.t caption properties.',
          content6:
            'Use the <b>up</b> and <b>down</b> arrows against the <b>Check for Errors</b> button to navigate in order to the properties of all the caption boxes where errors are detected. The error is highlighted in yellow color for easy reference.',
        },
        ViewCaptionsinaSpectrogram: {
          title: 'View Captions in a Spectrogram',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'Click on the <b>Spectrogram</b> sliding window option present at the bottom end of the page.',
          content3:
            'Spectrogram is the audio of the media file processed into its spectrum of frequencies. Use the spectrogram to check out the ups and downs in the audio w.r.t the frequency of the dialogues, music, and silence.',
          content4:
            'The captions are also displayed on the spectrogram w.r.t the dialogue frequencies. Refer to this to synchronize the captions with the audio, down to milliseconds level of precision.',
        },
        ManageSpectrogram: {
          title: 'Manage Spectrogram',
          ZoomInZoomOutonSpectrogram: {
            title: 'Zoom-In/Zoom-Out on Spectrogram',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Open the spectrogram by clicking on the <b>Spectrogram</b> sliding window option present at the bottom end of the page.',
            content3:
              'This opens the spectrogram window. Click on the <b>Zoom In/Zoom Out </b>icons on the top of the window to minimize/maximize the duration of the spectrogram visible on the screen.',
          },
          AddCaptions: {
            title: 'Add Captions',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Open the spectrogram by clicking on the <b>Spectrogram</b> sliding window option present at the bottom end of the page.',
            content3:
              'This opens the spectrogram window. Click on the <b>Add Caption</b> icon at the top of the window to add a new caption box.',
            content4:
              'The application displays the <b>Add Caption</b> window. Enter the <b>Start Time</b> and <b>End Time</b> between which the dialogue is spoken.',
            content5:
              'In the text box below, enter the captions text and click on <b>Add Caption</b> to successfully append the new caption box at the mentioned time duration.',
          },
          SeektoAnyPositionintheSpectrogram: {
            title: 'Seek to Any Position in the Spectrogram',
            content1: 'Check out the ',
            content1link: ' Go to the Captioning Page ',
            content1b:
              'section to know how to open a job on the Captioning page.',
            content2:
              'Open the spectrogram by clicking on the <b>Spectrogram</b> sliding window option present at the bottom end of the page.',
            content3:
              'This opens the spectrogram window. Spot the <b>Spectrogram Seek Bar</b> located at the top-center of the window.',
            content4:
              'The seek bar helps to drag the video and in turn the spectrogram as well from the current playing position to another. Hold the circle on the Spectrogram Seek Bar and move it to any desired minute/second behind or forward from the current position.',
          },
          MinimizeEnlargetheFrequencyWaveontheSpectrogram: {
            title: 'Minimize/Enlarge the Frequency Wave on the Spectrogram',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'Open the spectrogram by clicking on the <b>Spectrogram</b> sliding window option present at the bottom end of the page.',
            content3:
              'This opens the spectrogram window. Spot the <b>Zoom In/Zoom Out Bar</b> for the <b>Frequency Wave</b> located at the top-right of the window.',
            content4:
              'The bar helps to manually maximize or minimize the frequency wave of the spectorgram to any desired percentage. Hold the circle on the bar and keep moving it to left or right until the frequency wave on the spectrogram is visible in desired size.',
          },
        },
        UtilizeOtherInternalFeatures: {
          title: 'Utilize Other Internal Features',
          ImportCaptions: {
            title: 'Import Captions',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'To import captions, click on the <b>File</b> menu at the top of the page and click on the <b>Import Caption</b> option.',
            content3:
              ' Upon selecting, choose the caption file from the local machine, and the respective caption will be successfully imported. The current captions (generated by processing the trasnscript obtained from the backend or transcription page) displayed in the Captioning Section are then completely replaced with the imported captions.',
          },
          ChangeFrameRate: {
            title: 'Change Frame Rate',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Change Frame Rate',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ViewJobInfo: {
            title: 'View Job Info',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'View Job Info ',
            content2b: 'segment in the ',
            content2link2: ' Process Job in Transcription Level(s) ',
            content2c:
              'section for a step-by-step guide on how to utilize this feature.',
          },
          ApplyTCRTimecode: {
            title: 'Apply TCR Timecode',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'To apply TCR Timecode on the uploaded video, click on the <b>Edit</b> menu at the top of the page and click on the <b>TCR Timecode</b> option. This feature is used for videos that are broadcasted as an intermediate part of a multi-hour broadcasting program. The TCR Timecode feature helps synchronize the captions with the video, starting from the particular timestamp in the multi-hour schedule from when the uploaded video is broadcasted, instead of the default 00:00:00:00 timestamp.',
            content3:
              'Upon selecting the option, the application displays the <b>Apply TCR Timecode</b> window. Enter the timecode from when the respective video is broadcasted.',
            content4:
              'Click on the <b>Confirm</b> button and the video’s timestamps change and start from the entered TCR timecode.',
          },
          SelectCaptionsAppearanceStyle: {
            title: 'Select Captions Appearance Style',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'To change the captions appearance style, click on the <b>Edit</b> menu at the top of the page and click on the <b>Caption Appearance Style</b> option.',
            content3:
              'The application displays the list of appearance styles available (pop-on, paint-on, roll-up). Choose one and the captions on the video will start appearing in the selected style.',
          },
          InsertSpecialSymbols: {
            title: 'Insert Special Symbols',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Insert Special Symbols',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on utilizing this feature.',
          },
          ViewBookmarks: {
            title: 'View Bookmarks',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'View Bookmarks',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on utilizing this feature.',
          },
          DeleteBookmarks: {
            title: 'Delete Bookmarks',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Delete Bookmarks',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ChooseUIFontFamily: {
            title: 'Choose UI Font Family',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Choose UI Font Family',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on how to utilize this feature.',
          },
          ChooseUIFontSize: {
            title: 'Choose UI Font Size',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Choose UI Font Size',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide utilizing this feature.',
          },
          ShowTimeinFrames: {
            title: 'Show Time in Frames',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
            content2link: 'Show Time in Frames',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Transcription Level(s)',
            content2c:
              ' section for a step-by-step guide on utilizing this feature.',
          },
          ShowGridlines: {
            title: 'Show Gridlines',
            content1: 'Check out the ',
            content1link: 'Go to the Captioning Page',
            content1b:
              ' section to know how to open a job on the Captioning page.',
            content2:
              'To show gridlines on the video player, click on the <b>Player</b> menu at the top of the page and click on the <b>Show Gridlines</b> option. Gridlines visually show the coordinates of the video and these coordinates help in adjusting the position of the captions on the screen.',
            content3:
              'Upon selecting the option, the application displays a full grid on the media player. Now, use the options in the ',
            content3link: 'Manage Captions Position on the Video',
            content3b:
              ' section to precisely adjust the captions position w.r.t to the gridlines.',
          },
        },
        DeliverOutput: {
          title: 'Deliver Output',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'Deliver Ouput',
          content2b: ' segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on how to utilize this feature.',
        },
        DownloadCaptions: {
          title: 'Download Captions',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            ' After finishing the processing of the job, to download the final captioning output of this particular process level, click on the <b>File</b> menu at top of the page and click on the <b>Download Captions</b> option.',
          content3:
            '  Select the file format in which the file has to be downloaded and the respective captions file with accurate synced audio and time stamps starts downloading into the local machine.',
        },
        TranslatetoSecondaryLanguage: {
          title: 'Translate to Secondary Language',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'To translate the processed captions into a secondary language, click on the <b>File</b> menu at the top of the page and click on the <b>Translate</b> option. TranceIQ application supports the Captioning Service in multiple languages. This translation service helps to convert the processed captions in the captioning page into other languages, in order to provide regional captioning for international media content or vice versa.',
          content3:
            'Upon selecting the <b>Translate</b> option, the application displays a list of translation languages available for that job. Select the required language and the Captioning Section on the page immediately loads caption boxes of both primary and secondary languages, one beside the other.',
          content4: 'Now, use all the related options available in the ',
          content4link: 'Process Job in Captioning Level(s)',
          content4b:
            ' section to process the captions of this secondary language.',
        },
        CloseJob: {
          title: 'Close Job',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'Close Job ',
          content2b: 'segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on how to utilize this feature.',
        },
        ViewHelpGuidelinesRelatedtothisJob: {
          title: 'View Help Guidelines Related to this Job',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'View Help Guidelines Related to this Job',
          content2b: ' segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on how to utilize this feature.',
        },
        ViewKeyboardShortcuts: {
          title: 'View Keyboard Shortcuts',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'View Keyboard Shortcuts',
          content2b: ' segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on utilizing this feature.',
        },
        ViewVocabularyRelatedtothisJob: {
          title: 'View Vocabulary Related to this Job',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'View Vocabulary Related to this Job',
          content2b: ' segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on utilizing this feature.',
        },
        ViewStyleGuideforCaptions: {
          title: 'View Style Guide for Captions',
          content1: 'Check out the ',
          content1link: 'Go to the Captioning Page',
          content1b:
            ' section to know how to open a job on the Captioning page.',
          content2:
            'This particular feature on the Captioning Page has the same functionality as that on the Transcription Page. So, please refer to the ',
          content2link: 'View Style Guide for Captions',
          content2b: ' segment in the ',
          content2link2: 'Process Job in Transcription Level(s)',
          content2c:
            ' section for a step-by-step guide on utilizing this feature.',
        },
        DownloadOutputs: {
          title: 'Download Outputs',
          DownloadOutputfromTranscriptionCaptioningPages: {
            title:
              'Download Output from Transcription/Captioning Editor Windows',
            content1:
              'To know how to download output from Transcription Page, please refer to the ',
            content1link: 'Download Transcript',
            content1b: ' segment in the ',
            content1link2: 'Process Job in Transcription Level(s)',
            content1c: ' section.',
            content2:
              'To know how to download output from Captioning Page, please refer to the ',
            content2link: 'Download Captions',
            content2b: ' segment in the ',
            content2link2: 'Process Job in Captioning Level(s)',
            content2c: ' section.',
          },
          DownloadFinalCaptionFiles: {
            title: 'Download Final Caption Files',
            content1:
              'Select the <b>Files</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Files</b> page in the <b>Files</b> section.',
            content2:
              "Make sure the job to whose output to download has its <b>Status</b> updated to <b>Completed</b>. Refer to <a class='help-trance'> TranceIQ User Manual</a> to know more.",
            content3:
              "Click on the highlighted <b>download</b> icon (<img class='help-img' src={{value}}>) against the job and a zip file with all the selected output requirements of that job (refer to <a class='help-trance'>TranceIQ User Manual</a> to know more) is successfully downloaded into the local machine.",
          },
        },
      },
      //Configure/Manage Application Settings
      ConfigureManageApplicationSettings: {
        title: 'Configure/Manage Application Settings',
        ManageTranscriptionKeyboardShortcuts: {
          title: 'Manage Transcription Keyboard Shortcuts',
          ViewShortcuts: {
            title: 'View Shortcuts',
            content1:
              'Select the <b>Settings</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Notifications</b> page in the <b>Settings</b> section.',
            content2:
              'Keyboard shortcut is a practice of using a combination of keys for performing a specific action/function in the Transcription/Captioning editor windows. Click on the <b>Keyboard Shortcuts</b> option at the top.',
            content3:
              'The application redirects to the <b>Transcription</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section. View the list of all the existing keyboard shortcuts available for different actions in the Transcription page of the application.',
          },
          SearchShortcuts: {
            title: 'Search Shortcuts',
            content1:
              'Select the <b>Settings</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Notifications</b> page in the <b>Settings</b> section.',
            content2:
              'Click on the <b>Keyboard Shortcuts</b> option at the top. The application redirects to the <b>Transcription</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section.',
            content3:
              'Click on the <b>Search Bar</b> provided at the top of the page and enter the name of the required keyboard shortcut.',
            content4:
              'Press <b>Enter</b> and the related transcription-keyboard shortcuts are displayed based on the search results.',
          },
          EditShortcuts: {
            title: 'Edit Shortcuts',
            content1:
              'Select the <b>Settings</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Notifications</b> page in the <b>Settings</b> section.',
            content2:
              'Click on the <b>Keyboard Shortcuts</b> option at the top. The application redirects to the <b>Transcription</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section.',
            content3:
              "TranceIQ contains an enhanced keyboard shortcuts customization feature which allows users to edit the default shortcut key combinations to any other key combination as per convenience. To edit the key combination of a keyboard shortcut, click on the <b>edit</b> icon (<img class='help-img' src={{value}}>) against that shortcut. Application opens the <b>Select Shortcut</b> window.",
            content4:
              'The name of the specific feature/action/function to which this selected keyboard shortcut is used is mentioned at the top and its current key combination is mentioned under <b>Old Shortcut</b> field.',
            content5:
              'On the virtual keyboard on the right, application highlights only the keys that are acceptable for a shortcut. Select the required new key combination on these virtual keys.',
            content6:
              'If application notifies that the new combination selected is <b>Available</b> and if satisfied with that combination, click on the <b>Save</b> button to set this desired new key combination as the shortcut for that respective feature/action/function.',
            content7:
              'If application notifies that the entered new combination is “not available”, then deselect the entered key(s) and try setting another combination until the application displays status as “available”. Follow step 6 when the desired and available keyboard combination is finally selected.',
            content8:
              'If not satisfied with the selected new combination (“available” or “not available”) and want to use the default shortcut provided for that respective feature/action/function, click on the <b>Reset to Default</b> button at the bottom-left corner of the window. This displays <b>Reset this Shortcut?</b> confirmation window. Click on <b>Yes</b> for the application to save the default combination as the shortcut for that respective feature/action/function.',
            note: 'Please note that the <b>edit</b> icon for a few keyboard shortcuts is not present as they cannot be edited.',
          },
          ResetAllShortcutstoDefault: {
            title: 'Reset All Shortcuts to Default',
            content1:
              'Select the <b>Settings</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Notifications</b> page in the <b>Settings</b> section.',
            content2:
              'Click on the <b>Keyboard Shortcuts</b> option at the top. The application redirects to the <b>Transcription</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section.',
            content3:
              'TranceIQ contains an enhanced keyboard shortcuts customization feature which allows users to change the default shortcut key combinations to any other key combination as per convenience. To bring back all such customized keyboard shortcuts to default combinations, click on the <b>Reset All to Default</b> button at bottom-left corner of the window.',
            content4:
              'Application displays the <b>Reset All Shortcuts?</b> confirmation window. Click on <b>Yes</b> to reset all the shortcuts.',
          },
        },
        ManageCaptioningKeyboardShortcuts: {
          title: 'Manage Captioning Keyboard Shortcuts',
          content1:
            'To go to the settings of captioning-related keyboard shortcuts, select the <b>Settings</b> option/icon on the <b>Global Sliding Menu</b>. The application redirects to the <b>Notifications</b> page in the <b>Settings</b> section.',
          content2:
            'Click on the <b>Keyboard Shortcuts</b> option at the top. The application redirects to the <b>Transcription</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section.',
          content3:
            'Click on the Captioning option beside the current tab and the application redirects to the <b>Captioning</b> tab of the <b>Keyboard Shortcuts</b> page in the <b>Settings</b> section.',
          content4:
            'Captioning Keyboard Shortcuts has the same functionality as that of the Transcription Keyboard Shortcuts. So, please refer to the ',
          content4link: 'Manage Transcription Keyboard Shortcuts',
          content4b:
            ' section to get a step-by-step guide on the different actions that can be performed on this section.',
        },
      },
    },
  },
};
