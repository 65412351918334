import { AppCommonService } from 'src/app/shared/services/app-common.service';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild,ViewChildren,QueryList } from '@angular/core';
import { DropDownActionKey } from 'igniteui-angular/lib/drop-down/drop-down.common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { EnterpriseTranscriptionService } from '../../services/enterprise-transcription.service';


import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionStorageService } from '../../services/session-storage.service';
import { SetPasswordModalComponent } from '../../modals/set-password-modal/set-password-modal.component';
import { AuthorizationService } from '../../services/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordModalComponent } from '../../modals/change-password-modal/change-password-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WebSocketService } from '../../services/web-socket.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translate.service';
import { CommonFeatures } from 'src/app/UTILS/common-features.util';
import { Subscription } from 'rxjs';
import { GenericModalService } from '../../services/generic-modal.service';


export const LANGUAGES = [
  { name: 'English', value: 'en' },
  { name: 'French', value: 'fr' },
  { name: 'Spanish', value: 'fr' },
  { name: 'Arabic', value: 'fr' },
  { name: 'Bulgarian', value: 'fr' },
  { name: 'Chinese', value: 'fr' },
  { name: 'Croatain', value: 'fr' },
  { name: 'Denmark', value: 'fr' },
  { name: 'Finnish', value: 'fr' }
];

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      // ...
      state('open', style({
        width: '240px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        width: '0px',
        opacity: 1,
        backgroundColor: 'blue'
      })),
      transition('* => *', [
        animate('0.3s ease')
      ]),
    ]),
  ],
})
export class SideBarComponent implements OnInit, OnDestroy {
  langulages: any[] = LANGUAGES;
  nav = true;
  isDarkMode: boolean = true;
  currentTheme = 'dark';
  showUserMenu = false

  notification = false;
  show = false;
  paragraphs = [
    {
      id: 1,
      content: `1. There is a warning on Media Conversion Service due to network problems. This is a possible critical issue which needs attention. 5 jobs are delayed so far in the Media Conversion Service pipeline. See Less`,
      visible: false
    },
  ];
  currentUrl: any;
  currentRoute: any;
  userName: any;
  changePasswordRef: any;
  langSearch: string = '';
  isActive:boolean=false;
  routerEventSubscription: Subscription;
  @ViewChildren(RouterLink,{read:ElementRef}) sideMenuPNode: QueryList<ElementRef<HTMLDivElement>>

  constructor(
    public _translate: TranslateService,
    public enterpriseservice: EnterpriseTranscriptionService,
    private router: Router,
    private authorizationService: AuthorizationService,
    public appCommonService: AppCommonService,
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService,
    private webSocketService: WebSocketService,
    private TranslationService: TranslationService,
    private elementRef: ElementRef<HTMLElement>,
    private modalService:GenericModalService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
    .addSvgIcon(`theme-switch`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.themeSwitchIcon))
    .addSvgIcon(`close-circled`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.closeCircledIcon))
    .addSvgIcon(`side-project`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.sideProjectIcon))
    .addSvgIcon(`video-file`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.videoFileIcon))
    .addSvgIcon(`user-white`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.userWhiteIcon))
    .addSvgIcon(`side-help`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.sideHelpIcon))
    .addSvgIcon(`exit`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.exitIcon))
    .addSvgIcon(`password`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.passwordIcon))
    .addSvgIcon(`home`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.homeIcon))
    .addSvgIcon(`manage`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.manageIcon))
    .addSvgIcon(`pricing`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.pricingIcon))
    .addSvgIcon(`bell`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.bellIcon))
    .addSvgIcon(`hamburger`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.hamburgerIcon))
    .addSvgIcon(`search-icon-light`,this.domSanitizer.bypassSecurityTrustResourceUrl(this.appCommonService.searchIconLightIcon))
   
    this.routerEventSubscription = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((x) => {
      this.currentRoute = x.url
    });
   
  }
  ngOnDestroy(): void {
    if(this.routerEventSubscription) this.routerEventSubscription.unsubscribe()
    if(this.sideMenuPNode.toArray().length > 0) this.sideMenuPNode.toArray().forEach(x => x?.nativeElement?.removeAllListeners && x.nativeElement.removeAllListeners('click'))
  }

  ngOnInit(): void {

    document.documentElement.setAttribute("data-theme", this.getTheme());
    this.userName = this.appCommonService.getUserName();
  }
  onSelectLang(lang: string) {
    this.TranslationService.setLanguage(lang);
    this.isActive=false;
  }


  openChangePasswordModal() {
    this.nav = true
    if(this.dialog.getDialogById('change_password')) return
    const result = this.dialog.open(ChangePasswordModalComponent, {
      width: "400px",
      height: "350px",
      panelClass: "changePwd__custom-style",
      id:'change_password'
    });
    result.afterOpened().subscribe(res => this.nav = true)
    result.backdropClick().subscribe(_ => {
      // Close the dialog
      result.close();
    })
    result.afterClosed().subscribe((result: any) => { });
    this.router.events
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement?: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    if(!this.elementRef.nativeElement.contains(targetElement) && this.nav === false){
      this.nav = true
      this.showUserMenu = false
    }
  }
  clickedOutside(): void {
    this.notification = false;
    this.nav = true;
  }
  ngAfterViewInit() {
    const theme = localStorage.getItem('theme');
    if (theme == 'light') {
    }
    this.sideMenuPNode.toArray().forEach(x => x?.nativeElement?.addEventListener('click', () => this.nav = true))
  }
  closeNav() {
    this.nav = !this.nav;
    this.closebell();
    this.isActive=false;
    this.showUserMenu = false;
  }

  onlanguage(event:any){
    event.stopPropagation();
  }

  closebell() {
    this.notification = false;
  }
  openNav(event: any) {
    event.stopPropagation();
    this.nav = !this.nav;
    this.closebell();
    this.dialog.closeAll();
    this.showUserMenu = false;
  }
  bell(event: any) {
    event.stopPropagation();
    this.notification = !this.notification;
    this.dialog.closeAll();
    this.showUserMenu = false;
  }
  openUserMenu(event: any) {
    event.stopPropagation();
    this.showUserMenu = !this.showUserMenu;
    this.initialStateValues();
  }
  initialStateValues() {
    this.nav = false;
    this.notification = false;
  }
  changeTheme() {
    if (this.getTheme() == 'dark') {
      document.documentElement.setAttribute("data-theme", "light");
      this.setTheme('light');
      this.isDarkMode = false;
      document.querySelectorAll('img[src*=".svg"]').forEach((ele) => {
        let svgPath: any = '';
        svgPath = ele.attributes.getNamedItem('src')?.value ? ele.attributes.getNamedItem('src')?.value : '';
        console.log(svgPath);


      });
    } else {
      document.documentElement.setAttribute("data-theme", "dark");
      this.setTheme('dark');
      this.isDarkMode = true;
      document.querySelectorAll('img[src*=".svg"]').forEach((ele) => {
        let svgPath: any = '';
        svgPath = ele.attributes.getNamedItem('src')?.value ? ele.attributes.getNamedItem('src')?.value : '';

        console.log(svgPath);

      });
    }
  }
  setTheme(theme: string) {
    localStorage.setItem('theme', theme);
    this.appCommonService.themeToggle.next(theme);
  }
  getTheme(): string {
    return localStorage.getItem('theme') as string || 'dark';
  }

  async logout(closeDialog = true) {
    if(localStorage?.level_id && !(this.router.url.includes('captioning') || this.router.url.includes('enterprise-transcription'))){
      const value = await this.modalService.openDialog('Are you sure you want to logout?',`Please make sure to save you work open in a different tab.`,'Logout','Cancel').toPromise()
      if(value !== 'true') return
    }
    if (this.router.url.includes('captioning') || this.router.url.includes('enterprise-transcription')) {
      const successful = await this.router.navigate(['not-found'])
      if (successful) {
        if (closeDialog) this.dialog.closeAll();
        if (!this.isDarkMode) this.changeTheme();
        this.sessionStorage.clearSessionData();
        this.router.navigate(['/login']);
        this.webSocketService.close()
      }
      return
    }
    if (closeDialog) this.dialog.closeAll();
    if (!this.isDarkMode) this.changeTheme();
    this.sessionStorage.clearSessionData();

    this.router.navigate(['/login']);
    this.webSocketService.close()
  }
 
  onSearch(): void {
    this.langulages = LANGUAGES.filter(item => {
      return CommonFeatures.isSearch(item, this.langSearch);
    });
  }

  onFilesClick(): void {
    // remove saved filters on fresh load
    localStorage.removeItem('filesFilters');
  }
}
