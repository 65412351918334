<div class="flex-sap-bw">
    <h1 mat-dialog-title class="header">
        Add Payment </h1>
    <span class="close-icon" (click)="onDismiss()">
        <img src="../../../../assets/images/close-popup.svg"></span>
</div>
<div class="flex-column payment-block">

    <div class="input-filed flex-column">
        <mat-label class="input-payment">Payment From {{data?.accountName}}</mat-label>
        <div class="block-input">
           <input 
           [formControl]="payment"
           matInput
           class="currency-type"
           placeholder="1000.00"
           autocomplete="off"
            type="text"
       
            (keydown)="validateNumberField($event)"
            (input)="validateNumberField($event)"
            (paste)="$event.preventDefault()"
               
            >        

                <span class="type">USD ($)</span>
        </div>
      <!--------
             
    
    -->
        <mat-error *ngIf="payment.dirty && payment.errors">Please enter a valid number</mat-error>
    </div>
    <div class="input-filed flex-column">
        <mat-label class="input-payment">Received on</mat-label>
        <mat-form-field appearance="fill" class="date-payment">
            <input matInput [matDatepicker]="picker" [max]="today" [(ngModel)]="receivedDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon class="calendar" svgIcon="calendar" aria-hidden="false"
                aria-label="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="action-buttons">
            <div mat-dialog-actions align="end">
                <button mat-raised-button color="primary" mat-dialog-close class="btnOk">Cancel</button>
                <button mat-button (click)="onConfirm()" [disabled]="payment.invalid||!receivedDate">Add</button>
            </div>
        </div>
    </div>