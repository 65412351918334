import { AuthService } from 'src/app/shared/services/auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AppCommonService } from './app-common.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private appCommonService:AppCommonService) { }

  /**
   * check user login or not if login than redirect on home page
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.auth.isAuthenticated()) {
      return true;
    } else {
      this.appCommonService.redirectToAccessibleMenuItem();
      return false;
    }
  }

}
