export class CommonFeatures {
  static isSearch(item: any, search: string) {
    for (var i in item) {
      if (
        item[i] != null &&
        item[i] != undefined &&
        item[i]
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      ) {
        return true;
      }
    }
    return false;
  }
  static isSearchName(item: any, search: string, name: string) {
    if (
      item[name] != null &&
      item[name] != undefined &&
      item[name]
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) > -1
    ) {
      return true;
    }
    return false;
  }
}