import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';

import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { JobItem } from 'src/app/pages/files/new-job/new-job.component';
import { JobsService } from './jobs.service';
import { AppCommonService } from './app-common.service';
import { FileProgressSnackBarComponent } from '../modals/file-progress-snack-bar/file-progress-snack-bar.component';

export enum FileQueueStatus {
  Pending,
  Success,
  Error,
  Progress
}

export class FileQueueObject {
  job: JobItem;
  public status: FileQueueStatus = FileQueueStatus.Pending;
  public progress: number = 0;
  public request: Subscription;
  public response: HttpResponse<any> | HttpErrorResponse;

  constructor(job: JobItem) {
    this.job = job;
  }

  // actions
  public upload = () => { /* set in service */ };
  public cancel = () => { /* set in service */ };
  public remove = () => { /* set in service */ };

  // statuses
  public isPending = () => this.status === FileQueueStatus.Pending;
  public isSuccess = () => this.status === FileQueueStatus.Success;
  public isError = () => this.status === FileQueueStatus.Error;
  public inProgress = () => this.status === FileQueueStatus.Progress;
  public isUploadable = () => this.status === FileQueueStatus.Pending || this.status === FileQueueStatus.Error;

}

@Injectable()
export class FileUploadService {
  private _queue: BehaviorSubject<FileQueueObject[]>;
  public _files: FileQueueObject[] = [];
  public jobStatusSubscription: Subscription;
  public newJobUploaded: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private jobsService: JobsService, private commonService: AppCommonService) {
    this._queue = <BehaviorSubject<FileQueueObject[]>>new BehaviorSubject(this._files);
    this.newJobUploaded= new BehaviorSubject<boolean>(false);
  }

  // the queue
  public get queue() {
    return this._queue.asObservable();
  }

  // public events
  public onCompleteItem(queueObj: FileQueueObject, response: any): any {
    return { queueObj, response };
  }

  // public functions
  public addToQueue(data: JobItem[]) {
    // add file to the queue
    for (let i = 0; i < data.length; i++) {
      this._addToQueue(data[i]);
    }
  }

  public clearQueue() {
    // clear the queue
    this._files = [];
    this._queue.next(this._files);
  }

  // public uploadAll() {
  //   // upload all except already successfully or in progress
  //   _.each(this._files, (queueObj: FileQueueObject) => {
  //     if (queueObj.isUploadable()) {
  //       this._upload(queueObj);
  //     }
  //   });
  // }

  // private functions
  private _addToQueue(file: any) {
    const queueObj = new FileQueueObject(file);

    // set the individual object events
    queueObj.upload = () => this._upload(queueObj);
    queueObj.remove = () => this._removeFromQueue(queueObj);
    queueObj.cancel = () => this._cancel(queueObj);

    // push to the queue
    this._files.push(queueObj);
    this._queue.next(this._files);
    //starts upload
    queueObj.upload();
  }

  private _removeFromQueue(queueObj: FileQueueObject) {
    let index = this._files.indexOf(queueObj);
    if (index > -1) {
      this._files.splice(index, 1);
    }
    if (this._files.length == 0) {
      this.commonService.dismissActiveSnackBar();
    }
    this._queue.next(this._files);
  }

  private _upload(queueObj: FileQueueObject) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'multipart/form-data');
    // upload file and report progress
    const req = new HttpRequest('PUT', queueObj.job.URL, queueObj.job.File, {
      reportProgress: true,
      headers: headers
    });

    // upload file and report progress
    queueObj.request = this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this._uploadProgress(queueObj, event);
        } else if (event instanceof HttpResponse) {
          // call job initiator api
          queueObj.job.Status='Processing';
          this.callJobInitiator(queueObj);
          setTimeout(() => {
            this._uploadComplete(queueObj, event);
          }, 5000);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this._uploadFailed(queueObj, err);
        } else {
          // The backend returned an unsuccessful response code.
          this._uploadFailed(queueObj, err);
        }
      }
    );

    return queueObj;
  }

  private _cancel(queueObj: FileQueueObject) {
    // update the FileQueueObject as cancelled
    queueObj.request.unsubscribe();
    queueObj.progress = 0;
    queueObj.status = FileQueueStatus.Pending;
    this._queue.next(this._files);
  }

  private _uploadProgress(queueObj: FileQueueObject, event: any) {
    // update the FileQueueObject with the current progress
    const progress = Math.round(100 * event.loaded / event.total);
    queueObj.progress = progress;
    queueObj.status = FileQueueStatus.Progress;
    this._queue.next(this._files);
  }

  private async _uploadComplete(queueObj: FileQueueObject, response: HttpResponse<any>) {
    // update the FileQueueObject as completed
    queueObj.progress = 100;
    queueObj.status = FileQueueStatus.Success;
    queueObj.response = response;
    this._queue.next(this._files);
    this.onCompleteItem(queueObj, response.body);
    // check no file is inprogress status
    if (!this._files.some(x => x.inProgress())) {
      this.commonService.showCustomSnackBar(FileProgressSnackBarComponent, { type: 'allCompleted' }, 30000);
      this.jobStatusSubscription = interval(1000).subscribe(x => {
        this.updateAllJobStatus();
      });
    }
  }

  private _uploadFailed(queueObj: FileQueueObject, response: HttpErrorResponse) {
    // update the FileQueueObject as errored
    queueObj.progress = 0;
    queueObj.status = FileQueueStatus.Error;
    queueObj.response = response;
    this._queue.next(this._files);
  }

  public async updateAllJobStatus(): Promise<void> {
    let jobIds = this._files.filter(x => x.job.Pop != 1).map(x => +x.job.Job_Id);
    if (jobIds && jobIds.length > 0) {
      const jobsData = {
        "job_ids": jobIds,
        "scope": "job_status"
      }
      let jobStatusRes: any = await this.jobsService.getJobStatusByJobIds(jobsData);
      if (jobStatusRes && jobStatusRes.statusCode == 200 && jobStatusRes.data) {
        jobStatusRes.data.forEach((element: any) => {
          let item = this._files.find(x => x.job.Job_Id == element.job_id);
          if (item) {
            item.job.Duration = element.duration;
            item.job.Status = element.job_status;
            item.job.Pop = element.pop;
            item.job.Open_Job = element.open_job;
            item.job.Level_Id = element.level_id;
            item.job.Sub_Level_Id = element.sub_level_no;
            item.job.Navigate_To=element.navigate_to;
          }
        });
        this._queue.next(this._files);
        // all files status duplicate than stop calling status
        if (jobStatusRes.data.some((x: any) => x.job_status == "Duplicate File")) {
          this.stopJobStatusAPI();
        }
      }
    }else{
      this.stopJobStatusAPI();
    }
  }

  private stopJobStatusAPI(): void {
    //stop job status api
    this.jobStatusSubscription.unsubscribe();
    this.newJobUploaded.next(true);
  }

  public async callJobInitiator(queueObj: FileQueueObject) {
    let data = {
      "job_id": queueObj.job.Job_Id
    }
    try {
      this.jobsService.jobInitiator(data);
    } catch (error) {

    }
  }

}
