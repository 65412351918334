import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './../core/http-interceptor/http-interceptor.service';
import { GenericModalComponent } from './modals/generic-modal/generic-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VideojsComponent } from './components/videojs/videojs.component';
import { ForgotPasswordModalComponent } from './modals/forgot-password-modal/forgot-password-modal.component';
import { SelectLanguageComponent } from './modals/select-language/select-language.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { PlayerSeekbarComponent } from './components/player-seekbar/player-seekbar.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AddPaymentComponent } from './modals/add-payment/add-payment.component';
import { AdminAddUserComponent } from './modals/admin-add-user/admin-add-user.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EditTATComponent } from './modals/edit-tat/edit-tat.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EnterpriseTranscriptionService } from './services/enterprise-transcription.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmEmailComponent } from './modals/confirm-email/confirm-email.component';
import { GenericSnackbarComponent } from './modals/generic-snackbar/generic-snackbar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FindTatPricePipe } from './pipe/find-tat-price.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ManualPricingComponent } from './components/manual-pricing/manual-pricing.component';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImportTranscriptComponent } from './modals/import-transcript/import-transcript.component';
import { NumbersOnlyDirective } from './pipe/numbers-only.directive';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { AccountGeneralDetailsComponent } from './components/account-general-details/account-general-details.component';
import { AccountPmaRoleComponent } from './components/account-pma-role/account-pma-role.component';
import { AccountWorkflowPriceComponent } from './components/account-workflow-price/account-workflow-price.component';
import { punctuationPipe } from './pipe/punctuations.pipe';
import { OtpInputComponent } from './modals/forgot-password-modal/otp-input/otp-input.component';
import { SetPasswordModalComponent } from './modals/set-password-modal/set-password-modal.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { TransHeaderComponent } from './components/header/trans-header/trans-header.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewJobOptionsComponent } from './modals/new-job-options/new-job-options.component';
import { GuidlinesPopupComponent } from './modals/guidlines-popup/guidlines-popup.component';
import { KeyboardShortcutsModalComponent } from './modals/keyboard-shortcuts-modal/keyboard-shortcuts-modal.component';
import { HightlightDirective } from './directives/hightlight.directive';

import { FileProgressSnackBarComponent } from './modals/file-progress-snack-bar/file-progress-snack-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddSpacePipe, HighlightSearchPipe, musicFormatSpace, SafeHtmlPipe, ShowVolume } from './pipe/highlight-search.pipe';
import { TCRTimecodeComponent } from './components/header/tcr-timecode/tcr-timecode.component';
import { GetDurationPipe } from './pipe/get-duration.pipe';
import { NgChartsModule } from 'ng2-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { HighlightSpellCheckPipe } from './pipe/highlight-spell-check.pipe';
import { IgxMaskModule, IgxTextHighlightModule } from 'igniteui-angular';
import { CustomeMatSlideToggleComponent } from './components/custome-mat-slide-toggle/custome-mat-slide-toggle.component';
import { JobInfoModalComponent } from './components/job-info-modal/job-info-modal.component';
import {MatBadgeModule} from '@angular/material/badge';
import { AddSpectrographCaptionComponent } from './modals/add-spectrograph-caption/add-spectrograph-caption.component';
import { PermissionsNotFoundComponent } from './components/permissions-not-found/permissions-not-found.component';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { LoaderComponent } from './components/loader/loader.component';
import { GetValueAsMillisecondsPipe } from './pipe/get-value-as-milliseconds.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CallbackPipe } from './pipe/call-back.pipe';
import { DoubleClickDirective } from './directives/double-click-directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddClassPipe } from './pipe/add-class.pipe';
import { GetValueAsTimeStampPipe } from './pipe/get-value-as-time-stamp.pipe';
import { VocabularyPopupComponent } from './modals/vocabulary-popup/vocabulary-popup.component';
import { StyleguidePopupComponent } from './modals/styleguide-popup/styleguide-popup.component';
import { InViewDirective } from './directives/in-view.directive';
import { ShortcutConversionPipe } from './pipe/shortcut-conversion.pipe';
import { DebounceSearchDirective } from './directives/debounce-search.directive';
import { RemoveEndSpecialCharacters } from './pipe/remove-end-special-charecters.pipe';
import { TimeConsumptionPipe } from './pipe/time-consumption.pipe';
import { WordAccuracyPopupComponent } from './modals/word-accuracy-popup/word-accuracy-popup.component';
import { TimezonePipe } from './pipe/timezone.pipe';
import { SplitjobPopupComponent } from './splitjob-popup/splitjob-popup.component';
import { DownloadOutputModalComponent } from './modals/download-output-modal/download-output-modal.component';

@NgModule({
  declarations: [
    SideBarComponent,
    GenericModalComponent,
    DeleteAccountModalComponent,
    VideojsComponent,
    SelectLanguageComponent,
    PlayerSeekbarComponent,
    ForgotPasswordModalComponent,
    SelectLanguageComponent,
    AddPaymentComponent,
    AdminAddUserComponent,
    EditTATComponent,
    ConfirmEmailComponent,
    GenericSnackbarComponent,
    FindTatPricePipe,
    PaginatorComponent,
    ManualPricingComponent,
    ImportTranscriptComponent,
    NumbersOnlyDirective,
    SearchFilterPipe,
    AccountGeneralDetailsComponent,
    AccountPmaRoleComponent,
    AccountWorkflowPriceComponent,
    punctuationPipe,
    OtpInputComponent,
    SetPasswordModalComponent,
    ChangePasswordModalComponent,
    TransHeaderComponent,
    NewJobOptionsComponent,
    GuidlinesPopupComponent,
    KeyboardShortcutsModalComponent,
    HightlightDirective,
    FileProgressSnackBarComponent,
    ShowVolume,
    GetDurationPipe,
    HighlightSpellCheckPipe,
    HighlightSearchPipe,
    TCRTimecodeComponent,
    CustomeMatSlideToggleComponent,
    JobInfoModalComponent,
    AddSpectrographCaptionComponent,
    PermissionsNotFoundComponent,
    AddSpacePipe,
    SafeHtmlPipe,
    //HightlightDirective,
    musicFormatSpace,
    LoaderComponent,
    GetValueAsMillisecondsPipe,
    ClickOutsideDirective,
    CallbackPipe,
    DoubleClickDirective,
    AddClassPipe,
    GetValueAsTimeStampPipe,
    VocabularyPopupComponent,
    StyleguidePopupComponent,
    InViewDirective,
    ShortcutConversionPipe,
    DebounceSearchDirective,
    RemoveEndSpecialCharacters,
    TimeConsumptionPipe,
    WordAccuracyPopupComponent,
    TimezonePipe,
    SplitjobPopupComponent,
    DownloadOutputModalComponent
  ],

  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    DragDropModule,
    MatTableModule,
    MatListModule,
    VirtualScrollerModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgChartsModule,
    MatExpansionModule,
    MatTabsModule,
    IgxMaskModule,
    MatBadgeModule,
    IgxTextHighlightModule,
    MatSortModule,
    NgxOrgChartModule,
    // IgxTextHighlightModule,
    MatTreeModule,
    // ResizeObserverModule
    MatChipsModule,
    // NgxOrgChartModul
    MatAutocompleteModule,
    OrganizationChartModule,
    ScrollingModule
  ],

  exports: [
    RouterModule,
    CommonModule,
    SideBarComponent,
    GenericModalComponent,
    DeleteAccountModalComponent,
    SelectLanguageComponent,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    VideojsComponent,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatMenuModule,
    HttpClientModule,
    MatRadioModule,
    MatButtonToggleModule,
    DragDropModule,
    FindTatPricePipe,
    PaginatorComponent,
    GenericSnackbarComponent,
    ManualPricingComponent,
    MatTableModule,
    MatListModule,
    NumbersOnlyDirective,
    SearchFilterPipe,
    AccountGeneralDetailsComponent,
    AccountPmaRoleComponent,
    AccountWorkflowPriceComponent,
    punctuationPipe,
    VirtualScrollerModule,
    TransHeaderComponent,
    MatTooltipModule,
    MatProgressBarModule,
    GetDurationPipe,
    HightlightDirective,
    NgChartsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTabsModule,
    HighlightSpellCheckPipe,
    HighlightSearchPipe,
    CustomeMatSlideToggleComponent,
    MatBadgeModule,
    IgxTextHighlightModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    NgxOrgChartModule,
    // IgxTextHighlightModule,
    MatTreeModule,
    // ResizeObserverModule
    MatChipsModule,
    // NgxOrgChartModul
    MatAutocompleteModule,
    OrganizationChartModule,
    IgxMaskModule,
    AddSpacePipe,
    SafeHtmlPipe,
    //HightlightDirective,
    musicFormatSpace,
    LoaderComponent,
    GetValueAsMillisecondsPipe,
    CallbackPipe,
    ScrollingModule,
    AddClassPipe,
    GetValueAsTimeStampPipe,
    InViewDirective,
    ShortcutConversionPipe,
    DebounceSearchDirective,
    RemoveEndSpecialCharacters,
    TimeConsumptionPipe,
    TimezonePipe
  ],

  providers: [EnterpriseTranscriptionService, NumbersOnlyDirective],

  entryComponents: [GenericModalComponent, SelectLanguageComponent , TCRTimecodeComponent, AddSpectrographCaptionComponent],
})
export class SharedModule { }
