import { AppCommonService } from './shared/services/app-common.service';
import { ApiService } from './shared/services/api.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';

import { HttpClientModule } from '@angular/common/http';

import { AuthService } from './shared/services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './core/http-interceptor/http-interceptor.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AppConfig, APP_CONFIG } from './app.config';
import { LoaderInterceptor } from './shared/services/loader.intercepter';
import { FileUploadService } from './shared/services/file-upload.service';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WebSocketService } from './shared/services/web-socket.service';


const config: MatDialogConfig = {
   backdropClass: 'custom-backdrop-overlay',
   hasBackdrop: true
}

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TranslateModule.forRoot()
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true,
  },
    AppCommonService,
    AuthService,
    FileUploadService,
    ApiService,
    WebSocketService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: config }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
