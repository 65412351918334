export const locale = {
    lang: 'fr',
    data: {
        dashboard:{
            pma_dashboard:{
                title:'Home fr',
                consumptions:{
                    title:'Consumptions'
                },
                job_status:{},
                resources_status:{},  
                account_balance:{
                    total:''
                }
            }
        },
        help:{
            title:'Help FR'
        }
    }
}