import { Component, Inject, HostListener} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, GenericModalService } from '../../services/generic-modal.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {
  isConfirm: boolean = true;
  largeContent: boolean = false;
  constructor(public dialogRef: MatDialogRef<GenericModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              public modalService: GenericModalService) {
                if (data) {
                  this.largeContent = typeof data.body === 'object' ? true : false;
                }
               }

  @HostListener('document:keydown.arrowleft', ['$event'])
  @HostListener('document:keydown.arrowright', ['$event'])
  @HostListener('document:keydown.enter', ['$event'])
  listenArrows(event: any) {
    event.stopPropagation();
    if (event.keyCode === 37) {
      this.isConfirm = true;
    } else if (event.keyCode === 39) {
      this.isConfirm = false;
    } else {
      this.isConfirm ? this.onConfirm() : this.onDismiss();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close("true");
    this.modalService.genericPopup = false;
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close('');
    this.modalService.genericPopup = false;
  }
}
