<div id="custom-snack-bar-container">
  <div class="custom-snack-bar-row">
    <ng-container [ngSwitch]="data.type">
      <ng-container *ngSwitchCase="'uploading'">
        <span class="example-snack-bar-message">{{ uploadingFilesText }} </span>
        <mat-progress-spinner class="custom-spinner" [diameter]="25" [value]="currentFileProgress" mode="determinate">
        </mat-progress-spinner>
      </ng-container>
      <ng-container *ngSwitchCase="'allCompleted'">
        <!-- <mat-icon>check</mat-icon> -->
        <mat-icon class="checkmark_skyblue" svgIcon="checkmark_skyblue"
        aria-hidden="false" aria-label="checkmark_skyblue"></mat-icon>
        <span class="example-snack-bar-message"> All Files Uploaded! </span>
        <mat-progress-spinner class="custom-spinner" [diameter]="25" [value]="currentFileProgress" mode="determinate">
        </mat-progress-spinner>
      </ng-container>
    </ng-container>
  </div>

  <mat-progress-bar id="progress-bar" mode="determinate" [value]="allFilesProgress" strokeWidth="25"></mat-progress-bar>
</div>