import { ChangeDetectorRef, Component } from '@angular/core';
import { delay, first, tap } from 'rxjs/operators';
import { AppCommonService } from './shared/services/app-common.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EnterpriseTranscriptionService } from './shared/services/enterprise-transcription.service';
import './shared/angular-formcontrols-extension';
import { Observable, Subject, asyncScheduler } from 'rxjs';
import { observeOn, distinctUntilChanged } from "rxjs/operators";
import { TranslationService } from './shared/services/translate.service';
import { locale as en } from '../assets/i18n/en';
import { locale as fr } from '../assets/i18n/fr';
import videojs from 'video.js';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'trance-portal';

  svgInit: boolean = false;

   // subscriber for checking flag for show/hide loader
   isLoading: Observable<boolean> = this.appCommanService.isLoading.asObservable().pipe(distinctUntilChanged(),observeOn(asyncScheduler));
  constructor(public appCommanService: AppCommonService,
    public enterpriseservice:EnterpriseTranscriptionService, 
    private iconRegistry: MatIconRegistry, 
    private sanitizer: DomSanitizer,
    private metaService: Meta, 
    private translationService: TranslationService) {}

  ngOnInit(): void {
    // this.metaService.addTag({
    //     httpEquiv: 'Cache-Control',
    //     content: 'no-cache, no-store, must-revalidate',
    // });
    // this.metaService.addTag({
    //     httpEquiv: 'Pragma',
    //     content: 'no-cache',
    // });
    // this.metaService.addTag({
    //     httpEquiv: 'Expires',
    //     content: '0',
    // });
    this.translationService.loadTranslations(en,fr);
    (<any>window).videojs = videojs;
    this.getSvgJson();
  }

  getSvgJson() {
    this.enterpriseservice.svgCreation().subscribe((response: any) => {
     response.data.forEach((item: any, index:number) => {
       for (let svg in item) {
        //  console.log(item[svg]);
        //  console.log(svg);
         this.iconRegistry.addSvgIcon(svg, this.sanitizer.bypassSecurityTrustResourceUrl(item[svg]));
         if(index == response.data.length - 1) {
           this.svgInit = true;
         }
       }
     });
   });

  }

 
}
