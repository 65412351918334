import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppCommonService } from './app-common.service';

/**
 * The purpose of this intercept is to track the status of http requests for the purposes of showing/hiding a loader
 * Refer this https://firstclassjs.com/display-a-loader-on-every-http-request-using-interceptor-in-angular-7.
 */

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  // counter for total request
  requestCounter = 0;
  constructor(public appCommonService: AppCommonService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let endPoint = req.url.split('/').pop() || '';
    if(endPoint.includes('?')) {
      endPoint = endPoint.split('?')[0];   // /dev/sound-cues?project=26
    }
    if ( !this.appCommonService.ignoreLoaders.includes(endPoint) && (!this.appCommonService.ignoreUpdateLoaders.includes(endPoint) || req.method.toLowerCase() !== 'put') && (!this.appCommonService.ignoreGetLoaders.includes(endPoint) || req.method.toLowerCase() !== 'get') && (endPoint !== 'caption-updates' || req?.body?.operation.toLowerCase() !== 'bookmark_index') && !endPoint.includes('.svg')) {
      if(!req.urlWithParams.includes('?hideloader=1')){
        this.requestCounter += 1;
          // show loader on  request being initialized
          this.appCommonService.show();
      }
    }
    return next.handle(req).pipe(
      finalize(() => {
        if (!this.appCommonService.ignoreLoaders.includes(endPoint) && (!this.appCommonService.ignoreUpdateLoaders.includes(endPoint) || req.method.toLowerCase() !== 'put') && (!this.appCommonService.ignoreGetLoaders.includes(endPoint) || req.method.toLowerCase() !== 'get') && (endPoint !== 'caption-updates' || req?.body?.operation.toLowerCase() !== 'bookmark_index') && !endPoint.includes('.svg')) {
          if (!req.urlWithParams.includes('?hideloader=1')) {
            // minus counter after request finalize
            this.requestCounter -= 1;
          }
        }

        // check if no request pending and hide loader
        if (this.requestCounter === 0) {
          this.appCommonService.hide();
        }
      })
    );
  }
}
