<div class="head flex-sap-bw">
  <h1 mat-dialog-title class="flex-c">
    <mat-icon *ngIf="data.body.showTitleIcon" class="icon-alert" svgIcon="icon-alert" aria-hidden="false"
      aria-label="icon-alert"></mat-icon>
    {{data.title}}
  </h1>
  <span class="close-icon generic__modal" (click)="onDismiss()">
    <mat-icon class="close-circled" svgIcon="close-circled" aria-hidden="false" aria-label="close-circled"></mat-icon>
  </span>

</div>
<div mat-dialog-content>
  <p>{{data.body.line1}}</p>
  <p *ngIf="data.body.line2">{{data.body.line2}}</p>
  <p *ngIf="data.body.line3">{{data.body.line3}}</p>
</div>

<div mat-dialog-actions class="generic__modal--btns">
  <button mat-raised-button class="btnOk grey-btn" (click)="onConfirm()">{{data.ok}}</button>
  <button *ngIf="data?.Cancel" mat-button mat-dialog-close class="btnCancel org-btn"
    (click)="onDismiss()">{{data.Cancel}}</button>
</div>