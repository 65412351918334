import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { ForgotPassword } from '../models/forgot-password.model';
import { ApiService } from './api.service';
import { SessionStorageService } from './session-storage.service';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root',
})

export class AuthorizationService {
  public auth_details = <any>[];
  config: any;
  baseUrl = environment.baseUrl;
  enteredUsername: string;
  defaultPassword: string;
  constructor(public httpClient: HttpClient,
    public sessionStorageService: SessionStorageService,
    private router: Router,
    private api: ApiService,
    private webSocketService: WebSocketService
    ) {
    this.config = AppConfig;
  }

  getAuthDetails() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const headers = new HttpHeaders();
    if (token) { headers.set('Authorization', token); }
    headers.set('Content-type', 'application/json');
    this.httpClient.get(`${this.baseUrl}${this.config.USER}?username=${username}`, { headers }).toPromise()
      .then((response) => {
        this.auth_details.push(response);
        this.sessionStorageService.setSessionData('auth_key', this.auth_details[0].data.Auth_key);
        this.sessionStorageService.setSessionData('auth_Id', this.auth_details[0].data.id);
        this.sessionStorageService.setSessionData('user_role', this.auth_details[0].data.role);
        this.sessionStorageService.setSessionData('parent_userid', this.auth_details[0].data.parent_userid);

        //Socket service data
        // this.socketService.getData();
        if (this.auth_details[0].data.role === 4 && this.auth_details[0].data.parent_userid === 0) {
          this.router.navigate(['/pages']);
        } else {
          this.router.navigate(['/pages/enterprise-transcription']);
        }
      })
    return this.auth_details;
  }

  async initialPasswordChange(payload: any) {
    try {      
      const requireAuth = payload.scope == "CHANGE_PASSWORD" ||  payload.scope == "CONFIRM_USER_CHANGE_PASSWORD" ? true : false;
      const res = await (<any>this.api.put(`${this.config.LOGIN}`, payload, requireAuth)).toPromise(); 
      return res;
    } catch (e: any) {      
      return Promise.reject(e?.error)
    }
  }

  async login(username: string, password: string, app: number = 1) {
    this.enteredUsername = username;
    this.defaultPassword = password;

    let data;
    data = { username, password, app};
    try {
      const res = await (<any>this.api.post(this.config.LOGIN, data, false ).toPromise());
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
    // let data;
    // data = { username, password };
    // return this.httpClient.post(`${this.baseUrl}${this.config.LOGIN}`, {}, { params: data });
  }
  /**
   * API to send/Confirm the OTP and reset password accourding to the `scope`
   * @param  {ForgotPassword.Options} Options
   */
  async resetPasswordAndConfirm(Options: ForgotPassword.Options) {
    try {
      const res = await (<any>this.api.patch(this.config.LOGIN, {...Options}, false ).toPromise());
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  saveResetpassword(uname: string, newpwd: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    return this.httpClient.post(`${this.baseUrl}${this.config.FORGOT_PASSWORD}`, { username: uname, password: newpwd }, {
      headers: header,
      observe: 'response',
    });
  }

  resetPassword(username: any, sendcode: any, confirmationCode?: any, newPassword?: any) {
    let data;
    data = {
      username,
      sendcode: sendcode ? true : '',
      confirmationCode,
      newPassword
    };

    if (!sendcode) {
      data.confirmationCode = confirmationCode;
      data.newPassword = newPassword;
    }

    return this.httpClient.post(`${this.baseUrl}${this.config.FORGOT_PASSWORD}`, {}, { params: data });
  }

  /**
   * Make the API call to refresh the user's token and update the stored token with the new token we get back.
   */
  async refreshToken() {
    console.count('refreshToken')
    // const authToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const username = localStorage.getItem('username');
    // make a request to get the new (refreshed) token
    try {
      const res = await (<any> this.api.post(`${this.config.REFRESH_TOKEN}?refresh_token=${refreshToken}&username=${username}`, false).toPromise());
      if (!res) {
        throw Error;
      }
      const idToken:any = res.AuthenticationResult.AccessToken;
      this.sessionStorageService.setSessionData('token', idToken);
      // this.sessionStorageService.setSessionData('result', JSON.stringify(res));
      this.sessionStorageService.setSessionData('accessToken', res.AuthenticationResult.AccessToken);
      let date: any =  Date.now();
      let expirySeconds = parseInt(res.AuthenticationResult.ExpiresIn) - 100;
      date = date + (expirySeconds * 1000)
      this.sessionStorageService.setSessionData('expiryTime', date);
    } catch (e) {
      // token refresh failed, sign them out
      console.error('refreshToken error',e)
      await this.logout();
      throw e;
    }
  }

  /**
   * Removes the auth token from local storage and navigates the user to root.
   */
  async logout() {
    window.localStorage.removeItem('token');
    this.webSocketService.close()
    this.router.navigate(['/login']);
  }
}
