import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');
export const AppConfig = {
  FORGOT_PASSWORD: 'forgot-password',
  USER: 'user',
  LOGIN: 'login',
  REFRESH_TOKEN: 'refresh-token',
  CAPTURE_USER_ACTIVITY:'user-activity',
  DASHBOARD: {
   DASHBOARD_JOBS_STATUS :'dashboard-jobs-status',
   PMA_DASHBOARD:'pma-dashboard'
  },
  ADMIN_MANAGE: {
    MANAGE_ROLE: 'roles',
    MANAGE_USER: 'users',
    TEAM_MANAGE: 'manage-team',
    GETALL_PERMISSION_LIST: 'list-permissions',
    TEAMS_ENDPOINT: 'manage-team',
    LIST_ACCOUNT_DETAILS: 'manage-account',
    PROJECT_MANAGE: 'account-projects',
    GET_ASR_DATA: 'manage-asr-definition',
    USERS_STATUS_COUNT:'users-status-count',
    ACCOUNT_PROJECT_COUNT:'account-project-count '
  },
  KEYBOARD_SHORTCUTS: {
    GET_ALL_KEYBOARD_SHORTCUTS: 'captioncontent',
  },
  CAPTION: {
    GET_ALL_CAPTIONS: 'captioncontent',
  },
  ENTERPRISE_TRANSCRIPTION: {
    GET_TRANSCRIPTION_DATA: 'transcriptioncontent',
    SAVE_TRANSCRIPTION_DATA: 'updatejobstatus',
    GET_UPDATE_TRANSCRIPTION_DATA: 'transcriptions',
    DELETE_UPDATE_SPEAKER_DATA: 'transcription/section',
    BOOK_MARKS: 'bookmark',
    LOAD_TRANSCRIPTION: 'load-transcriptions',
    UPDATE_TRANSCRIPTION: 'transcription_updates',
    DOWNLOAD_TRANSCRIPTION: 'download_transcript'
  },
  PROJECT_CREATION_PROCESS: {
    POST_GLOBAL_PRESET: 'manage-globalpreset',
    GET_GLOBAL_PRESET: 'manage-globalpreset',
    DELETE_PRESET: 'manage-globalpreset',
  },
  SERVICE_PRICING: {
    MANAGE_TATS: 'managetat',
    GET_MANUAL_SERVICE_PRICING: 'service-pricing',
  },
  WORKFLOW_PRICING: {
    ACCOUNT_PRICES: 'account-workflow-prices',
    UPDATE_ACCOUNT_PRICES: 'account-workflow-prices',
    LIST_ACCOUNT_DETAILS: 'manage-account',
  },
  PROJECT_CREATION_STEPS: {
    ACCOUNT_PROJECT: 'account-projects',
    PROJECT_WORKFLOW: 'projectworkflow',
    TRANSLATION_LANGUAGES: 'project-languages',
    POST_ASRDATA: 'asr-vendor',
    GLOBAL_PRESETS: 'global-presets',
    ACCOUNT_PRESETS: 'account-presets',
    PROJECT_OUTPUT_SETTINGS: 'project-output-settings',
    MANAGE_TAT: 'managetat',
    PROJECT_TAT_SETTINGS: 'project-tat-settings',
    EMAIL_NOTIFICATIONS: 'manage-project-emails',
    PERFORMANCE_LEVEL: 'performance-level',
    PROJECT_META_D: 'project-metadata',
    PROJECT_META_DATA: 'project-metadata-document',
    //PROJECT_META_DATA_TEXT: 'project-metadata-text',
    PROJECT_OTHER_SETTINGS: 'project-archive-policy',
    PROJECT_USERS: 'project-users',
    MANAGE_USER: 'users',
    MANAGE_TEAM: 'manage-team',
    PROJECT_HEIRARCHY: 'project-heirarchy',
    PROJECT_ASR_SETUP: 'project-asr-setup',
    ACCOUNT_ROLES: 'account-roles',
    DUPLICATE_ACCOUNT_PROJECTS: 'duplicate-account-projects'
  },
  ACCOUNT_CREATION: {
    LOGO: 'manage-account/logo',
    GENERAL_DETAILS: 'manage-account',
    PMA_DETAILS: 'manage-account/pma-users',
    ALL_ACCOUNTS: 'manage-account',
    ACCOUNT_PRICES: 'account-workflow-prices',
    MANAGE_ACCOUNT_TATS: 'account_tat_settings',
    DUPLICATION_ACCOUNT: 'manage-account/copy',
    ACCOUNT_PAYMENTS: 'account-payments',
    ACCOUNT_SETTINGS:'account-settings',
  },
  CAPTIONING: {
    SETTINGS_KEYBOARD: 'user-keyboard-shortcuts',
    GET_CAPTIONS: 'captions',
    USER_FILES: 'user-files',
    CAPTION_PRESETS:'caption/presets',
    UPDATE_CAPTIONS: 'caption-list',
    LANGUAGES: 'project-languages',
    MERGE_CAPTIONS: 'caption-updates',
    CAPTION_VALIDATE:'caption-validate',
    TRANSLATE: 'translate',
    MANAGE_WORKFLOW_LEVEL:'manage-workflow-level',
    DELIVER_TRANSCRIPTIONS:'deliver_transcriptions',
    AFC_CONVERTER:'afc-initiator',
    DELIVER_CAPTIONS:'deliver_captions',
    SPECTROGRAM: 'spectrogram',
    BOOK_MARKS: 'caption_bookmark',
    DOWNLOAD_CAPTIONS: 'download_captions',
    IMPORT_CAPTIONS:'import_captions'
  },
  NOTIFICATION: {
    SETTINGS: 'notify-settings',
  },

  PLAYERPREFERENCES: {
    FETCHPLAYERPREFERENCESVALUES: 'user-player-preferences',
  },
  PROJECT_NOTIFICATION: {
    NOTIFICATIONS: 'project-notifications',
  },
  PEDAL_CALIBRATION: {
    CALIBRATION: 'pedal-calibrations',
  },
  SOUND_CUES: {
    SOUND_CUES: 'sound-cues',
  },
  JOBS: {
    MANAGE_PROJECTS: 'account-projects',
    PROJECT_LANGUAGES: 'project-languages',
    ACCOUNT_PRESETS: 'account-presets',
    PROJECT_TAT_SETTINGS: 'project-tat-settings',
    ACCOUNT_USERS_TEAMS: 'account-users-teams',
    CREATE_JOB: 'create-job',
    JOB_STATUS: 'jobs-status',
    MANAGE_JOB: 'manage-job?',
    LISTING_FILES: 'list-files',
    FILE_ASSIGNMENT: 'file-assignments',
    JOB_INITIATOR:'job-initiator',
    OPEN_JOB: 'open-file',
    LISTING_ASSIGNMENTS: 'file-assignments-list',
    FILE_PROGRESS:'file-progress',
    WORK_ITEM_OUTPUTS:'work-item-outputs',
    CONSUMPTION_LIST: 'consumptions',
    PMA_DASHBOARD: 'pma-dashboard',
    ACCOUNT_TRANSACTIONS:'account-transactions',
    WORK_ITEM_PARTS:"work-item-parts"
  }
};
