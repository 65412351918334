import { AfterViewInit, Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[numbersOnly]'
})
export class NumbersOnlyDirective implements AfterViewInit{

	/**
	* @property Number Of Decimal Digits.
	* @default 0
	*/
   @Input('digits') digits: number = 0;

	/**
	 * @property Number Of Decimal Digits.
	 * @default 0
	 */
	@Input('decimals') decimals: number = 0;
	previousValue: string;
	// @HostListener ('window:keydown', ['$event'])
	// KeyDown (event: any) {
	// 	if (event.which === 32)
  //       event.preventDefault();
	// }
	private check(value: string, decimals: number) {
		if ( decimals <= 0) {
			return String(value).match(new RegExp(/^\d+$/));
		} else {
			const regExpString = this.getRegEx(decimals)
			return String(value).match(new RegExp(regExpString));
		}
	}

	private getRegEx(decimals: number) {
		return !this.digits
		? `^\\d*((\\d+(\\.\\d{0,${decimals}})?)|((\\d*(\\.\\d{1,${decimals}})))	)$`
		:`^(((\\d{0,${this.digits}})(\\.\\d{0,${decimals}})?)|(((\\d{1,${this.digits}})(\\.\\d{1,${decimals}}))))$`
	}

	private run(oldValue:any) {
        setTimeout(() => {
            let currentValue: string = this.el.nativeElement.value;
            if (currentValue !== '' && !this.check(currentValue,this.decimals)) {
                this.el.nativeElement.value = oldValue;
				if(this.control && this.control.control) {
					this.control.control.patchValue(oldValue);
					this.control.control.updateValueAndValidity()
				}
            }
        });
    }

	private specialKeys = [
		'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
	];

	constructor(private el: ElementRef,@Optional() private control:NgControl) {
	}
	ngAfterViewInit(): void {
		if(this.control)
		this.control.control?.addValidators((control) => {
			if (control.value && !this.check(control.value, this.decimals)) {
				return { numbersOnly: true };
			}
			return null;
		});
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		//let current: string = this.el.nativeElement.value;
		//let next: string = current.concat(event.key);

        
		const inputElement = this.el.nativeElement as HTMLInputElement; // get a reference to the input element
		const currentCursorPosition = inputElement.selectionStart || 0; // get the current cursor position before the key was pressed
		
		let current: string = inputElement.value;
		let firstHalf = current.substring(0, currentCursorPosition); 
        let secondHalf = current.substring(currentCursorPosition); 
		let next: string = firstHalf+event.key+secondHalf;

		if (next && !this.check(next, this.decimals)) {
			event.preventDefault();
		}
	}

	@HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        this.run(this.el.nativeElement.value);
    }

	@HostListener("input", ["$event"])
    onInput(event: InputEvent) {
		if(!event.data) return
		if(event.data === '.') return
		if(event.data && !!!this.check(event.data, this.decimals)) {
			this.el.nativeElement.value = this.el.nativeElement.value.replace(event.data, '');
			return
		};
        this.run(this.el.nativeElement.value);
    }

}

