import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordMatchValidator = (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get('password')?.value === formGroup.get('password2')?.value)
        return null;
    else
        return { passwordMismatch: true };
};
export const forgotPasswordMatchValidator = (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get('password')?.value === formGroup.get('confirmPassword')?.value)
        return null;
    else
        return { passwordMismatch: true };
};

export const patternValidator = (regex: RegExp, error: ValidationErrors): ValidationErrors | null => {
    return (control: AbstractControl): any => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

        // if true, return no error (no error), else return error passed in the second parameter
        return valid ? null : error;
    };
};



