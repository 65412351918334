import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { DeleteAccountModalComponent } from '../modals/delete-account-modal/delete-account-modal.component';
import { CustomModalBody } from '../models/custom-modal-body.model';
import { AddPaymentComponent } from '../modals/add-payment/add-payment.component';
export interface DialogData {
  title: string;
  body: string;
  ok: string;
  Cancel: string;
  result: string;
  closeIconMessage: string;
  middleContent: string;
  hideCloseIcon: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class GenericModalService {
  fileUpload: boolean = false;
  genericPopup: boolean = false;
  result: any;
  constructor(public dialog: MatDialog) {
  }
  // to open generic modal popup
  openDialog(title: any, body: any, ok?: string, Cancel?: string, disableClose?: boolean, closeIconMessage?: string, hideCloseIcon?: boolean): Observable<any> {
    this.genericPopup = true;
    const dialogRef = this.dialog.open(GenericModalComponent, {
      width: '352px',
      height: '192px',
      id: 'Generic-Modal',
      panelClass: 'generic__pop',
      disableClose: true,
      data: {
        title: title,
        body: body,
        ok: ok,
        Cancel: Cancel,
        closeIconMessage: closeIconMessage ? closeIconMessage : '',
        hideCloseIcon: hideCloseIcon
      }
    });

    return dialogRef.afterClosed()
  }

  // to open custom modal popup
  openCustomDialog(title: string, body: CustomModalBody, ok?: string, Cancel?: string, disableClose?: boolean): Observable<any> {
    const dialogRef = this.dialog.open(DeleteAccountModalComponent, {
      width: '360px',
      height: '220px',
      id: 'Delete-Modal',
      panelClass: 'Delete__account--pop',
      disableClose,
      data: {
        title: title,
        body: body,
        ok: ok,
        Cancel: Cancel
      }
    });

    return dialogRef.afterClosed()
  }
  closeAllModals() {
    this.dialog.closeAll();
  }

  openAddPayment(accountName: any) {
    const dialogRef = this.dialog.open(AddPaymentComponent, {
      width: '320px',
      height: '320px',
      panelClass: 'account-payment',
      data: { accountName: accountName }
    });

    return dialogRef.afterClosed()
  }

  openUpdateSpeakerDialog(title: any, body: any, ok?: string, Cancel?: string, disableClose?: boolean, closeIconMessage: string = '', middleContent: string = ''): Observable<any> {
    this.genericPopup = true;
    const dialogRef = this.dialog.open(GenericModalComponent, {
      width: '400px',
      height: '224px',
      id: 'Generic-Modal',
      panelClass: 'updatespeaker__popup',
      disableClose: true,
      data: {
        title: title,
        body: body,
        ok: ok,
        Cancel: Cancel,
        closeIconMessage: closeIconMessage,
        middleContent: middleContent
      }
    });

    return dialogRef.afterClosed()
  }

}

