import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public config: any;
  public browserName: string;
  IpAddress: any;

  constructor(private api: ApiService, private http: HttpClient) {
    this.config = AppConfig;
    this.browserName = this.getBrowserName()
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token ? true : false
  }

  public async getIPAddress() {
    try {
      const res = await this.api.get(this.config.GET_IP_ADDRESS, false, '', false, false, false).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Google Chrome';
      case agent.indexOf('trident') > -1:
        return 'Internet Explorer';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'other';
    }
  }

  public async captureUserActivity(activity: string, pageName: string) {
    const payload = {
      "page": pageName,
      "activity": activity,
      "browser": this.browserName,
      "os": window.navigator.platform
    }

    try {
      const res = await this.api
        .post(this.config.CAPTURE_USER_ACTIVITY, payload, true)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }


  public async getUserActivityData(data: any) {
    try {
      const res = await this.api
        .get(this.config.CAPTURE_USER_ACTIVITY, true, data)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }


}
