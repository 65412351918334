import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppCommonService } from './app-common.service';
import { AuthService } from './auth.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: AuthService,public router: Router, private route: ActivatedRoute,public appCommonService: AppCommonService,private sessionStorage: SessionStorageService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isAuthenticated()) {
      if(!this.appCommonService.isUserLoggedIn()){
        this.appCommonService.showError("Session time expired, please login again.");
        this.sessionout();
        return false;
      } else {
        return true;
      }
    } else {
      this.sessionout();
      return false;
    }
  }
  sessionout(){
    this.sessionStorage.clearSessionData();
    this.router.navigate(['/login'], { relativeTo: this.route});
  }
}
