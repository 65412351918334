import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';
import { catchError, tap, switchAll, delayWhen, retryWhen } from 'rxjs/operators';
import { EMPTY, Observable, BehaviorSubject, timer } from 'rxjs';
import { AppCommonService } from './app-common.service';
export const WS_ENDPOINT = environment.wsEndpoint;
export const RECONNECT_INTERVAL = 1000

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>|undefined;
  private messagesSubject$ = new BehaviorSubject<Observable<any>>(EMPTY);
  
  constructor(private appService:AppCommonService){}

  /**
   *  A public observable that we will be subscribing to in every component subject to real time.
   */
  public messages$: Observable<SocketResponce>  = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));
  private _isClose: boolean;

  /**
   * Call's the getNewWebSocket and emits messages coming from the server to a private subject messagesSubject$.
   */
  public connect(cfg: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      const messages = this.socket$.pipe(
        cfg.reconnect ? this.reconnect : o => o,
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY));
      this.messagesSubject$.next(messages);
    }
  }
  /**
   * Returns a new webSocketSubject for a given url.
   */
  private getNewWebSocket() {
    return webSocket(
      { 
        url: `${WS_ENDPOINT}?user_id=${this.appService.getUserId()}`,
        closeObserver: {
          next: () => {
            console.log('[WebSocketService]: connection closed');
            this.socket$ = undefined;
            if(!this._isClose){
              this.connect({ reconnect: true });
            }
          }
        }
      }
    );
  }
  /**
   * Sends a message to the socket. This latter will send it to the server.
   * @param  {any} msg
   */
  sendMessage(msg: any) {
    this.socket$?.next(msg);
  }
  
  /**
   * Closes the connection by completing the subject.
   */
  close() {
    this._isClose = true
    this.socket$?.complete();
    
  }
  /**
   * Retries to eshtablish connection when connections lost.
   */
  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(retryWhen(errors => errors.pipe(tap(val => console.log('[Data Service] Try to reconnect', val)),
      delayWhen(_ => timer(RECONNECT_INTERVAL)))));
  }

}


export interface SocketResponce {
  user_id: number;
  action:  'STOP_JOB'|'NEW_LOGIN'|'TRANSLATION_COMPLETED'|'TRANSLATION_FAILED'|'DISABLE_USER';
  message: string;
}
